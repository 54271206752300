import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AggiornamentoPianoDiStudiStatus, AuthorityType} from '../../../../api-clients/generated/services';
import {FuseConfirmationService, hideDelibera} from '../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {AbstractDefaultComponent} from '../../abstracts/abstract-default-component/abstract-default-component';
import {DialogApprovalConfiguration} from '../../interface/dialog-approval-configuration';
import {SnackbarTypes} from '../../../../@fuse/services/confirmation/snackbar/snackbar.component';
import * as moment from "moment";
import {FormComponent} from "../../../layout/common/generic-components/form/form.component";

@Component({
    selector: 'app-dialog-approval',
    templateUrl: './dialog-approval.component.html',
    styleUrls: ['./dialog-approval.component.scss']
})
export class DialogApprovalComponent extends AbstractDefaultComponent implements OnInit {

    showMiniLoader: boolean = false;

    protected readonly AuthorityType = AuthorityType;
    protected readonly hideDelibera = hideDelibera;
    protected readonly AggiornamentoPianoDiStudiStatus = AggiornamentoPianoDiStudiStatus;
    protected readonly moment = moment;
    touched = false;
    @ViewChild(FormComponent) docsFormComponent: FormComponent;
    private formNotValidMessage: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogApprovalConfiguration,
                public dialogRef: MatDialogRef<DialogApprovalComponent>,
                private fuseConfirmationService: FuseConfirmationService,
                private _translocoService: TranslocoService,
    ) {
        super();
        this._translocoService.selectTranslate('form.not_valid').subscribe(value => this.formNotValidMessage = value);
    }

    ngOnInit(): void {
    }


    onConfirm(): void {

        this.touched = true;
        if (this.docsFormComponent.formGroup.valid) {
            this.data.onConfirm(this.docsFormComponent.formGroup, this.dialogRef);
        } else {
            this.touched = true
            this.docsFormComponent.formGroup.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: this.formNotValidMessage,
                type: SnackbarTypes.Warning,
            });
        }

    }

    closeDialog(): void {
        this.dialogRef.close();
    }





}
