import {Injectable} from '@angular/core';
import * as forge from 'node-forge';
import {environment} from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class RsaEncryptionService {

    constructor() {
    }

    encryptWithPublicKey(valueToEncrypt: string): string {
        const rsa = forge.pki.publicKeyFromPem(environment.rsaPublicKey);
        return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
    }

}

