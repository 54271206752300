import {isEmpty} from "lodash";
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {
    AbstractDefaultComponent
} from "../../abstracts/abstract-default-component/abstract-default-component";
import {
    AttivitaOffertaFormativaInfoView,

    SpesaStudenteType,
    UserSottoruoloView,
    UsersViewInfoView
} from "api-clients/generated/services";
import {FormControl, FormGroup} from "@angular/forms";
import {AppInitService} from "app/shared/service/app-init.service";
import {distinctUntilChanged, take, takeUntil, tap} from "rxjs";
import {LocalStorageService} from "../../service/local-storage.service";
import {capitalizeString} from "../../utils/utils";
import {StudentiBarFilterManagerService} from "../../service/studenti-bar-filter-manager.service";
import {SpeseBarFilterManagerService} from "../../service/spese-bar-filter-manager.service";
import {CicloConfigurationService} from "../../service/ciclo-configuration.service";

@Component({
    selector: 'app-spese-bar-filter-container',
    templateUrl: './spese-bar-filter-container.component.html',
    styleUrls: ['./spese-bar-filter-container.component.scss']
})
export class SpeseBarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeBar: EventEmitter<boolean> = new EventEmitter<boolean>();
    mainForm: FormGroup | undefined;
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    _codiceSpesa = 'codiceSpesa';
    _tipologiaSpesa = 'tipologiaSpesa';
    _descrizioneNoteSpesa = 'descrizioneNoteSpesa';
    _nominativoStudente = 'nominativoStudente';
    _emailStudente = 'emailStudente';
    _cfStudente = 'cfStudente'
    _matricolaStudente = 'matricolaStudente';
    cosupervisoreList: UserSottoruoloView[];
    supervisoreList: UserSottoruoloView[];
    docenteList: UserSottoruoloView[];
    filterApplied = {
        'codiceSpesa': null,
        'tipologiaSpesa': null,
        'descrizioneNoteSpesa': null,
        'nominativoStudente': null,
        'emailStudente': null,
        'cfStudente': null,
        'matricolaStudente': null,
    };
    protected readonly Object = Object;
    protected readonly SpesaStudenteType = SpesaStudenteType;
    constructor(public filterManagerService: SpeseBarFilterManagerService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,
                protected cicloConfigurationService: CicloConfigurationService,
                ) {
        super();
        this.currentFields = this.filterManagerService.defaultFormPathList;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;

    }

    ngOnInit(): void {
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => console.log('Aggiorna filtri')),
            tap(value =>
                this.currentFields = this.filterManagerService.getFieldsByRole(value?.ruolo, value?.sottoruolo)),
            takeUntil(this.destroy$)
        ).subscribe();

        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            tap(() => this.filterManagerService.init()),
            tap(value =>
                this.currentFields = this.filterManagerService.getFieldsByRole(
                    this.localStorageService?.dipartimentoRuoloCiclo?.ruolo,
                    value)
            ),
            takeUntil(this.destroy$)
        ).subscribe();


        this.filterManagerService.filterApplied$.asObservable().pipe(
            tap((value) => this.filterApplied = value),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.filterManagerService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService.mainFormGroup;
    }

    applyFilters(): void {
        //this.filterApplied= this.getMainFormGroup().getRawValue();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.filterManagerService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeBar.next(true);
    }


    reset(): void {
        this.resetFilterApplied();
        this.getMainFormGroup().reset();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
        this.close();
    }

    setFilterApplied(): void {

        if (!!this.filterApplied && !isEmpty(this.filterApplied)) {
            this.filterManagerService.mainFormGroup.setValue(
                {
                    'codiceSpesa': this.filterApplied.codiceSpesa,
                    'tipologiaSpesa': this.filterApplied.tipologiaSpesa,
                    'descrizioneNoteSpesa': this.filterApplied.descrizioneNoteSpesa,
                    'nominativoStudente': this.filterApplied.nominativoStudente,
                    'emailStudente': this.filterApplied.emailStudente,
                    'cfStudente': this.filterApplied.cfStudente,
                    'matricolaStudente': this.filterApplied.matricolaStudente,
                }
            );
        }
    }

    resetFilterApplied(): void {
        this.filterApplied = {
            'codiceSpesa': null,
            'tipologiaSpesa': null,
            'descrizioneNoteSpesa': null,
            'nominativoStudente': null,
            'emailStudente': null,
            'cfStudente': null,
            'matricolaStudente': null,
        };
        this.filterManagerService.mainFormGroup.reset();
    }


}
