import {Component, Inject, OnInit} from '@angular/core';
import {ClickEvent, TipoClickEnum} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AttivitaOffertaFormativaInfoView,
    AuthorityType,
    DeliberaInfoViewImpl,
    PeriodoRegistroAttivitaInfoView,
    SpesaStudentePerApprovMassivaInfoView, SpeseStudentiCicloService,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, switchMap, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {FormControl} from "@angular/forms";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get} from "lodash";
import {filter} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {makeFilenameFromFE} from "../../../../../shared/utils/utils";
import {HttpResponse} from "@angular/common/http";
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import * as fs from "file-saver";
import {ApprovazioneRegistroAttivitaMassivaDataI} from "../approvazione-registro-attivita-steps-interface";
import {optionFile} from "../../../../../shared/costants/app-constants";


@Component({
    selector: 'app-info-approvazione-registro-stepper',
    templateUrl: './compila-info-approvazione-registro.component.html',
    styleUrls: ['./compila-info-approvazione-registro.component.scss']
})
export class CompilaInfoApprovazioneRegistroComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    periodiCompileInfos: PeriodiCompileInfo[];
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    periodiSelected: PeriodoRegistroAttivitaInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private speseStudentiCicloService: SpeseStudentiCicloService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneRegistroAttivitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneRegistroAttivitaMassivaDataI) => {
            this.initForm();
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
            }
            // if spese selected changes reset delibera form
            if(inputOutputData.periodiSelected !== this.periodiSelected){
                this.periodiSelected = inputOutputData?.periodiSelected;
                this.periodiCompileInfos = this.buildPeriodiCompileInfoConfiguration(this.periodiSelected || []);
            }
        });
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }


    private initForm() {
        this.canGoNext$.next(true);
    }

    tableClickAction($event: ClickEvent) {
        if($event.tipoClick === TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST) {
            this.downloadAllegatiRequest($event.value);
        }
    }

    private downloadAllegatiRequest(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.speseStudentiCicloService.getZipAllegatiRichiestaDiSpesaStudente(spesa?.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                //const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_SPESA_' + spesa?.codiceSpesa;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: spesa?.budgetStudente?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo:  spesa?.budgetStudente?.studenteCiclo?.numeroCicloDottorato + '',
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse?.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }


    private buildPeriodiCompileInfoConfiguration(periodoRegistroAttivitaInfoViews: PeriodoRegistroAttivitaInfoView[]): PeriodiCompileInfo[] {
        this.formControls = new Map<string, FormControl>();
        this.files = new Map<string, File>();
        const periodi = periodoRegistroAttivitaInfoViews
            ?.map(periodo => ({
                id: periodo.id,
                studenteUIMapped: {
                    utenteNome: periodo?.studenteCiclo?.utente?.nome,
                    utenteCognome: periodo?.studenteCiclo?.utente?.cognome,
                    nome: periodo?.studenteCiclo?.utente?.nome,
                    cognome: periodo?.studenteCiclo?.utente?.cognome,
                    utenteMail: periodo?.studenteCiclo?.utente?.mail?.toLowerCase(),
                    studenteCodiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: periodo?.studenteCiclo?.matricola,
                    urlImmagineProfilo: periodo?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: periodo?.studenteCiclo?.utente?.telefono
                },
                dataInizioFormatted: periodo.dataInizio ? moment(periodo.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                dataFineFormatted: periodo.dataFine ? moment(periodo.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                dataRichiestaFormatted: periodo.dataRichiesta ? moment(periodo.dataRichiesta).format('DD/MM/YYYY') : undefined,
                dataInizioAndFineFormatted: (periodo.dataInizio ? moment(periodo.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined) + ' - '
                    + (periodo.dataFine ? moment(periodo.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)
            }));
        periodi?.forEach(periodo => this.formControls.set(periodo.id, new FormControl<string>(undefined)));
        return periodi;
    }

    protected readonly optionFile = optionFile;
    formControls: Map<string, FormControl>;
    files: Map<string, File>;

    downloadFile(filename: string, idPeriodo: string) {
        fs.saveAs(this.files.get(idPeriodo), filename);
    }

    uploadFile(file: File, idPeriodo: string) {
        const extension = file?.name?.substring(file?.name?.lastIndexOf('.')+1);
        const renamedFile = new File([file], idPeriodo + '.' + extension, {type: file.type});
        this.files.set(idPeriodo, renamedFile);
        this.formControls.get(idPeriodo).setValue(idPeriodo);
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            compileInfo: this.files
        });

    }

    deleteFile(idPeriodo: string) {
        this.files.delete(idPeriodo);
        this.formControls.get(idPeriodo).setValue(undefined);
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            compileInfo: this.files
        });
    }

    protected readonly AuthorityType = AuthorityType;
}

export interface PeriodiCompileInfo{
    id: string;
    studenteUIMapped: any;
    dataInizioFormatted: string;
    dataFineFormatted: string;
    dataRichiestaFormatted: string;
    dataInizioAndFineFormatted: string;
}
