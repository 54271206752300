import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel, TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AggiornamentoPianoDiStudiStatus,
    AuthorityType,
    ChiaveFlussoEnum,
    ChiaveOperazioneEnum,
    DeliberaInfoView,
    DelibereService, PeriodoDiMobilitaStudenteInfoView, StudenteCicloPianoDiStudiViewImpl,
    StudenteCicloUtenteViewImpl
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, Subscription} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {FormBuilder, FormGroup} from "@angular/forms";
import {
    ApprovazionePianiMassivaDataI,
    CompileInfoApprovazionePianiMassiva
} from '../approvazione-piani-steps-interface';
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get} from "lodash";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {buildFormConfigForAddNewDelibera} from "../../../gestione-documenti/gestione-documenti-utils";
import {
    StudyPlanWithDeliberaMode
} from "../../common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";
import {FormComponent} from "../../../../../layout/common/generic-components/form/form.component";
import {buildStudyPlansConfiguration} from "../../../requests/requests-utils";
import {
    computePlanStatusExtendedChipAccordingStatusAndValidity
} from "../../../student-details/student-details.component";
import {
    PianoDiStudiStatusExtended
} from "../../../cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {StudentDetailsService} from "../../../student-details/student-details.service";


export interface StudenteWithExtraCustom {
    formGroup: FormGroup;
    tableConfiguration: GenericTableConfigurationModel;
    studente: StudenteCicloUtenteViewImpl;
    planId: string;
    aggiornamentoId: string;
}

@Component({
    selector: 'app-info-approvazione-piani-stepper',
    templateUrl: './compila-info-approvazione-piani.component.html',
    styleUrls: ['./compila-info-approvazione-piani.component.scss']
})
export class CompilaInfoApprovazionePianiComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    studentiSelected: StudenteCicloUtenteViewImpl[];
    deliberaMode: StudyPlanWithDeliberaMode;
    compileInfo: CompileInfoApprovazionePianiMassiva[];
    documentsFormConfig: formGroupConfigInterface[];
    documentsFormValue: any;
    _docsFormComponent: FormComponent;
    docsFormComponentSub: Subscription;
    updatesWithDocumentAlreadyAdded: GenericTableConfigurationModel;
    @ViewChild(FormComponent) set docsFormComponent(docsFormComp: FormComponent) {
        this._docsFormComponent = docsFormComp;
        // subscribe to document form value change
        this.subscribeToDocsFormValueChange();
    };

    private subscribeToDocsFormValueChange() {
        this.canGoNext$.next(this._docsFormComponent.formGroup.valid);
        this.docsFormComponentSub?.unsubscribe();
        this.docsFormComponentSub = this._docsFormComponent?.formGroup?.valueChanges.subscribe(docsFormValue => {
            this.canGoNext$.next(this._docsFormComponent.formGroup.valid);
            const documents = this.cicloConfigurationService.prepareDocsSubmitObject(
                // at the moment the config for supervisor is the same (empty) in both flows Modifica and Prima presentazione
                // therefore it's the same to access to the documents needed for supervisor
                // if they will change the massive op will need the info if it's Modifica or Prima presentazione
                this.deliberaMode === StudyPlanWithDeliberaMode.EDIT ? ChiaveFlussoEnum.ModificaPianoFormativo : ChiaveFlussoEnum.PrimaPresentazionePianoFormativo,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                docsFormValue,
            );
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                //deliberaCollegio: this.delibere?.find(d => d.id === deliberaId),
                documentiAllegati: documents
            });
        });
    }

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private studentDetailsService: StudentDetailsService,
                private cicloConfigurationService: CicloConfigurationService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazionePianiMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazionePianiMassivaDataI) => {
            this.deliberaMode = inputOutputData?.deliberaMode;
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
                this._docsFormComponent?.formGroup?.reset({emitEvent: false});
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.compileInfo !== this.compileInfo){
                this.compileInfo = inputOutputData?.compileInfo;
                this._docsFormComponent?.formGroup?.reset({emitEvent: false});
            }
            this.buildCompileInfoComponent(inputOutputData);
        });
    }

    private buildCompileInfoComponent(inputOutputData: ApprovazionePianiMassivaDataI) {
        // building plan updates list

        // finding how many updates needs documents
        // AT MOMENT ONLY DELIBERA
        const planUpdatesWithoutDocuments = inputOutputData.selectedPlanUpdate
            ?.filter(update => update.allegatiUltimoAggiornamentoPianoDiStudi?.length == 0)
            ?.length;
        const planUpdatesWithDocuments = inputOutputData.selectedPlanUpdate
            ?.filter(update => update.allegatiUltimoAggiornamentoPianoDiStudi?.length > 0)
            ?.length;
        this.updatesWithDocumentAlreadyAdded = planUpdatesWithDocuments > 0 ? this.buildSelectedStudyPlanUpdatedConfiguration(
            inputOutputData.selectedPlanUpdate, this.cicloConfigurationService) : undefined;
        this.documentsFormConfig = planUpdatesWithoutDocuments > 0 ? [
            ...this.cicloConfigurationService.getDocsConfig(
                this.deliberaMode === StudyPlanWithDeliberaMode.EDIT ? ChiaveFlussoEnum.ModificaPianoFormativo : ChiaveFlussoEnum.PrimaPresentazionePianoFormativo,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                (fileUrl) => this.downloadFileOrDelibera(fileUrl),
                undefined,
            )
        ] : [];
        this.documentsFormValue = {
            ...this.cicloConfigurationService.getDocsValues(
                this.deliberaMode === StudyPlanWithDeliberaMode.EDIT ? ChiaveFlussoEnum.ModificaPianoFormativo : ChiaveFlussoEnum.PrimaPresentazionePianoFormativo,
                this.currentProfile === AuthorityType.COORDINATORE ? ChiaveOperazioneEnum.ApprovazioneCoordinatore : ChiaveOperazioneEnum.ApprovazioneSupervisore,
                [],
            )
        };
    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }



    protected readonly StudyPlanWithDeliberaMode = StudyPlanWithDeliberaMode;
    protected readonly AuthorityType = AuthorityType;

    downloadFileOrDelibera(urlFile: string, deliberaData?: DeliberaInfoView) {
        // NOT NEEDED AT THE MOMENT
        // if(deliberaData) {
        //     this.fuseConfirmationService.openDialogDelibera({
        //         delibera: deliberaData,
        //         codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
        //     });
        // } else {
        //     const activeLang = this._translocoService.getActiveLang();
        //     const translation = this._translocoService.getTranslation().get(activeLang);
        //     const requestBody: GetAllegatoSuperamentoAnnoStudenteRequest = {
        //         id_superamento_anno_studente: this.superamentoData.id,
        //         nome_file: urlFile
        //     }
        //     this.fuseConfirmationService.showLoader();
        //     this.appInitService.selectedInService.pipe(
        //         filter(Boolean),
        //         take(1),
        //         tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
        //         switchMap(() => this.studentiCicloService.getAllegatoSuperamentoAnnoStudente(requestBody, this.userId, 'response')),
        //         takeUntil(this.destroy$),
        //         finalize(() => {
        //             this.fuseConfirmationService.hideLoader();
        //         })
        //     ).subscribe({
        //         next: (fileResponse: HttpResponse<Blob>) => {
        //             const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
        //             //const fileNameToDownload = makeFilename(fileName);
        //             openFileInBlankWindow(fileResponse?.body, fileName);
        //             this.fuseConfirmationService.openSnackBar({
        //                 message: get(translation, 'budget.file_show_success', null),
        //                 type: SnackbarTypes.Success,
        //             });
        //         },
        //         error: (err) => {
        //             this.fuseConfirmationService.openSnackBar({
        //                 message: get(translation, 'student.file_download_error', null),
        //                 type: SnackbarTypes.Warning,
        //             });
        //         }
        //     });
        // }

    }

    private buildSelectedStudyPlanUpdatedConfiguration(selectedPlanUpdate: StudenteCicloPianoDiStudiViewImpl[],
                                                       cicloConfigurationService: CicloConfigurationService): GenericTableConfigurationModel {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            configuration: {
                data: selectedPlanUpdate,
                sticky: true,
                totalElements: selectedPlanUpdate.length,
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data_study_plan_dashboard',
                hidePaginator: false,
                disabledCssClass: 'generic-table-disabled-row',
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.student',
                        colonnaKey: 'studente',
                        flex: 24,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.enrollment_number',
                        colonnaKey: 'matricola',
                        flex: 10,
                        hideColonna: !cicloConfigurationService.showMatricola,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.university_email',
                        colonnaKey: 'emailAteneo',
                        flex: 32,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'mobility.collegio_approval',
                        colonnaKey: 'deliberaFormatted',
                        flex: 32,
                    },
                    {
                        nomeColonna: ' ',
                        colonnaKey: 'action',
                        flex: 7,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        button: [
                            {
                                nameIconButton: 'library_books',
                                click: TipoClickEnum.SHOW,
                                tooltip: 'common.go_to_study_plan',
                                color: 'accent',
                            },
                            {
                                nameIconButton: 'visibility',
                                click: TipoClickEnum.SCARICA_DELIBERA,
                                tooltip: 'mobility.show_delibera',
                                color: 'accent',
                                show: (stato, update) => !!update?.delibera,
                                valueShow: 'stato'
                            },
                        ]
                    }
                ],
            }
        };
    }

    tableClickAction($event: ClickEvent) {
        switch ($event.tipoClick) {
            case TipoClickEnum.SCARICA_DELIBERA:
                this.fuseConfirmationService.openDialogDelibera({
                    delibera: $event.value?.delibera?.deliberaCollegioDocenti,
                    codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3 ,
                });
                break
        }
    }
}
