import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData, TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    CorsoPianoDiStudiPerExportViewImpl,
    PageStudenteCicloUtenteViewImpl, StudenteCicloUtenteViewImpl,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {tap} from "rxjs/operators";
import {BehaviorSubject, finalize, Observable, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormBuilder, FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {
    StudentiBarFilterContainerComponent
} from "../../../../../shared/components/studenti-topbar-filter-container/studenti-bar-filter-container.component";
import {SuperamentoCorsoMassivoDataI} from "../superamento-corso-steps-interface";
import {CAN_GO_AHEAD$, DEFAULT_PAGE_SIZE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


@Component({
    selector: 'app-scegli-studenti-stepper',
    templateUrl: './scegli-studenti.component.html',
    styleUrls: ['./scegli-studenti.component.scss']
})
export class ScegliStudentiComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    studentsListConfiguration: GenericTableConfigurationModel;
    private corso: any;
    private pageSize: number = DEFAULT_PAGE_SIZE;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    filterContainerComponent: StudentiBarFilterContainerComponent;
    showTopbar: boolean;
    searchForm: FormGroup;

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private logOutService: LogoutService,
                protected fb: FormBuilder,
                private router: Router,
                private cicloConfigurationService: CicloConfigurationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<SuperamentoCorsoMassivoDataI>,) {
        super();
        this.searchForm = this.fb.group({
            nominativo: [''],
        });
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: SuperamentoCorsoMassivoDataI) => {
            if (this.corso?.id !== inputOutputData?.corsoSelected?.id) {
                // when course changes do the studenti request and reset filters
                this.corso = inputOutputData?.corsoSelected;
                this.getListaStudentiRequest$(inputOutputData?.corsoSelected?.id, true);
                this.canGoNext$.next(false);
            }
            if (inputOutputData?.refreshStudents) {
                // when confirm step force refresh do the studenti request
                console.log('REFRESHING STUDENTI IN SCEGLI STUDENTI')
                this.studentsListConfiguration = undefined;
                this.resetRefreshStudents();
                this.getListaStudentiRequest$(inputOutputData?.corsoSelected?.id, true);
                this.canGoNext$.next(false);
            }
        });
        this.isFirstTime = true;
    }

    getListaStudentiRequest$(idCorso: string, isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10,
                             sort?: string): void {
        this.getStudentiFormObs$(idCorso, isFirstTime, filters, page, size, sort).subscribe({
            next: (value) => {
                /*                this.filterManagerService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                                console.log('FILTRI PRESENTI DOPO LA GET STUDENTI', this.filterManagerService.mainFormGroup.value);
                                this.filterManagerService.filterApplied$.next(this.getMainFormGroup().getRawValue());
                                this.currentFilterListChipLabels = this.getListChipsLabel();*/
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {
                        this.logOutService.goToHome()
                    },
                    () => this.getListaStudentiRequest$(idCorso, isFirstTime, filters, page, size, sort),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getStudentiFormObs$(idCorso: string, isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10,
                        sort?: string): Observable<PageStudenteCicloUtenteViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.studentiCicloService.getStudentiForm(
            !!filters?.nominativo ? filters?.nominativo : undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            idCorso,
            false,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            page, size,).pipe(
            tap((pageStudente) => {
                this.studentsListConfiguration = this.buildListaStudentiConfiguration(pageStudente, size, page);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildListaStudentiConfiguration(pageStudente: PageStudenteCicloUtenteViewImpl, pageSize: number, page: number): GenericTableConfigurationModel {
        return {
            configuration: {
                data: pageStudente?.content?.map(studenteContent => ({
                    ...studenteContent,
                    studente: studenteContent.cognome?.toUpperCase() + ' ' + studenteContent.nome?.toUpperCase(),
                    supervisore: (studenteContent?.supervisore?.cognome?.toUpperCase() || studenteContent?.supervisore?.nome?.toUpperCase()) ?
                        ((studenteContent?.supervisore?.cognome?.toUpperCase() || '') + ' ' + (studenteContent?.supervisore?.nome?.toUpperCase() || '')).toUpperCase() : '',
                    tipologiaBorsa: (studenteContent?.tipoBorsaPerCiclo?.descrizioneTipoBorsa),
                    curriculum: (studenteContent?.percorsoDiStudi?.descrizionePercorsoDiStudi),
                    dataIscrizione: this.formatDay(studenteContent?.dataIscrizione),
                    emailAteneo: studenteContent?.emailAteneo?.toLowerCase()
                })),
                sticky: true,
                pageSize: pageSize,
                pageBE: page,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: pageStudente.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data',
                hidePaginator: false,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'studente',
                    selectedColumnKeyPrimaryKey: 'idUtente',
                },
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.student',
                        colonnaKey: 'studente',
                        flex: 19,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.enrollment_number',
                        colonnaKey: 'matricola',
                        hideColonna: !this.cicloConfigurationService.showMatricola,
                        flex: 8,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.date_of_registration',
                        colonnaKey: 'dataIscrizione',
                        flex: 8,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.university_email',
                        colonnaKey: 'emailAteneo',
                        flex: 22,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.supervisor',
                        colonnaKey: 'supervisore',
                        flex: 12,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'menu_filter.type_of_study_grant',
                        colonnaKey: 'tipologiaBorsa',
                        flex: 14,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'students_list.curriculum',
                        colonnaKey: 'curriculum',
                        flex: 15,
                    },
                    {
                        nomeColonna: ' ',
                        colonnaKey: 'action',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        button: [
                            {
                                nameIconButton: 'library_books',
                                click: TipoClickEnum.GO_TO,
                                tooltip: 'common.go_to_study_plan',
                                color: 'accent',
                            },
                        ]
                    },
                ],
            }
        };
    }


    tableClickAction($event: ClickEvent) {
        switch ($event.tipoClick) {
            case TipoClickEnum.GO_TO:
                this.openPlanInNewTab(($event.value as StudenteCicloUtenteViewImpl)?.idUtente);
                break;
        }
    }

    openPlanInNewTab(id: string): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([PathEnum.STUDENTS, id, PathEnum.STUDY_PLAN])
        );
        window.open(url, '_blank');
    }


    onStudentDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            corsoSelected: this.operazioneMassivaData$.getValue()?.corsoSelected,
            studentiSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getListaStudentiRequest$(this.corso?.id, false, this.searchForm.getRawValue(), page, this.pageSize);
    }


    private resetRefreshStudents() {
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshStudents: false
        })
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    onSearch() {
        this.getListaStudentiRequest$(this.corso?.id, false, this.searchForm.getRawValue(), 0, this.pageSize);
    }

    onReset() {
        this.searchForm.reset();
        this.getListaStudentiRequest$(this.corso?.id, false, this.searchForm.getRawValue(), 0, this.pageSize);
    }
}
