// @ts-ignore
import {PathEnum} from '../app/app.routing';

export const environment = {
    name: 'vanvitelli-dev',
    production: false,
    servicesUrl: 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend',
    servicesKeycloak: {
        redirectUri: 'https://dev-atena-unicampania.southengineering.it/'+PathEnum.SIGN_IN,
        idpLogout: 'https://idptest.unicampania.it',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_secret: 'g38vjYuYjVhYgrzll63O3MPp5dboZQ9f',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id: 'univ-vanvitelli-cli',
        basePath: 'https://dev-keycloak.southengineering.it/',
        realmName: 'univ-vanvitelli-realm',
        client_id_internal: 'atena-dev-internal-cli',
        client_secret_internal: 'EnTIdMpQuJ0Gu4mQbgDSyhnEiNIYw8hy',
        useInternalIDP: true,
    },
    errorsQueueCapacity: 5,
    showStatisticsInHome: false,
    recaptcha: {
        siteKey: '6Ld7ZdkhAAAAABMgYYwCAAUxt4hO7CGK-KKGOObF'
    },
    page: {
        showAnvur: false,
    },
    rsaPublicKey: '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4UcWy8j5ULQ0AJG13y8b\n9QLgBRQ4LsWvEfFxhHs33w1eJqkzBml2rAshJtt5FrPMMPqQNELh+blZG2dhlSJl\nzVQYrniVjLOTAeTO4u6fR9EQPRNGepT+uNCRGHhJGluVttkNCRmpOLpzqkvLv5UM\ncsAE2IrU7Iqd6C7S+Fprh9a5CYvr9i/siw9D1DlIGo2olmFj/pCclEfoNTz6tPwa\nR+8bp8GMDQ4i46pcehi6xio6b4Or1B1tAlKI/U/Q3tgD+7PFh1P7fuwuy+NDSwBd\nUI3gDNjo1bm0uZpO8jj3e8l1+d0uOMD63eYOZJtj5wlrfG3kiE1YyFywf7i4R8Tm\n1QIDAQAB\n-----END PUBLIC KEY-----'
};
