import {Component, Input, OnInit} from '@angular/core';
import {
    AnnoRiferimentoValues,
    ChiaveFunzionalitaEnum,
    ConfigurazioneFunzionalita, FunzionalitaStatus
} from "../../../../../../api-clients/generated/services";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";

@Component({
    selector: 'app-year-passing-relation-in-plan',
    templateUrl: './year-passing-relation-in-plan.component.html',
    styleUrls: ['./year-passing-relation-in-plan.component.scss']
})
export class YearPassingRelationInPlanComponent implements OnInit {

    @Input() anno: AnnoRiferimentoValues;

    constructor(protected cicloConfigurationService: CicloConfigurationService) {
    }

    ngOnInit(): void {
    }

    getSuperamentoAnnoConfig(): ConfigurazioneFunzionalita {
        return getSuperamentoAnnoConfigByYear(this.cicloConfigurationService, this.anno);
    }

    protected readonly FunzionalitaStatus = FunzionalitaStatus;
    protected readonly ChiaveFunzionalitaEnum = ChiaveFunzionalitaEnum;
}

export function getSuperamentoAnnoConfigByYear(cicloConfigurationService: CicloConfigurationService, anno: AnnoRiferimentoValues): ConfigurazioneFunzionalita {
    return cicloConfigurationService.getFunzionalitaCorsoConfig(ChiaveFunzionalitaEnum.SuperamentoAnno, anno);
}
