import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {map, Observable, of, switchMap} from "rxjs";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {TrainingOfferService} from "../../modules/landing/cycle/training-offer/training-offer.service";
import {get} from "lodash";
import moment from "moment";
import {TranslocoService} from "@ngneat/transloco";
import {CicloConfigurationService} from "../../shared/service/ciclo-configuration.service";
import {AuthorityType, ChiaveFunzionalitaEnum, FunzionalitaStatus} from "../../../api-clients/generated/services";
import {first} from "rxjs/operators";
import {PathEnum} from "../../app.routing";
import {LogoutService} from "../../shared/service/logout.service";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";
import {checkFunctionalityState} from "./guards-utils";
import {CicloConfigDependantGuard} from "./ciclo-config-dependant.guard";
import {LocalStorageService} from "../../shared/service/local-storage.service";


@Injectable({
    providedIn: 'root'
})
export class TrainingOfferGuard extends CicloConfigDependantGuard implements CanDeactivate<any> {
    /**
     * Constructor
     */
    constructor(
        private fuseConfirmationService: FuseConfirmationService,
        private translocoService: TranslocoService,
        private trainingOfferService: TrainingOfferService,
        protected cicloConfigurationService: CicloConfigurationService,
        private logoutService: LogoutService,
        private localStorageService: LocalStorageService,
    ) {
        super(cicloConfigurationService)
    }



    _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const cicloCorsoRuolo = this.localStorageService.getCicloCorsoRuolo(true);
        const canActivate = checkFunctionalityState(this.cicloConfigurationService, ChiaveFunzionalitaEnum.AreaDidattica)
            && cicloCorsoRuolo?.ruolo !== AuthorityType.STUDENTE;
        if (!canActivate) {
            this.logoutService.goToHome();
            return of(false);
        }
        return of(true);
    }

    canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.trainingOfferService.canEditOffer
            && !this.trainingOfferService.isTrainingOfferValid
            && !this.trainingOfferService.isShowingArchived) {
                return new Observable<boolean>((subscriber) => {
                    this.fuseConfirmationService.open({
                            message: get(translation, 'training_offer.invalid_activities_message', null),
                            icon: {
                                show: true,
                                name: 'mat_solid:info',
                                color: 'basic'
                            },
                            onBackdrop: {
                                show: false,
                                backdrop: true
                            },
                            actions: [
                                {
                                    color: 'accent',
                                    label: get(translation, 'common.close', null), icon: 'close',
                                    function: () => {
                                        subscriber.next(false);
                                        subscriber.complete();
                                    }
                                },
                                {
                                    color: 'primary',
                                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                                    function: () => {
                                        subscriber.next(true);
                                        subscriber.complete();
                                    }
                                }]
                        }
                    );
                });
        } else {
            return of(true);
        }
    }

    static accessCondition(cicloCorsoRuolo: CicloCorsoRuoloInterface,
                           cicloConfigurationService: CicloConfigurationService,
                           passingStates: FunzionalitaStatus[] = [FunzionalitaStatus.ABILITATA]) {
        return checkFunctionalityState(cicloConfigurationService, ChiaveFunzionalitaEnum.AreaDidattica, passingStates)
            && cicloCorsoRuolo?.ruolo !== AuthorityType.STUDENTE;
    }

}
