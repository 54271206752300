import {Component, Inject, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AbstractDefaultComponent} from 'app/shared/abstracts/abstract-default-component/abstract-default-component';
import {OperazioneMassivaDataI} from "../../operazioni-massive-config";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {TranslocoService} from "@ngneat/transloco";
import {ApprovazioneSpeseMassivaDataI} from "../../approvazione-spesa-steps/approvazione-spese-steps-interface";


export interface ShowWithDeliberaForPlanStepI {
    label: string;
    withDelibera?: boolean;
    edited?: boolean;
}

export enum StudyPlanWithDeliberaMode {
    FIRST_PRESENTATION="FIRST_PRESENTATION",
    EDIT="EDIT",
}

@Component({
    selector: 'app-show-with-delibera-for-plan-step',
    templateUrl: './show-with-delibera-for-plan-step.component.html',
    styleUrls: ['./show-with-delibera-for-plan-step.component.scss']
})
export class ShowWithDeliberaForPlanStepComponent extends AbstractDefaultComponent implements OnInit {

    formGroup: FormGroup;
    getOptionLabel(mode: StudyPlanWithDeliberaMode){
        if(mode === StudyPlanWithDeliberaMode.EDIT){
            return 'massive_operations.approvazione_piani.edit';
        }else if(mode === StudyPlanWithDeliberaMode.FIRST_PRESENTATION){
            return 'massive_operations.approvazione_piani.first_presentation';
        } else {
            return undefined;
        }
    }

    constructor(private fb: FormBuilder,
                private translocoService: TranslocoService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<OperazioneMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.buildForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneSpeseMassivaDataI) => {
            if (inputOutputData.refreshData) {
                this.canGoNext$.next(false);
            }
            if(this.formGroup?.get('withDelibera')?.value !== inputOutputData?.deliberaMode){
                this.formGroup?.get('withDelibera')?.setValue(inputOutputData?.deliberaMode);
            }
        });
    }



    private buildForm() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.formGroup = this.fb.group({
            withDelibera: [undefined, Validators.required]
        });
        // subscribe to form value changes to update step data
        this.formGroup?.get('withDelibera')?.valueChanges?.subscribe(value => {
            this.operazioneMassivaData$.next({
                deliberaMode: value,
                //showWithDeliberaTranslatedLabel: get(translation, this.options?.find(o => o.withDelibera === value)?.label),
                refreshData: true
            });
            this.canGoNext$.next(this.formGroup?.get('withDelibera')?.valid);
        })
    }

    protected readonly Object = Object;
    protected readonly StudyPlanWithDeliberaMode = StudyPlanWithDeliberaMode;
}
