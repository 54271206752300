import {
    AttivitaTrasversaleStatus,
    CorsoPianoDiStudiPerExportViewImpl,
    PageCorsoPianoDiStudiPerExportViewImpl,
    PagePeriodoDiMobilitaStudenteInfoView,
    PageSpesaStudentePerApprovMassivaInfoView,
    PageStudenteCicloUtenteViewImpl,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    SpesaStudenteInfoViewImpl, SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus,
    TipoPeriodoEnum
} from "../../../../api-clients/generated/services";
import {ConfigCol, numFmtEuro} from "../../../shared/service/excel.service";
import moment from "moment";
import {
    getLanguagePathByAttivitaTrasversale,
    getLanguagePathBySpesaStudenteStatus,
    removeUnderscore
} from "../../../shared/utils/utils";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../shared/components/table/model/generic-table-model";
import {DEFAULT_PAGE_SIZE} from "./requests-to-be-resolved.component";
import {PathEnum} from "../../../app.routing";
import {Translation} from "@ngneat/transloco";
import {get, sortBy} from "lodash";
import {CicloConfigurationService} from "../../../shared/service/ciclo-configuration.service";

export const PERIODI_EXCEL_SHEET_CONFIG: (cicloConfigurationService: CicloConfigurationService) => ConfigCol[] =
    (cicloConfigurationService: CicloConfigurationService) => [
    {
        key: 'codiceMobilita',
        translocoHeader: 'mobility.mobility_code',
        width: 17
    },
    {
        key: 'tipoPeriodoEnum',
        translocoHeader: 'mobility.period_type',
        width: 20
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'students_list.student',
        width: 25
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'mobility.enrollment_number',
        width: 15
    },
    {
        key: 'studenteEmail',
        translocoHeader: 'mobility.student_email',
        width: 30
    },
    {
        key: 'stato',
        translocoHeader: 'mobility.state',
        width: 15
    },
    {
        key: 'dataInizio',
        translocoHeader: 'mobility.start_date',
        width: 12,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'dataFine',
        translocoHeader: 'mobility.end_date',
        width: 12,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'strutturaEsteraOspitanteDescrizione',
        translocoHeader: 'mobility.foreign_university',
        width: 35
    },
    {
        key: 'tipologiaMobilitaDescrizione',
        translocoHeader: 'mobility.mobility_type',
        width: 20
    },
    {
        key: 'tipologiaBonusDescrizione',
        translocoHeader: 'mobility.bonus_tipology',
        width: 20
    },
    {
        key: 'tipologiaInterventoSupporto',
        translocoHeader: 'mobility.support_tipology',
        width: 20
    },
    {
        key: 'sedeCotutela',
        translocoHeader: 'mobility.cotutela',
        width: 20
    },
    {
        key: 'sedeDoctorEuropeaus',
        translocoHeader: 'mobility.doctor_europeaus',
        width: 20
    },
    {
        key: 'importo',
        translocoHeader: 'common.amount',
        width: 10,
        style: {
            numFmt: numFmtEuro
        }
    },
    {
        key: 'approvalNumber',
        translocoHeader: 'budget.approval_number',
        width: 20
    },
];

function buildLinkForDettaglioStudente(periodo: PeriodoDiMobilitaStudenteInfoView, basePath: string) {
    const fragment = periodo?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca';
    return basePath + '/' + PathEnum.STUDENTS + '/' + periodo?.studenteCiclo?.utente?.id + '/' + PathEnum.MOBILITA + '#' + fragment;
}

export function mapPeriodiForExcel(periodi: PeriodoDiMobilitaStudenteInfoView[], currentBaseURL: string, translation: Translation): any[] {

    return periodi.map(periodo => ({
        ...periodo,
        strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
            + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
        tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
        tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
        dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        studenteNomeCompleto: periodo?.studenteCiclo?.utente?.cognome + ' ' + periodo?.studenteCiclo?.utente?.nome,
        studenteMatricola: periodo?.studenteCiclo?.matricola,
        studenteEmail: periodo?.studenteCiclo?.emailAteneo?.toLowerCase(),
        tipologiaInterventoSupporto: periodo?.tipoInterventoSupporto?.descrizione,
        stato: removeUnderscore(periodo?.stato),
        tipoPeriodoEnum: removeUnderscore(periodo?.tipoPeriodoEnum),
        dettaglioStudenteLink:  { text: get(translation, 'mobility.go_to_detail', null), hyperlink: buildLinkForDettaglioStudente(periodo, currentBaseURL) },
    }))
}

export function buildMobilityRequestsTableConfiguration(pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView,
                                                        pageSize: number = DEFAULT_PAGE_SIZE,
                                                        page?: number,
                                                        isRicerca?: boolean): GenericTableConfigurationModel {
    const periodiDiMobilitaStudenteForUI = pagePeriodi?.content?.map((periodo: PeriodoDiMobilitaStudenteInfoView) =>
        ({
            ...periodo,
            strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
                + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
            tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
            tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
            dataRichiestaFormatted: moment(periodo?.dataRichiesta).format('DD/MM/YYYY HH:mm'),
            dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            studenteUIMapped: [{
                ...periodo?.studenteCiclo,
                nome: periodo?.studenteCiclo?.utente?.nome,
                cognome: periodo?.studenteCiclo?.utente?.cognome,
                codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale
            }]
        }));
    return {
        configuration: {
            pageBE: page,
            data: periodiDiMobilitaStudenteForUI,
            totalElements: pagePeriodi?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.mobility_code',
                    colonnaKey: 'codiceMobilita',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.period_type',
                    colonnaKey: 'tipoPeriodoEnum',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'mobility.state',
                    colonnaKey: 'stato',
                    flex: 10,
                    statusType: PeriodoDiMobilitaStudenteStatus,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.start_date',
                    colonnaKey: 'dataInizio',
                    flex: 9,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.end_date',
                    colonnaKey: 'dataFine',
                    flex: 9,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.foreign_university',
                    colonnaKey: 'strutturaEsteraOspitanteDescrizione',
                    flex: 18,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'person',
                            click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                            tooltip: 'mobility.go_to_detail',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: isRicerca ? 'custom_table.no_data_research' :  'custom_table.no_data_mobility',
        }
    };
}



export function buildConfigurationForSpeseRequest(spese: PageSpesaStudentePerApprovMassivaInfoView,
                                                  pageSize = DEFAULT_PAGE_SIZE,
                                                  pageBE: number): GenericTableConfigurationModel {
    return {
        configuration: {
            data: spese.content.map(value => ({
                ...value,
                studenteUIMapped: [{
                    utenteNome:  value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    utenteCognome:  value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    utenteMail: value?.budgetStudente?.studenteCiclo?.utente?.mail,
                    nome: value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    cognome: value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    studenteCodiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: value?.budgetStudente?.studenteCiclo?.matricola,
                    urlImmagineProfilo: value?.budgetStudente?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: value?.budgetStudente?.studenteCiclo?.utente?.telefono
                }]
            })),
            pageBE,
            totalElements: spese?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.description',
                    colonnaKey: 'descrizione',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.codice_spesa',
                    colonnaKey: 'codiceSpesa',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.type',
                    colonnaKey: 'tipologia',
                    flex: 15,
                },
                {
                    nomeColonna: 'budget.note',
                    colonnaKey: 'nota',
                    flex: 7,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_NOTES,
                            tooltip: 'budget.note',
                            color: 'accent',
                            show: (nota?: string) => (!!nota && nota !== ''),
                            valueShow: 'nota'
                        }
                    ]
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.req_amount',
                    colonnaKey: 'importoRichiesto',
                    flex: 10,
                },
                {
                    nomeColonna: 'budget.show_attachment_file',
                    colonnaKey: 'urlAllegatiRichiesta',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [
                        {
                            nameIconButton: 'download',
                            click: TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST,
                            tooltip: 'budget.show_attachment_file',
                            color: 'accent',
                            show: (urlAllegatiRichiesta, spesa: SpesaStudenteInfoViewImpl) => spesa.urlAllegatiRichiesta?.length > 0,
                            valueShow: 'urlAllegatiRichiesta'
                        }
                    ]
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'stato',
                    flex: 20,
                    statusType: SpesaStudenteStatus,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'person',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'mobility.go_to_detail',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_budget'
        }
    };
}



export function buildConfigurationForActivitiesRequest(attivita: PageCorsoPianoDiStudiPerExportViewImpl, pageSize = DEFAULT_PAGE_SIZE, pageBE: number): GenericTableConfigurationModel {
    return {
        configuration: {
            data: attivita.content.map(value => ({
                ...value,
                studenteUIMapped: [{
                    utenteNome:  value?.utenteNome,
                    utenteCognome:  value?.utenteCognome,
                    utenteMail: value?.utenteMail,
                    nome: value?.utenteNome,
                    cognome: value?.utenteCognome,
                    studenteCodiceFiscale: value?.studenteCodiceFiscale,
                    codiceFiscale: value?.studenteCodiceFiscale,
                    studenteMatricola: value.studenteMatricola,
                    urlImmagineProfilo: value.urlImmagineProfilo,
                    utenteTelefono: value.utenteTelefono
                }]
            })),
            pageBE,
            totalElements: attivita?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 28,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.year',
                    colonnaKey: 'annoInsegnamento',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'requests.denomination',
                    colonnaKey: 'denominazione',
                    flex: 20,
                },
                {
                    nomeColonna: 'requests.description',
                    colonnaKey: 'descrizione',
                    flex: 7,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_NOTES,
                            tooltip: 'budget.note',
                            color: 'accent',
                            show: (desc?: string) => (!!desc && desc !== ''),
                            valueShow: 'descrizione'
                        }
                    ]
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'support.category',
                    colonnaKey: 'denominazioneCategoria',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 20,
                    statusType: AttivitaTrasversaleStatus
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'person',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'mobility.go_to_detail',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities'
        }
    };
}


export const SPESE_EXCEL_SHEET_CONFIG: (cicloConfigurationService: CicloConfigurationService) => ConfigCol[] =
    (cicloConfigurationService: CicloConfigurationService) => [
    {
        key: 'codiceSpesa',
        translocoHeader: 'budget.codice_spesa',
        width: 25
    },
    {
        key: 'descrizione',
        translocoHeader: 'budget.description',
        width: 17
    },
    {
        key: 'importoRichiesto',
        translocoHeader: 'budget.req_amount',
        width: 20,
        style: {
            numFmt: numFmtEuro
        }
    },
    {
        key: 'tipologia',
        translocoHeader: 'common.type',
        width: 30
    },
    {
        key: 'stato',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'dataRichiesta',
        translocoHeader: 'mobility.request_date',
        width: 15,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'common.nominative',
        width: 30,
    },

    {
        key: 'annoRiferimento',
        translocoHeader: 'common.year',
        width: 15
    },
    {
        key: 'utenteMail',
        translocoHeader: 'common.email',
        width: 20
    },
    {
        key: 'studenteCodiceFiscale',
        translocoHeader: 'common.fiscal_code',
        width: 20
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'common.enrollment_number',
        width: 20,
        hide: !cicloConfigurationService.showMatricola,
    },
    {
        key: 'nota',
        translocoHeader: 'budget.nota',
        width: 30,
    },

]

export function mapSpeseForExcel(spese: SpesaStudentePerApprovMassivaInfoView[], translation: Translation): any[] {
    return spese.map(spesa => ({
        ...spesa,
        dataRichiesta: !!spesa?.dataRichiesta ? moment(spesa?.dataRichiesta, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        studenteNomeCompleto: spesa?.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa?.budgetStudente?.studenteCiclo?.utente?.nome,
        tipologia: removeUnderscore(spesa?.tipologia),
        annoRiferimento: removeUnderscore(spesa?.annoRiferimento),
        stato: get(translation, getLanguagePathBySpesaStudenteStatus(spesa.stato)) as SpesaStudenteStatus,
        utenteMail: spesa?.budgetStudente?.studenteCiclo?.utente?.mail,
        studenteCodiceFiscale: spesa?.budgetStudente?.studenteCiclo?.codiceFiscale,
        studenteMatricola: spesa?.budgetStudente?.studenteCiclo?.matricola,
    }))
}

export function mapAttivitaForExcel(attivita: CorsoPianoDiStudiPerExportViewImpl[], translation: Translation): any[] {
    const mappedAttivita = attivita.map(value => ({
        ...value,
        studenteNomeCompleto: value?.utenteCognome + ' ' + value.utenteNome,
        statoAttivitaTrasversale: get(translation, getLanguagePathByAttivitaTrasversale(value.statoAttivitaTrasversale)) as SpesaStudenteStatus,
        presenzaDocumentoDottorando:  get(translation, value.presenzaDocumentoDottorando ? 'common.yes' : 'common.no'),
        presenzaDocumentoSupervisore:  get(translation, value.presenzaDocumentoSupervisore ? 'common.yes' : 'common.no'),
        presenzaDeliberaCoordinatore:  get(translation, value.presenzaDeliberaCoordinatore ? 'common.yes' : 'common.no'),
        categoryDenomination: value.denominazioneCategoria,
    }))
    return sortBy(mappedAttivita, [
        a => a.categoryDenomination?.toLowerCase(),
        a => a.denominazione?.toLowerCase(),
    ], ['asc', 'asc']);
}


export const ATTIVITA_EXCEL_SHEET_CONFIG: (cicloConfigurationService: CicloConfigurationService) => ConfigCol[] =
    (cicloConfigurationService: CicloConfigurationService) => [
    {
        key: 'categoryDenomination',
        translocoHeader: 'support.category',
        width: 40
    },
    {
        key: 'denominazione',
        translocoHeader: 'requests.denomination',
        width: 25
    },
    {
        key: 'descrizione',
        translocoHeader: 'requests.description',
        width: 17
    },
    {
        key: 'annoInsegnamento',
        translocoHeader: 'common.year',
        width: 17
    },
    /*    {
            key: 'tipo',
            translocoHeader: 'common.type',
            width: 20,
        },*/
    {
        key: 'dettaglioAttivitaTrasversale',
        translocoHeader: 'common.detail',
        width: 30
    },
    {
        key: 'statoAttivitaTrasversale',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'descrizioneCfu',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'presenzaDocumentoDottorando',
        translocoHeader: 'cycle_doctorate.student_document_required',
        width: 30,
    },
    {
        key: 'tipoDocumentoDottorando',
        translocoHeader: 'cycle_doctorate.student_document',
        width: 30,
    },
    {
        key: 'presenzaDocumentoSupervisore',
        translocoHeader: 'cycle_doctorate.supervisor_document_required',
        width: 30,
    },

    {
        key: 'presenzaDeliberaCoordinatore',
        translocoHeader: 'cycle_doctorate.coordinator_required',
        width: 30,
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'common.nominative',
        width: 30,
    },
    {
        key: 'utenteMail',
        translocoHeader: 'common.email',
        width: 20
    },
    {
        key: 'studenteCodiceFiscale',
        translocoHeader: 'common.fiscal_code',
        width: 20
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'common.enrollment_number',
        width: 20,
        hide: !cicloConfigurationService.showMatricola,
    },
]

export const buildStudyPlansConfiguration = (pageStudente: PageStudenteCicloUtenteViewImpl, cicloConfigurationService: CicloConfigurationService, pageSize: number = DEFAULT_PAGE_SIZE, pageBE: number): GenericTableConfigurationModel  =>{
    return {
        configuration: {
            data: pageStudente?.content?.map(studenteContent => ({
                ...studenteContent,
                studente: studenteContent.cognome?.toUpperCase() + ' ' + studenteContent.nome?.toUpperCase(),
                supervisore: (studenteContent?.supervisore?.cognome?.toUpperCase() || studenteContent?.supervisore?.nome?.toUpperCase() ) ?
                        ((studenteContent?.supervisore?.cognome?.toUpperCase() || '') + ' ' + (studenteContent?.supervisore?.nome?.toUpperCase() || '')).toUpperCase() : '',
                tipologiaBorsa: (studenteContent?.tipoBorsaPerCiclo?.descrizioneTipoBorsa),
                curriculum: (studenteContent?.percorsoDiStudi?.descrizionePercorsoDiStudi),
                dataIscrizione: moment(new Date(studenteContent?.dataIscrizione)).format('DD/MM/YYYY'),
                emailAteneo: studenteContent?.emailAteneo?.toLowerCase()
            })),
            pageBE,
            sticky: true,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pageStudente.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'custom_table.no_data_study_plan_dashboard',
            hidePaginator: false,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.student',
                    colonnaKey: 'studente',
                    flex: 19,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'menu_filter.enrollment_number',
                    colonnaKey: 'matricola',
                    flex: 8,
                    hideColonna: !cicloConfigurationService.showMatricola,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.date_of_registration',
                    colonnaKey: 'dataIscrizione',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.university_email',
                    colonnaKey: 'emailAteneo',
                    flex: 22,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.supervisor',
                    colonnaKey: 'supervisore',
                    flex: 12,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'menu_filter.type_of_study_grant',
                    colonnaKey: 'tipologiaBorsa',
                    flex: 14,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.curriculum',
                    colonnaKey: 'curriculum',
                    flex: 15,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'person',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'mobility.go_to_detail',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
        }
    };
}
