import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogWrapI} from "../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    AnnoRiferimentoValues,
    CategoriaOffertaFormativaInfoView,
    ConfigurazioneCategoriaPerAnni,
    ElementoOffertaFormativaType,
    OffertaFormativaInfoViewImpl
} from "../../../../../../api-clients/generated/services";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {BehaviorSubject, startWith, Subscription} from "rxjs";
import {MatStepper} from "@angular/material/stepper";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {ButtonType} from "../../../../../shared/components/custom-button/custom-button.component";

export interface TrainingOfferCategoryAddEditDialogI extends DialogWrapI {
    categoryToEdit: CategoriaOffertaFormativaInfoView;
    annoInsegnamento?: string;
    activityType?: ElementoOffertaFormativaType;
    trainingOffer: OffertaFormativaInfoViewImpl;
    topAlertTextLabel?: string;
    wantsToEditConfig?: boolean;
    onConfirm: (denominazione: string, descrizione: string, note: string, configurazioni: ConfigurazioneCategoriaPerAnni[],
                loading$: BehaviorSubject<boolean>, dialogRef: MatDialogRef<TrainingOfferCategoryAddEditDialogComponent>) => void;
}

@Component({
    selector: 'app-training-offer-category-add-edit-dialog',
    templateUrl: './training-offer-category-add-edit-dialog.component.html',
    styleUrls: ['./training-offer-category-add-edit-dialog.component.scss']
})
export class TrainingOfferCategoryAddEditDialogComponent extends AbstractDefaultComponent {


    loading$ = new BehaviorSubject<boolean>(false);
    outputFormatData = 'DD/MM/YYYY';
    infoGeneraliFormGroup: FormGroup;
    anniRiferimentoValues = Object.values(AnnoRiferimentoValues);
    anniRiferimentoAvailable1 = Object.values(AnnoRiferimentoValues);
    anniRiferimentoAvailable2 = Object.values(AnnoRiferimentoValues);
    anniRiferimentoAvailable3 = Object.values(AnnoRiferimentoValues);
    categoryStepperSelectedStepIndex: number = 0;
    anniRiferimentoAvailable: AnnoRiferimentoValues[][];
    private firstConfigYearsValueChangeSub: Subscription | undefined;
    private secondConfigYearsValueChangeSub: Subscription | undefined;
    private thirdConfigYearsValueChangeSub: Subscription | undefined;
    configurationsForRecap: ConfigurazioneCategoriaPerAnni[];
    configFormGroups: FormGroup[];
    visitedRecap: boolean;

    @ViewChild('categoryStepper') categoryCreationStepper: MatStepper;

    protected readonly ElementoOffertaFormativaType = ElementoOffertaFormativaType;
    protected readonly AnnoRiferimentoValues = AnnoRiferimentoValues;
    canDeleteSomeConfig: boolean;
    protected readonly ButtonType = ButtonType;

    constructor(@Inject(MAT_DIALOG_DATA) public data: TrainingOfferCategoryAddEditDialogI,
                public dialogRef: MatDialogRef<TrainingOfferCategoryAddEditDialogComponent>,
                private fb: FormBuilder,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,

    ) {
        super();
        this.categoryStepperSelectedStepIndex = this.data.wantsToEditConfig ? 1 : 0;
        this.createForm();
    }

    private createFormForConfiguration(configIndex: number): FormGroup {
        const configurationForThisYear = this.data?.categoryToEdit?.configurazione?.configurazioni?.[configIndex];
        const form = this.fb.group({
            anni: [this.getCategoryConfigsYearsFromBE(configurationForThisYear) || [], Validators.required],
            attivita_presunte: [configurationForThisYear?.flag_aggiunta_attivita_presunte ?? false],
            attivita_presunte_group: this.fb.group({
                cfuIsDescrizione: [!!configurationForThisYear?.configurazione_attivita_presunte?.descrizione_cfu],
                cfu: [configurationForThisYear?.configurazione_attivita_presunte?.cfu
                        ?? configurationForThisYear?.configurazione_attivita_presunte?.descrizione_cfu, Validators.required],
                primoAnno: configurationForThisYear?.configurazione_attivita_presunte?.primo_anno,
                secondoAnno: configurationForThisYear?.configurazione_attivita_presunte?.secondo_anno,
                terzoAnno: configurationForThisYear?.configurazione_attivita_presunte?.terzo_anno,
                documentoDottorando: this.fb.group({
                    presenza: configurationForThisYear?.configurazione_attivita_presunte?.presenza_documento_dottorando,
                    tipo: configurationForThisYear?.configurazione_attivita_presunte?.tipo_documento_dottorando,
                }, {validators: CustomValidators.PresenzaDocumentoValidator()}),
                documentoSupervisore: this.fb.group({
                    presenza: configurationForThisYear?.configurazione_attivita_presunte?.presenza_documento_supervisore,
                    tipo: configurationForThisYear?.configurazione_attivita_presunte?.tipo_documento_supervisore,
                }, {validators: CustomValidators.PresenzaDocumentoValidator()}),
                presenzaDeliberaCoordinatore: configurationForThisYear?.configurazione_attivita_presunte?.presenza_delibera_coordinatore,
            }, {validators: CustomValidators.AtLeastOneYearValidator()}),
            attivita_proposte_dottorando: [configurationForThisYear?.flag_aggiunta_attivita_proposte ?? false],
            attivita_proposte_dottorando_group: this.fb.group({
                cfuIsDescrizione: [!!configurationForThisYear?.configurazione_attivita_proposte?.descrizione_cfu],
                cfu: [configurationForThisYear?.configurazione_attivita_proposte?.cfu
                        ?? configurationForThisYear?.configurazione_attivita_proposte?.descrizione_cfu, Validators.required],
                primoAnno: configurationForThisYear?.configurazione_attivita_proposte?.primo_anno,
                secondoAnno: configurationForThisYear?.configurazione_attivita_proposte?.secondo_anno,
                terzoAnno: configurationForThisYear?.configurazione_attivita_proposte?.terzo_anno,
                documentoDottorando: this.fb.group({
                    presenza: configurationForThisYear?.configurazione_attivita_proposte?.presenza_documento_dottorando,
                    tipo: configurationForThisYear?.configurazione_attivita_proposte?.tipo_documento_dottorando,
                }, {validators: CustomValidators.PresenzaDocumentoValidator()}),
                documentoSupervisore: this.fb.group({
                    presenza: configurationForThisYear?.configurazione_attivita_proposte?.presenza_documento_supervisore,
                    tipo: configurationForThisYear?.configurazione_attivita_proposte?.tipo_documento_supervisore,
                }, {validators: CustomValidators.PresenzaDocumentoValidator()}),
                presenzaDeliberaCoordinatore: configurationForThisYear?.configurazione_attivita_proposte?.presenza_delibera_coordinatore,
            }, {validators: CustomValidators.AtLeastOneYearValidator()}),
            numero_attivita_obbligatorie: [configurationForThisYear?.numero_attivita_obbligatorie || 0, Validators.required],
            numero_cfu_minimo: [configurationForThisYear?.numero_minimo_cfu || 0, Validators.required],
        });
        // subscribe to attivita_presunte and attivita_proposte_dottorando changes to enable/disable the groups form
        form.valueChanges.pipe(startWith(form.value)).subscribe(value => {
            if(value.attivita_presunte){
                form.get('attivita_presunte_group').enable({ emitEvent: false });
            } else {
                form.get('attivita_presunte_group').disable({ emitEvent: false });
            }
            if(value.attivita_proposte_dottorando){
                form.get('attivita_proposte_dottorando_group').enable({ emitEvent: false });
            } else {
                form.get('attivita_proposte_dottorando_group').disable({ emitEvent: false });
            }
        });
        // subscribe to cfuIsDescrizione changes to clear the cfu value on change
        const formPresunteIsDescrizione = form.get('attivita_presunte_group').get('cfuIsDescrizione');
        formPresunteIsDescrizione.valueChanges.subscribe(value => {
            form.get('attivita_presunte_group').get('cfu').setValue(null, { emitEvent: false });
        });
        const formProposteIsDescrizione = form.get('attivita_proposte_dottorando_group').get('cfuIsDescrizione');
        formProposteIsDescrizione.valueChanges.subscribe(value => {
            form.get('attivita_proposte_dottorando_group').get('cfu').setValue(null, { emitEvent: false });
        });
        return form;
    }

    createForm(): void {
        this.infoGeneraliFormGroup = this.fb.group({
            denominazione: [this.data?.categoryToEdit?.denominazione, Validators.required],
            descrizione: [this.data?.categoryToEdit?.descrizione],
            note: [this.data?.categoryToEdit?.note],
        });

        if(this.data.categoryToEdit){
            this.configFormGroups = this.data?.categoryToEdit?.configurazione?.configurazioni
                ?.map((config, index) => this.createFormForConfiguration(index));
        } else {
            this.configFormGroups = [
                this.createFormForConfiguration(0),
            ];
        }
        this.canDeleteSomeConfig = this.configFormGroups.length > 1;

        this.subscribeToConfigsYearsValueChanges();

    }

    closeDialog() {
        this.dialogRef.close();
    }

    save() {
        this.data.onConfirm(
            this.infoGeneraliFormGroup.get('denominazione').value,
            this.infoGeneraliFormGroup.get('descrizione').value,
            this.infoGeneraliFormGroup.get('note').value,
            this.configurationsForRecap,
            this.loading$,
            this.dialogRef,
        );
    }

    get descrizioneCtrl(): FormControl {
        return this.infoGeneraliFormGroup.get('descrizione') as FormControl;
    }

    get noteCtrl(): FormControl {
        return this.infoGeneraliFormGroup.get('note') as FormControl;
    }

    private subscribeToConfigsYearsValueChanges() {
        this.anniRiferimentoAvailable1 = Object.values(AnnoRiferimentoValues);
        this.anniRiferimentoAvailable2 = Object.values(AnnoRiferimentoValues);
        this.anniRiferimentoAvailable3 = Object.values(AnnoRiferimentoValues);
        this.anniRiferimentoAvailable = [this.anniRiferimentoAvailable1, this.anniRiferimentoAvailable2, this.anniRiferimentoAvailable3]

        this.firstConfigYearsValueChangeSub?.unsubscribe();
        this.secondConfigYearsValueChangeSub?.unsubscribe();
        this.thirdConfigYearsValueChangeSub?.unsubscribe();

        const firstConfigYearsFormCtrl = this.configFormGroups?.[0]?.get('anni');
        const secondConfigYearsFormCtrl = this.configFormGroups?.[1]?.get('anni');
        const thirdConfigYearsFormCtrl = this.configFormGroups?.[2]?.get('anni');

        this.firstConfigYearsValueChangeSub = firstConfigYearsFormCtrl?.valueChanges?.pipe(startWith(firstConfigYearsFormCtrl.value))?.subscribe(value => {
            this.anniRiferimentoAvailable2 = this.anniRiferimentoValues.filter(v =>
                !value?.includes(v) && !thirdConfigYearsFormCtrl?.value?.includes(v));
            this.anniRiferimentoAvailable3 = this.anniRiferimentoValues.filter(v =>
                !value?.includes(v) && !secondConfigYearsFormCtrl?.value?.includes(v));
            this.anniRiferimentoAvailable = [this.anniRiferimentoAvailable1, this.anniRiferimentoAvailable2, this.anniRiferimentoAvailable3];
            this.adjustAttivitaPresunteYearsCtrl(0);
            this.adjustAttivitaProposteYearsCtrl(0);
        });
        this.secondConfigYearsValueChangeSub = secondConfigYearsFormCtrl?.valueChanges?.pipe(startWith(secondConfigYearsFormCtrl.value))?.subscribe(value => {
            this.anniRiferimentoAvailable1 = this.anniRiferimentoValues.filter(v =>
                !value?.includes(v) && !thirdConfigYearsFormCtrl?.value?.includes(v));
            this.anniRiferimentoAvailable3 = this.anniRiferimentoValues.filter(v =>
                !value?.includes(v) && !firstConfigYearsFormCtrl?.value?.includes(v));
            this.anniRiferimentoAvailable = [this.anniRiferimentoAvailable1, this.anniRiferimentoAvailable2, this.anniRiferimentoAvailable3];
            this.adjustAttivitaPresunteYearsCtrl(1);
            this.adjustAttivitaProposteYearsCtrl(1);
        });
        this.thirdConfigYearsValueChangeSub = thirdConfigYearsFormCtrl?.valueChanges?.pipe(startWith(thirdConfigYearsFormCtrl.value))?.subscribe(value => {
            this.anniRiferimentoAvailable2 = this.anniRiferimentoValues.filter(v =>
                !value?.includes(v) && !firstConfigYearsFormCtrl?.value?.includes(v));
            this.anniRiferimentoAvailable1 = this.anniRiferimentoValues.filter(v =>
                !value?.includes(v) && !secondConfigYearsFormCtrl?.value?.includes(v));
            this.anniRiferimentoAvailable = [this.anniRiferimentoAvailable1, this.anniRiferimentoAvailable2, this.anniRiferimentoAvailable3];
            this.adjustAttivitaPresunteYearsCtrl(2);
            this.adjustAttivitaProposteYearsCtrl(2);
        });
    }

    private adjustAttivitaPresunteYearsCtrl(configIndex: number) {
        const configFormGroup = this.configFormGroups?.[configIndex];
        if(!configFormGroup.get('anni').value?.includes(AnnoRiferimentoValues.PRIMO)) {
            configFormGroup?.get('attivita_presunte_group')?.get('primoAnno')?.setValue(null);
        }
        if(!configFormGroup.get('anni').value?.includes(AnnoRiferimentoValues.SECONDO)) {
            configFormGroup?.get('attivita_presunte_group')?.get('secondoAnno')?.setValue(null);
        }
        if(!configFormGroup.get('anni').value?.includes(AnnoRiferimentoValues.TERZO)) {
            configFormGroup?.get('attivita_presunte_group')?.get('terzoAnno')?.setValue(null);
        }
    }

    private adjustAttivitaProposteYearsCtrl(configIndex: number) {
        const configFormGroup = this.configFormGroups?.[configIndex];
        if(!configFormGroup.get('anni').value?.includes(AnnoRiferimentoValues.PRIMO)) {
            configFormGroup?.get('attivita_proposte_dottorando_group')?.get('primoAnno')?.setValue(null);
        }
        if(!configFormGroup.get('anni').value?.includes(AnnoRiferimentoValues.SECONDO)) {
            configFormGroup?.get('attivita_proposte_dottorando_group')?.get('secondoAnno')?.setValue(null);
        }
        if(!configFormGroup.get('anni').value?.includes(AnnoRiferimentoValues.TERZO)) {
            configFormGroup?.get('attivita_proposte_dottorando_group')?.get('terzoAnno')?.setValue(null);
        }
    }

    buildConfigurationsForBE() {
        this.configurationsForRecap = this.configFormGroups?.map(formConfigGrop => this.mapFormForBE(formConfigGrop.value));
    }

    stepperSelectionChange($event: StepperSelectionEvent) {
        this.categoryStepperSelectedStepIndex = $event.selectedIndex;
        if($event.selectedStep.ariaLabel === 'recap'){
            this.visitedRecap = true;
            // every time we go to recap we build the configurations for the BE
            this.buildConfigurationsForBE();
        }
    }

    private mapFormForBE(formValue: any): ConfigurazioneCategoriaPerAnni {
        return {
            primo_anno: formValue.anni?.includes(AnnoRiferimentoValues.PRIMO),
            secondo_anno: formValue.anni?.includes(AnnoRiferimentoValues.SECONDO),
            terzo_anno: formValue.anni?.includes(AnnoRiferimentoValues.TERZO),
            numero_attivita_obbligatorie: formValue.numero_attivita_obbligatorie,
            numero_minimo_cfu: formValue.numero_cfu_minimo,
            flag_aggiunta_attivita_presunte: formValue.attivita_presunte,
            configurazione_attivita_presunte: {
                cfu: !formValue.attivita_presunte_group?.cfuIsDescrizione ? formValue.attivita_presunte_group?.cfu : undefined,
                descrizione_cfu: formValue.attivita_presunte_group?.cfuIsDescrizione ? formValue.attivita_presunte_group?.cfu : undefined,
                primo_anno: formValue.attivita_presunte_group?.primoAnno,
                secondo_anno: formValue.attivita_presunte_group?.secondoAnno,
                terzo_anno: formValue.attivita_presunte_group?.terzoAnno,
                presenza_documento_dottorando: !!formValue.attivita_presunte_group?.documentoDottorando.presenza,
                tipo_documento_dottorando: formValue.attivita_presunte_group?.documentoDottorando.presenza ? formValue.attivita_presunte_group?.documentoDottorando.tipo : undefined,
                presenza_documento_supervisore: !!formValue.attivita_presunte_group?.documentoSupervisore.presenza,
                tipo_documento_supervisore: formValue.attivita_presunte_group?.documentoSupervisore.presenza ? formValue.attivita_presunte_group?.documentoSupervisore.tipo : undefined,
                presenza_delibera_coordinatore: !!formValue.attivita_presunte_group?.presenzaDeliberaCoordinatore,
            },
            flag_aggiunta_attivita_proposte: formValue?.attivita_proposte_dottorando,
            configurazione_attivita_proposte: {
                cfu: !formValue.attivita_proposte_dottorando_group?.cfuIsDescrizione ? formValue.attivita_proposte_dottorando_group?.cfu : undefined,
                descrizione_cfu: formValue.attivita_proposte_dottorando_group?.cfuIsDescrizione ? formValue.attivita_proposte_dottorando_group?.cfu : undefined,
                primo_anno: formValue.attivita_proposte_dottorando_group?.primoAnno,
                secondo_anno: formValue.attivita_proposte_dottorando_group?.secondoAnno,
                terzo_anno: formValue.attivita_proposte_dottorando_group?.terzoAnno,
                presenza_documento_dottorando: !!formValue.attivita_proposte_dottorando_group?.documentoDottorando.presenza,
                tipo_documento_dottorando: formValue.attivita_proposte_dottorando_group?.documentoDottorando.presenza ? formValue.attivita_proposte_dottorando_group?.documentoDottorando.tipo : undefined,
                presenza_documento_supervisore: !!formValue.attivita_proposte_dottorando_group?.documentoSupervisore.presenza,
                tipo_documento_supervisore: formValue.attivita_proposte_dottorando_group?.documentoSupervisore.presenza ? formValue.attivita_proposte_dottorando_group?.documentoSupervisore.tipo : undefined,
                presenza_delibera_coordinatore: !!formValue.attivita_proposte_dottorando_group?.presenzaDeliberaCoordinatore,
            },
        };
    }

    private getCategoryConfigsYearsFromBE(configurazioneCategoriaPerAnni: ConfigurazioneCategoriaPerAnni): AnnoRiferimentoValues[] {
        if(!configurazioneCategoriaPerAnni){
            return [];
        } else {
            let years = [];
            if(configurazioneCategoriaPerAnni.primo_anno){
                years.push(AnnoRiferimentoValues.PRIMO);
            }
            if(configurazioneCategoriaPerAnni.secondo_anno){
                years.push(AnnoRiferimentoValues.SECONDO);
            }
            if(configurazioneCategoriaPerAnni.terzo_anno){
                years.push(AnnoRiferimentoValues.TERZO);
            }
            return years;
        }
    }

    addNewConfig(cannotGoNext: boolean) {
        if(cannotGoNext){
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.configFormGroups.push(this.createFormForConfiguration(this.configFormGroups.length));
            this.subscribeToConfigsYearsValueChanges();
            setTimeout(() => {
                this.categoryCreationStepper.next();
                this.canDeleteSomeConfig = this.configFormGroups.length > 1;
            }, 100);
        }
    }

    trackByFnConfigs = (index: number) => index;

    nextCategoryStep(cannotGoNext: boolean) {
        if(cannotGoNext){
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.categoryCreationStepper.next();
            if(this.categoryCreationStepper.selected.ariaLabel === 'recap'){
                this.buildConfigurationsForBE();
            }
        }
    }

    nextConfigStep(configStepper: MatStepper, cannotGoNext: boolean) {
        if(cannotGoNext){
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            configStepper.next();
        }
    }

    removeConfigAtIndex(configIndex: number) {
        this.configFormGroups.splice(configIndex, 1);
        this.canDeleteSomeConfig = this.configFormGroups.length > 1;
        this.subscribeToConfigsYearsValueChanges();
        if(this.visitedRecap){
            this.buildConfigurationsForBE();
        }
        this.categoryStepperSelectedStepIndex -= 1;
    }

}

