import {AbstractDefaultComponent} from "app/shared/abstracts/abstract-default-component/abstract-default-component";
import {AfterViewInit, Component, OnDestroy, ViewChild} from "@angular/core";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum
} from "../../../shared/components/table/model/generic-table-model";
import {Router} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {
    Audit,
    AuditService,
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PageAudit,
    PeriodoDiMobilitaStudenteStatus, StudenteCicloIdAndUtenteInfoView,
} from "../../../../api-clients/generated/services";
import {DialogInfoComponent, DialogInfoI} from "../../../shared/components/dialog-info/dialog-info.component";
import {get, has, includes, isEmpty} from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {PathEnum} from "../../../app.routing";
import {TranslocoService} from "@ngneat/transloco";
import {finalize, map, Observable, ObservedValueOf, OperatorFunction, Subscription, take, takeUntil} from "rxjs";
import {FuseMediaWatcherService} from "../../../../@fuse/services/media-watcher";
import {MatDrawer} from "@angular/material/sidenav";
import {filter, switchMap, tap} from "rxjs/operators";
import {AppInitService} from "../../../shared/service/app-init.service";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {LogoutService} from "../../../shared/service/logout.service";
import {
    AuditSidebarFilterContainerComponent
} from "./audit-sidebar-filter-container/audit-sidebar-filter-container.component";
import {FormControl, FormGroup} from "@angular/forms";
import moment from "moment/moment";
import {UpperCasePipe} from "@angular/common";
import {SafeUrl} from "@angular/platform-browser";
import {buildAuditRequestsTableConfiguration} from "./audit-utils";
import {AuditFilterManagerService, AuditFilters} from "../../../shared/service/audit-filter-manager.service";
import {
    AuditTopbarFilterContainerComponent
} from "./audit-topbar-filter-container/audit-topbar-filter-container.component";
import {DialogAuditDetailsComponent, DialogAuditDetailsI} from "./dialog-audit-details/dialog-audit-details.component";
import {GenericTableComponent} from "../../../shared/components/table/generic-table/generic-table.component";
import {omit} from "lodash-es";
import {CicloConfigurationService} from "../../../shared/service/ciclo-configuration.service";

export const DEFAULT_PAGE_SIZE = 20;

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent extends AbstractDefaultComponent implements OnDestroy, AfterViewInit {

    loading: boolean;
    protected readonly Object = Object;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    currentFilterListChipLabels: Array<string>;
    drawerOpened: boolean = false;
    @ViewChild('drawer') drawer: MatDrawer;
    fieldsLabelMap: Map<string, string>;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    showMiniLoader: boolean = false;
    @ViewChild(AuditSidebarFilterContainerComponent) auditSidebarFilterContainerComponent: AuditSidebarFilterContainerComponent;
    @ViewChild(AuditTopbarFilterContainerComponent) auditTopbarFilterContainerComponent: AuditTopbarFilterContainerComponent;
    currentLanguage: string;
    currentRuolo: AuthorityType;
    sottoruoli: AuthorityType[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    protected readonly AuthorityType = AuthorityType;
    private pageSize: number = DEFAULT_PAGE_SIZE;
    private filterChangesSubscription: Subscription;
    tableConfiguration: GenericTableConfigurationModel;
    private page: number;
    private table: GenericTableComponent;
    @ViewChild(GenericTableComponent) set genericTable(gt: GenericTableComponent) {
        this.table = gt;
    }

    constructor(private router: Router,
                private translocoService: TranslocoService,
                private dialog: MatDialog,
                private _fuseMediaWatcherService: FuseMediaWatcherService,
                private auditFilterManagerService: AuditFilterManagerService,
                private appInitService: AppInitService,
                private auditService: AuditService,
                private fuseConfirmationService: FuseConfirmationService,
                private logoutService: LogoutService,
                private cicloConfigurationService: CicloConfigurationService,
    ) {
        super();
        this.fieldsLabelMap = this.auditFilterManagerService.fieldsLabelMap;
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                this.drawerOpened = false;
            });
        if (!this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes(PathEnum.MOBILITA)) {
            this.getMainFormGroup()?.reset({emitEvent: false});
        }
        this.translocoService.langChanges$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(lang => this.currentLanguage = lang);
    }

    ngAfterViewInit(): void {
        if(!this.filterChangesSubscription) {
            this.filterChangesSubscription = this.subscribeToFilterChanges(true);
        } else {
            this.reset();
        }
    }


    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const formGroupValue = omit(this.getMainFormGroup()?.value, 'codiceRispostaNonMode');
        if (!isEmpty(formGroupValue)) {
            const entries: [string, any][] = Object.entries(formGroupValue);
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            return filteredEntries.map(([key, value]) => {
                const translatedKey: string = get(translation, this.fieldsLabelMap.get(key), key);
                let nonModeString = '';
                if(key === 'codiceRisposta' && has(this.getMainFormGroup()?.value, 'codiceRispostaNonMode')) {
                    nonModeString = ' ' + get(translation, this.getMainFormGroup()?.value?.codiceRispostaNonMode ? 'menu_filter.not_is' : 'menu_filter.is', null);
                }
                return translatedKey + nonModeString + ': ' + value;
            })
        } else {
            return [];
        }
    }

    getAuditRequest(isFirstTime?: boolean, filters?: AuditFilters, page: number = 0, size: number = 10): void {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap((value) => this.getAudit$(isFirstTime, filters, page, size))
        ).subscribe({
            next: (value) => {
                this.auditFilterManagerService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                this.auditFilterManagerService.filterApplied$.next(this.getMainFormGroup().getRawValue());
                this.currentFilterListChipLabels = this.getListChipsLabel();
            },
            error: (err) => {
                console.log(err)
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.goToHome(),
                    () => this.getAuditRequest(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getAudit$(isFirstTime?: boolean, filters?: AuditFilters, page: number = 0, size: number = DEFAULT_PAGE_SIZE): Observable<PageAudit> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.auditService.getAuditsForm(
            !!filters?.dataDa ? moment(filters.dataDa).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            !!filters?.dataA ? moment(filters.dataA).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            (!!filters?.codiceRisposta && !filters?.codiceRispostaNonMode) ? filters.codiceRisposta : undefined,
            (!!filters?.codiceRisposta && filters?.codiceRispostaNonMode) ? filters.codiceRisposta : undefined,
            !!filters?.nominativo ? filters.nominativo : undefined,
            !!filters?.uri ? filters.uri : undefined,
            !!filters?.operazione ? filters.operazione : undefined,
            page, size, undefined
        ).pipe(
            tap((pageAudit: PageAudit) => {
                this.tableConfiguration = buildAuditRequestsTableConfiguration(pageAudit, this.pageSize);
                if(page == 0 && this.table?.getPaginator()) {
                    this.table.getPaginator().pageIndex = 0;
                }
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.loading = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        )
    }

    catchErrorInAuditRequest$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }


    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.SHOW:
                this.openAuditInfoDialog($event?.value)
                break;
        }
    }

    pageAction($event: PageEvent) {
        const dataFormValues = this.auditTopbarFilterContainerComponent?.topbarFilterForm?.getRawValue();
        const combinedFilters: AuditFilters = {
            ...this.getMainFormGroup()?.getRawValue(),
            dataDa: moment(dataFormValues.dataDa).utc() as unknown as string,
            dataA: moment(dataFormValues.dataA).utc() as unknown as string,
        };
        this.page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getAuditRequest(false, combinedFilters, $event.pageIndex, $event.pageSize);
    }

    private openAuditInfoDialog(audit: Audit) {
        const data: DialogAuditDetailsI = {
            title: 'audit.info',
            icon: {
                name: 'mat_solid:info',
                color: 'basic'
            },
            audit: audit
        };
        this.dialog.open(DialogAuditDetailsComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            maxWidth: '95vw',
            minWidth: '90vw'
        });
    }

    closeDrawer() {
        this.drawer.close();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.auditFilterManagerService.checkApplyFilterClick$.next(false);
    }

    reset(): void {
        this.auditFilterManagerService?.resetFormGroup();
        this.auditSidebarFilterContainerComponent?.resetFilterApplied();
        this.auditFilterManagerService?.checkApplyFilterClick$.next(true);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.auditFilterManagerService?.mainFormGroup;
    }


    private subscribeToFilterChanges(isFirstTime: boolean = false): Subscription {
        const formValues = this.auditTopbarFilterContainerComponent?.topbarFilterForm?.getRawValue();
        const combinedFilters: AuditFilters = {
            ...this.getMainFormGroup()?.getRawValue(),
            dataDa: moment(formValues.dataDa).utc() as unknown as string,
            dataA: moment(formValues.dataA).utc() as unknown as string,
        };
        this.page = 0;
        this.getAuditRequest(isFirstTime, combinedFilters, 0, this.pageSize);
        return this.auditFilterManagerService.appliedFiltersObsevable$().pipe(
            filter(Boolean),
            map(filters => {
                const formValues = this.auditTopbarFilterContainerComponent?.topbarFilterForm?.getRawValue();
                return {
                    ...filters,
                    dataDa: moment(formValues.dataDa).utc() as unknown as string,
                    dataA: moment(formValues.dataA).utc() as unknown as string,
                }
            }),
            tap(_ => this.page = 0),
            switchMap(combinedFilters => this.getAudit$(false, combinedFilters, 0, this.pageSize).pipe(
                this.catchErrorInAuditRequest$(this.getAudit$(false, combinedFilters, 0, this.pageSize))
            )),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: () => {
                    this.auditFilterManagerService.filterApplied$.next(this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                },
                error: (err) => console.log(err)
            }
        );
    }


    onDatesApplied() {
        const formValues = this.auditTopbarFilterContainerComponent?.topbarFilterForm?.getRawValue();
        const combinedFilters: AuditFilters = {
            ...this.getMainFormGroup()?.getRawValue(),
            dataDa: moment(formValues?.dataDa).utc(),
            dataA: moment(formValues?.dataA).utc(),
        };
        this.getAudit$(false, combinedFilters, 0, this.pageSize).pipe(
            this.catchErrorInAuditRequest$(this.getAudit$(false, combinedFilters, 0, this.pageSize)),
        takeUntil(this.destroy$)
        ).subscribe(
            {
                next: () => {},
                error: (err) => console.log(err)
            }
        );
    }

    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.auditSidebarFilterContainerComponent?.setFilterApplied();
        if (hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }

    getDataForFilters() {
        this.toogleFilterContainer(true);
    }

}
