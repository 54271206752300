// eslint-disable-next-line max-len
import {
    AggiornamentoPianoDiStudiInfoView,
    AnnoRiferimentoValues,
    AttivitaExtraPianoDiStudiStatus,
    AttivitaOffertaFormativaInfoView,
    AttivitaTrasversaleStatus,
    AuthorityType,
    CategoriaInAggiornamentoInPianoDiStudiInfoView,
    CorsoAggiornamentoPianoDiStudiInfoView,
    CorsoPianoDiStudiInfoView,
    DatiCorsoOffertaFormativa,
    ElementoOffertaFormativaType,
    PianoDiStudiInfoView,
    PianoDiStudiInfoViewImpl,
    StudenteCicloInfoView,
    TipoModificaCorsoPianoDiStudi
} from '../../../../../api-clients/generated/services';
import {hideDelibera} from "../../../../../@fuse/services/confirmation";
import {annoRiferimentoFromRomanNumeral, getCosupPermessoApprovazione} from "../../../../shared/utils/utils";
import {
    TrainingOfferCategoryDataUI
} from "../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {TrainingOfferCategoryByTypeDataUI} from "../../cycle/training-offer/training-offer.component";
import {UIActivityI} from "./study-plans-categories-utils";
import {isNil} from "lodash";

export function calculateTotalCFUOfActivitiesInCategories(categories: CategoriaInAggiornamentoInPianoDiStudiInfoView[]): number {
/*    const cfuCorsiSelezionatiList = corsi?.filter(item =>
        item.datiCorsoOffertaFormativa.annoInsegnamento === anno
        && item.tipoModifica !== TipoModificaCorsoPianoDiStudi.RIMOZIONE
        && item.datiCorsoOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE
    )?.map(corso =>
        corso.datiCorsoOffertaFormativa.cfu || 0);
    return isEmpty(cfuCorsiSelezionatiList) ? 0 : cfuCorsiSelezionatiList?.reduce((accumulator, cfu) => accumulator + cfu);*/

    return categories.flatMap(category =>
        category.corsi
            ?.filter(item =>
                item.tipoModifica !== TipoModificaCorsoPianoDiStudi.RIMOZIONE
                // && item.datiCorsoOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE
            )
            ?.map(attivita => attivita.datiCorsoOffertaFormativa?.cfu ?? 0))
        ?.reduce((prev, curr) => prev + curr, 0);
}


export function calculateTotalCFUOfActivitiesInCategory(category: CategoriaInAggiornamentoInPianoDiStudiInfoView): number {
    return category.corsi?.filter(item =>
            item.tipoModifica !== TipoModificaCorsoPianoDiStudi.RIMOZIONE
            // && item.datiCorsoOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE
        )?.map(attivita => attivita.datiCorsoOffertaFormativa?.cfu ?? 0)?.reduce((prev, curr) => prev + curr, 0);
}

export function calculateSelectedCFUInCategoriesConf(categories: TrainingOfferCategoryDataUI[]): number {
    return categories?.map(c => c.totaleCfu)?.reduce((prev, curr) => prev + curr, 0);
}

export function calculateSelectedCFUInCategoryConf(category: TrainingOfferCategoryDataUI,
                                                   draft: AggiornamentoPianoDiStudiInfoView,
                                                   categoryType: ElementoOffertaFormativaType,
                                                   categoryYear: string): number {
    let cfuNotPresumedAndProposed = 0;
    if(categoryType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {
        // transversal are stored in attivitaCategoriaAssociations
        cfuNotPresumedAndProposed = category.attivitaCategoriaAssociations
            ?.map(attivita => attivita.attivitaOffertaFormativa)
            ?.filter((attivita: any) => !attivita?.idAttivitaProposta && !attivita?.idAttivitaPresunta)
            ?.map(attivita => attivita?.cfu ?? 0)
            ?.reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0) ?? 0;
    } else {
        // courses and extra are stored in selectedActivities
        cfuNotPresumedAndProposed = category.selectedActivities
            ?.map(attivita => attivita?.data?.cfu ?? 0)
            ?.reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0) ?? 0;
    }
    // proposed and presumed come from draftInReadMode currently editing copy
    const cfuPresumedAndProposed =  draft?.categorie
        ?.filter(c => c.tipoAttivitaContenute === categoryType)
        ?.find(c => c.idCategoriaOffertaFormativa === category.id)
            ?.corsi
            ?.filter(attivita => attivita.datiCorsoOffertaFormativa?.annoInsegnamento === annoRiferimentoFromRomanNumeral(categoryYear))
            ?.filter(attivita => attivita.datiCorsoOffertaFormativa?.idAttivitaProposta || attivita.datiCorsoOffertaFormativa?.idAttivitaPresunta)
            ?.map(attivita => attivita?.datiCorsoOffertaFormativa?.cfu ?? 0)
        ?.reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0) ?? 0;
    return cfuPresumedAndProposed + cfuNotPresumedAndProposed;
}
export function calculateSelectedActivitiesInCategoryConf(category: TrainingOfferCategoryDataUI,
                                                          draft: AggiornamentoPianoDiStudiInfoView,
                                                          categoryType: ElementoOffertaFormativaType,
                                                          categoryYear: string): number {
    let notPresumedAndProposed= 0;
    if(categoryType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {
        // transversal are stored in attivitaCategoriaAssociations
        notPresumedAndProposed = category.attivitaCategoriaAssociations
            ?.map(attivita => attivita.attivitaOffertaFormativa)
            ?.filter((attivita: any) => !attivita?.idAttivitaProposta && !attivita?.idAttivitaPresunta)
            ?.length ?? 0;
    } else {
        // courses and extra are stored in selectedActivities
        notPresumedAndProposed = category.selectedActivities
            ?.length ?? 0;
    }
    // proposed and presumed come from draftInReadMode currently editing copy
    const presumedAndProposed =  draft?.categorie
        ?.filter(c => c.tipoAttivitaContenute === categoryType)
        ?.find(c => c.idCategoriaOffertaFormativa === category.id)
            ?.corsi
            ?.filter(attivita => attivita.datiCorsoOffertaFormativa?.annoInsegnamento === annoRiferimentoFromRomanNumeral(categoryYear))
            ?.filter(attivita => attivita.datiCorsoOffertaFormativa?.idAttivitaProposta || attivita.datiCorsoOffertaFormativa?.idAttivitaPresunta)
            ?.length ?? 0;
    return notPresumedAndProposed + presumedAndProposed;
}


export function calculateSelectedCFUInCategoriesByYear(categories: TrainingOfferCategoryByTypeDataUI[]): number {
    return categories?.map(c => c.totaleCfu)?.reduce((prev, curr) => prev + curr, 0);
}


export function showSuperamentoCorso(value, element: CorsoPianoDiStudiInfoView, idUserLogged, isDocenteCorso, isSupervisore): boolean {
    const isDocenteEnabled = (isDocenteCorso && element?.docenti?.find(docente => docente.id === idUserLogged));
    const isSupevisoreEnabled = (isSupervisore && element?.docenti?.length === 0 && element?.docentiExtra?.length > 0);
    return !element?.superato && (!!isDocenteEnabled || !!isSupevisoreEnabled);
}

// cfu ottenuti vanno sempre presi dal piano approvato
export function getCfuOttentutiFromApprovedPlan(item: DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string, forTrasversali = false) {
    return approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idCorsoInOffertaFormativa && a.idCorsoInOffertaFormativa === (item.idCorsoInOffertaFormativa ?? item.id) && !forTrasversali) ||
            (a.idAttivitaTrasversale && a.idAttivitaTrasversale === (item.idAttivitaTrasversale ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)))
        ?.cfuOttenuti || 0;
}

// cfu previsti vanno presi dal campo cfu, che mantengo sempre aggiornato col valore della bozza
// (anche se la source dei dati è l'offerta, vedi nuovaPresentazioneEditMode)
export function getCfuPrevisti(item: DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string, forTrasversali = false) {
    if(isNil(item?.cfu)) {
        console.error('ERROR: cfu field must be not nil, since it comes from the DRAFT or from the OFFER')
    }
    return item?.cfu || 0;
}

// se item proviene dagli aggiornamenti il dato SUPERATO va preso dall'approvato
export function getSuperatoValueFromApprovedPlan(item:  CorsoPianoDiStudiInfoView & DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string, forTrasversali = false): boolean {
    const act = approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idCorsoInOffertaFormativa && a.idCorsoInOffertaFormativa === (item.idCorsoInOffertaFormativa ?? item.id) && !forTrasversali) ||
            (a.idAttivitaTrasversale && a.idAttivitaTrasversale === (item.idAttivitaTrasversale ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)))
    return act?.superato;
}

export function getNotaSuperamentoValueFromApprovedPlan(item:  CorsoPianoDiStudiInfoView & DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string, forTrasversali = false): string {
    const act = approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idCorsoInOffertaFormativa && a.idCorsoInOffertaFormativa === (item.idCorsoInOffertaFormativa ?? item.id) && !forTrasversali) ||
            (a.idAttivitaTrasversale && a.idAttivitaTrasversale === (item.idAttivitaTrasversale ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)));
    return act?.notaSuperamento;
}


export function getDataSuperamentoValueFromApprovedPlan(item:  CorsoPianoDiStudiInfoView & DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string, forTrasversali = false): string {
    const act = approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idCorsoInOffertaFormativa && a.idCorsoInOffertaFormativa === (item.idCorsoInOffertaFormativa ?? item.id) && !forTrasversali) ||
            (a.idAttivitaTrasversale && a.idAttivitaTrasversale === (item.idAttivitaTrasversale ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)))
    return act?.dataSuperamento;
}


export function getMotivazioneRifiutoValueFromApprovedPlan(item:  CorsoPianoDiStudiInfoView & DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string, forTrasversali = false): string {
    const act = approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idCorsoInOffertaFormativa && a.idCorsoInOffertaFormativa === (item.idCorsoInOffertaFormativa ?? item.id) && !forTrasversali) ||
            (a.idAttivitaTrasversale && a.idAttivitaTrasversale === (item.idAttivitaTrasversale ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)))
    return act?.motivazioneRifiuto;
}

export function getStatoAttivitaExtraFromApprovedPlan(item: CorsoPianoDiStudiInfoView & DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string): AttivitaExtraPianoDiStudiStatus {
    return approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idCorsoInOffertaFormativa && a.idCorsoInOffertaFormativa === (item.idCorsoInOffertaFormativa ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)))
        ?.statoAttivitaExtra;
}

export function getStatoAttivitaTrasversaleFromApprovedPlan(item: CorsoPianoDiStudiInfoView & DatiCorsoOffertaFormativa & AttivitaOffertaFormativaInfoView, approvedPlan?: PianoDiStudiInfoViewImpl, categoryId?: string): AttivitaTrasversaleStatus {
    const f = approvedPlan?.categorie
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId)?.corsi
        ?.find(a =>
            (a.idAttivitaTrasversale && a.idAttivitaTrasversale === (item.idAttivitaTrasversale ?? item.id)) ||
            (a.idAttivitaProposta && a.idAttivitaProposta === (item.idAttivitaProposta ?? item.id)) ||
            (a.idAttivitaPresunta && a.idAttivitaPresunta === (item.idAttivitaPresunta ?? item.id)))
    return f?.statoAttivitaTrasversale;
}

export function showCaricaAttestato(statoAttivitaExtra, element, currentRuolo?: AuthorityType) {
    return currentRuolo === AuthorityType.STUDENTE && (statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.INSERITA || statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.RIFIUTATA);

}

export function showActionColumnForExtra(statoAttivitaExtra, element, currentRuolo?: AuthorityType, isSupervisoreOrCosup?: boolean, isCreationPlan?: boolean) {
    return ((currentRuolo === AuthorityType.STUDENTE && statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.INSERITA) ||
        (statoAttivitaExtra !== AttivitaExtraPianoDiStudiStatus.INSERITA)) && !isCreationPlan
        && (showRifiutaApprovaAttivita(statoAttivitaExtra, element, isSupervisoreOrCosup)
        || (!(element?.idAttivitaPresunta && !element?.denominazione) && showCaricaAttestato(statoAttivitaExtra, element, currentRuolo))
        || showVisualizzaAttestato(statoAttivitaExtra, element, currentRuolo, isSupervisoreOrCosup)
        || showVisualizzaDelibera(statoAttivitaExtra, element, currentRuolo, isSupervisoreOrCosup));
}

export function showVisualizzaAttestato(statoAttivitaExtra, element, currentRuolo?: AuthorityType, isSupervisoreOrCoSupervisore?: boolean) {
    return element?.attestatoAttivitaExtra &&
        (currentRuolo === AuthorityType.ADMIN ||
            currentRuolo === AuthorityType.SUPERADMIN ||
            currentRuolo === AuthorityType.DOCENTE ||
            currentRuolo === AuthorityType.COORDINATORE ||
            currentRuolo === AuthorityType.STUDENTE ||
            isSupervisoreOrCoSupervisore
        );
}

export function showVisualizzaDelibera(statoAttivitaExtra, element, currentRuolo?: AuthorityType, isSupervisoreOrCoSupervisore?: boolean) {
    return element?.deliberaAttivitaExtra &&
        (currentRuolo === AuthorityType.ADMIN ||
            currentRuolo === AuthorityType.SUPERADMIN ||
            currentRuolo === AuthorityType.DOCENTE ||
            currentRuolo === AuthorityType.COORDINATORE ||
            currentRuolo === AuthorityType.STUDENTE ||
            (isSupervisoreOrCoSupervisore && !hideDelibera.includes(currentRuolo))
        );
}

export function showRifiutaApprovaAttivita(statoAttivitaExtra, element, isSupervisore?: boolean, isCreationPlan?: boolean) {
    return (statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.DAAPPROVARE && isSupervisore && !isCreationPlan);
}


/*export function buildConfigurationForCoursesPlan(coursesPlan: Array<any>,
                                                 annoInsegnamentoInput: AnnoRiferimentoValues,
                                                 showTipologiaModifica?: boolean,
                                                 idUserLogged?: string,
                                                 isDocenteCorso?: boolean,
                                                 isSupervisore?: boolean,
                                                 esamiFromPianoInfoView?: any[],
                                                 isCreationPlan?: boolean): GenericTableConfigurationModel {

    const myData: any[] =
        coursesPlan?.filter(corso => corso.annoInsegnamento === annoInsegnamentoInput)?.filter(element =>
            element.tipo === ElementoOffertaFormativaType.CORSO)?.map(item => ({
            ...item,
            cfuRatio: getCfuOttentuti(item, esamiFromPianoInfoView) + '/' + getCfuPrevisti(item, esamiFromPianoInfoView),
            superato: getSuperatoValue(item, esamiFromPianoInfoView),
            cfuPrevisti: getCfuPrevisti(item, esamiFromPianoInfoView),
            cfuOttenuti: getCfuOttentuti(item, esamiFromPianoInfoView),
            // unione di DocenteExtraPianoDiStudiInfoView[] e UsersView[]
            docentiTutti: unifyDocenti(item)
        }));
    if (!!showTipologiaModifica) {
        return {
            configuration: {
                data: myData,
                totalElements: myData?.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.course_title',
                        colonnaKey: 'denominazione',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'common.edit_type',
                        colonnaKey: 'tipoModifica',
                        tooltip: tipoModifica => 'study_plan_status.edit_types.' + tipoModifica.toLowerCase(),
                        flex: 15,
                        statusType: TipoModificaCorsoPianoDiStudi
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.final_check',
                        colonnaKey: 'verificaFinale',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'common.passed',
                        colonnaKey: 'superato',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.professor',
                        colonnaKey: 'docentiTutti',
                        flex: 35,
                    },
                    {
                        nomeColonna: 'common.additional_information',
                        colonnaKey: 'descrizione',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.additional_information',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: true,
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_study_plan'
            }
        };
    } else {
        return {
            configuration: {
                data: myData,
                totalElements: myData?.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.course_title',
                        colonnaKey: 'denominazione',
                        flex: 35,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.final_check',
                        colonnaKey: 'verificaFinale',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'common.passed',
                        colonnaKey: 'superato',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.ACTION,
                        nomeColonna: 'common.additional_information',
                        colonnaKey: 'descrizione',
                        flex: 10,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.additional_information',
                            color: 'accent'
                        }]
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.professor',
                        colonnaKey: 'docentiTutti',
                        flex: 45,
                    },
                    {
                        nomeColonna: 'user_list.action',
                        colonnaKey: 'superamentoCorso',
                        flex: 10,
                        hideColonna: (!isSupervisore && !isDocenteCorso) || isCreationPlan,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        show: (value, element) => showSuperamentoCorso(value, element, idUserLogged, isDocenteCorso, isSupervisore),
                        valueCheck: 'superato',
                        button: [{
                            nameIconButton: 'done',
                            click: TipoClickEnum.SUPERAMENTO_CORSO,
                            tooltip: 'common.superamento_corso',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: true,
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_study_plan'
            }
        };
    }
}*/


/*export function buildConfigurationForExtra(coursesPlan: Array<CorsoPianoDiStudiInfoViewImpl>,
                                           annoInsegnamentoInput: AnnoRiferimentoValues,
                                           showTipologiaModifica?: boolean,
                                           esamiFromPianoInfoView?: any[],
                                           currentRuolo?: AuthorityType,
                                           isSupervisoreOrCoSupervisore?: boolean,
                                           isCreationPlan?: boolean
)   : GenericTableConfigurationModel {
    const myData =
        coursesPlan?.filter(corso => corso.annoInsegnamento === annoInsegnamentoInput)?.filter(element =>
            element.tipo === ElementoOffertaFormativaType.ATTIVITAEXTRA)?.map(item => ({
            ...item,
            cfuRatio: getCfuOttentuti(item, esamiFromPianoInfoView) + '/' + getCfuPrevisti(item, esamiFromPianoInfoView),
            organizzatori: item?.organizzatori?.map(organizzatore => ({nome: organizzatore})),
            cfuPrevisti: getCfuPrevisti(item, esamiFromPianoInfoView),
            cfuOttenuti: getCfuOttentuti(item, esamiFromPianoInfoView),
            superato: getSuperatoValue(item, esamiFromPianoInfoView),
            docentiTutti: unifyDocenti(item),
            statoAttivitaExtra: item.statoAttivitaExtra || esamiFromPianoInfoView?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso?.idCorsoInOffertaFormativa === item?.idCorsoInOffertaFormativa)?.statoAttivitaExtra,
            presenzaAttestato: !!item?.presenzaAttestato || !!esamiFromPianoInfoView?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idCorsoInOffertaFormativa === item.idCorsoInOffertaFormativa)?.presenzaAttestato,
            presenzaDelibera: !!item?.presenzaDelibera || !!esamiFromPianoInfoView?.find(corso =>
                corso.annoInsegnamento === annoInsegnamentoInput
                && corso.idCorsoInOffertaFormativa === item.idCorsoInOffertaFormativa)?.presenzaDelibera,
        }));
    if (!!showTipologiaModifica) {
        return {
            configuration: {
                data: myData,
                totalElements: myData?.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.course_title',
                        colonnaKey: 'denominazione',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'common.status',
                        colonnaKey: 'statoAttivitaExtra',
                        flex: 15,
                        statusType: AttivitaExtraPianoDiStudiStatus
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'common.edit_type',
                        colonnaKey: 'tipoModifica',
                        tooltip: tipoModifica => 'study_plan_status.edit_types.' + tipoModifica.toLowerCase(),
                        flex: 15,
                        statusType: TipoModificaCorsoPianoDiStudi
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'student.passed',
                        colonnaKey: 'superato',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.final_check',
                        colonnaKey: 'verificaFinale',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                   /!* {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'study_plan_status.presence_certificate',
                        colonnaKey: 'presenzaAttestato',
                        flex: 12,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'study_plan_status.file_approve_presence',
                        colonnaKey: 'presenzaDelibera',
                        flex: 12,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },*!/
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.organizer',
                        colonnaKey: 'organizzatori',
                        isChipDisabled: true,
                        flex: 40,
                    },
                    {
                        nomeColonna: 'common.additional_information',
                        colonnaKey: 'descrizione',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.additional_information',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: true,
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data'
            }
        };
    } else {
        return {
            configuration: {
                data: myData,
                totalElements: myData?.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.course_title',
                        colonnaKey: 'denominazione',
                        flex: 35,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'common.status',
                        colonnaKey: 'statoAttivitaExtra',
                        flex: 15,
                        statusType: AttivitaExtraPianoDiStudiStatus
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.final_check',
                        colonnaKey: 'verificaFinale',
                        flex: 15,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.organizer',
                        colonnaKey: 'organizzatori',
                        isChipDisabled: true,
                        flex: 25,
                    },
                    {
                        nomeColonna: 'common.additional_information',
                        colonnaKey: 'descrizione',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.additional_information',
                            color: 'accent'
                        }]
                    },
                    {
                        nomeColonna: 'user_list.action',
                        colonnaKey: 'azioni',
                        flex: 10,
                        valueCheck: 'statoAttivitaExtra',
                        show: (value, element) => showActionColumn(value, element, currentRuolo, isSupervisoreOrCoSupervisore, isCreationPlan),
                        hideColonna: isCreationPlan, // questo valore è true quando si sta lavorando alla creazione del piano in bozza
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        button: [
                            {
                                nameIconButton: 'upload',
                                valueShow: ['statoAttivitaExtra'],
                                show: (value, element) => showCaricaAttestato(value, element, currentRuolo),
                                click: TipoClickEnum.CARICA_ATTESTATO,
                                tooltip: 'common.inserisci_attestato',
                                color: 'accent'
                            },
                            {
                                nameIconButton: 'visibility',
                                valueShow: ['statoAttivitaExtra'],
                                show: (value, element) => showVisualizzaAttestato(value, element, currentRuolo, isSupervisoreOrCoSupervisore),
                                click: TipoClickEnum.SHOW_ATTESTATO_ATTIVITA_EXTRA,
                                tooltip: 'common.visualizza_attestato',
                                color: 'accent'
                            },
                            {
                                nameIconButton: 'visibility',
                                valueShow: ['statoAttivitaExtra'],
                                show: (value, element) => showVisualizzaDelibera(value, element, currentRuolo, isSupervisoreOrCoSupervisore),
                                click: TipoClickEnum.SHOW_DELIBERA_ATTIVITA_EXTRA,
                                tooltip: 'study_plan_status.visualizza_documento_supervisore',
                                color: 'accent'
                            },
                            {
                                nameIconButton: 'done',
                                click: TipoClickEnum.APPROVA_ATTIVITA_EXTRA,
                                valueShow: ['statoAttivitaExtra'],
                                show: (value, element) => showRifiutaApprovaAttivita(value, element, isSupervisoreOrCoSupervisore, isCreationPlan),
                                tooltip: 'study_plan_status.confirm_passed_activity',
                                color: 'primary'
                            },
                            {
                                nameIconButton: 'close',
                                click: TipoClickEnum.RIFIUTA_ATTIVITA_EXTRA,
                                valueShow: ['statoAttivitaExtra'],
                                show: (value, element) => showRifiutaApprovaAttivita(value, element, isSupervisoreOrCoSupervisore, isCreationPlan),
                                tooltip: 'study_plan_status.rejects_activities',
                                color: 'primary'
                            },
                        ]
                    },

                ],
                hidePaginator: true,
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data'
            }
        };
    }
}*/

export function unifyDocentiAndExtra(corso: UIActivityI) {
    return [...(corso.docenti || []), ...(corso.docentiExtra || [])];
}

export function getFieldCfuByYear(fieldType: 'cfuOttenuti' | 'cfuPrevisti', year: AnnoRiferimentoValues, pianoDiStudi: PianoDiStudiInfoView): number {
    if (fieldType === 'cfuOttenuti') {
        switch (year) {
            case AnnoRiferimentoValues.PRIMO:
                return pianoDiStudi.cfuOttenutiPrimoAnno;
            case AnnoRiferimentoValues.SECONDO:
                return pianoDiStudi.cfuOttenutiSecondoAnno;
            case AnnoRiferimentoValues.TERZO:
                return pianoDiStudi.cfuOttenutiTerzoAnno;
            default:
                return null;
        }
    }
    if (fieldType === 'cfuPrevisti') {
        switch (year) {
            case AnnoRiferimentoValues.PRIMO:
                return pianoDiStudi.cfuPrevistiPrimoAnno;
            case AnnoRiferimentoValues.SECONDO:
                return pianoDiStudi.cfuPrevistiSecondoAnno;
            case AnnoRiferimentoValues.TERZO:
                return pianoDiStudi.cfuPrevistiTerzoAnno;
            default:
                return null;
        }
    }
}

export function visualizzaUploadAttestatoForAttivitaStudente(element: any,
                                                             currentRuolo: AuthorityType): boolean {
    return currentRuolo === AuthorityType.STUDENTE && !!element.presenzaAttestato &&
        (element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.INSERITA ||
            element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.RIFIUTATA
            || !element.attestatoAttivitaExtra);
}

export function visualizzaUploadDeliberaForAttivitaStudente(element: any,
                                                            currentSottoruolo: AuthorityType,
                                                            student: StudenteCicloInfoView,
                                                            currentUserId: string,): boolean {
    return (currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId)))  && !!element.presenzaDelibera &&
        (element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.DAAPPROVARE
            || element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.ATTESTATOPRESENTATO
        )
        && !element.superato;
}

export function visualizzaRifiutoForAttivitaStudente(element: any,
                                                     currentSottoruolo: AuthorityType,
                                                     student: StudenteCicloInfoView,
                                                     currentUserId: string,): boolean {
    return (currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId)))  && !!element.presenzaAttestato &&
        (element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.DAAPPROVARE
            || element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.ATTESTATOPRESENTATO

        )
        && !element.superato;
}

export function visualizzaConfermaSuperamentoForAttivitaStudente(element: any,
                                                                 currentSottoruolo: AuthorityType,
                                                                 student: StudenteCicloInfoView,
                                                                 currentUserId: string,): boolean {
    return (currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId)))  && !element.presenzaDelibera &&
        (element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.DAAPPROVARE
            || element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.ATTESTATOPRESENTATO
        )
        && !element.superato;
}

export function visualizzaBottoniForm(element: CorsoAggiornamentoPianoDiStudiInfoView): boolean {
    return element.tipoModifica === TipoModificaCorsoPianoDiStudi.AGGIUNTA;
}

export function visualizzaStatoAttivitaExtraCustom(element: any): boolean {
    return element.presenzaDelibera || element.presenzaAttestato;
}

export function visualizzaStatoAttivitaTrasversale(element: any): boolean {
    return true;
}

export function visualizzaActionMenuAtttivitaExtra(element: any,
                                                   currentRuolo: AuthorityType,
                                                   currentSottoruolo: AuthorityType,
                                                   student: StudenteCicloInfoView,
                                                   currentUserId: string,): boolean {
    const condition: boolean = (
        visualizzaUploadDeliberaForAttivitaStudente(element, currentSottoruolo, student, currentUserId) ||
        visualizzaRifiutoForAttivitaStudente(element, currentSottoruolo, student, currentUserId) ||
        visualizzaConfermaSuperamentoForAttivitaStudente(element, currentSottoruolo, student, currentUserId) ||
        visualizzaDownloadDeliberaForAttivitaStudente(element, currentRuolo) ||
        visualizzaDownloadAttestatoForAttivitaStudente(element) ||
        visualizzaUploadAttestatoForAttivitaStudente(element, currentRuolo));
    return checkRuoliExtraCustom(currentRuolo, currentSottoruolo) && condition;
}

export function checkRuoliExtraCustom(currentRuolo: AuthorityType, currentSottoruolo?: AuthorityType): boolean {
    return currentRuolo === AuthorityType.ADMIN ||
        currentRuolo === AuthorityType.SUPERADMIN ||
        currentRuolo === AuthorityType.STUDENTE ||
        currentSottoruolo === AuthorityType.COORDINATORE ||
        currentSottoruolo === AuthorityType.SUPERVISORE ||
        currentSottoruolo === AuthorityType.COSUPERVISORE;
}

export function checkRuoliTrasversali(currentRuolo: AuthorityType, currentSottoruolo?: AuthorityType): boolean {
    return currentRuolo === AuthorityType.ADMIN ||
        currentRuolo === AuthorityType.SUPERADMIN ||
        currentRuolo === AuthorityType.STUDENTE ||
        currentSottoruolo === AuthorityType.COORDINATORE ||
        currentSottoruolo === AuthorityType.SUPERVISORE ||
        currentSottoruolo === AuthorityType.COSUPERVISORE;
}



export function visualizzaDownloadDeliberaForAttivitaStudente(element: any, currentRuolo: AuthorityType): boolean {
    return !!element.deliberaAttivitaExtra && currentRuolo !== AuthorityType.STUDENTE;
}

export function visualizzaDownloadAttestatoForAttivitaStudente(element: any): boolean {
    return !!element.attestatoAttivitaExtra;
}

export function showModificaAttivitaStudenteForBozza(value: any, element: any) {
    return !element.superato && (element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.DAAPPROVARE
        || element.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.INSERITA ||
        !element.statoAttivitaExtra);
}

export function showRemoveAttivitaTrasversaleForBozza(value: any, element: any) {
    return element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADELIBERACOORDINATORE ||
        (!element?.presenzaDeliberaCoordinatore
            && element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTODOTTORANDO) ||
        (!element?.presenzaDeliberaCoordinatore && !element?.presenzaDocumentoDottorando
            && element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE) ||
        (!element?.presenzaDeliberaCoordinatore && !element?.presenzaDocumentoDottorando && !element?.presenzaDocumentoSupervisore
            && element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESASUPERAMENTO) ||
        !element?.statoAttivitaTrasversale;
}


export function visualizzaUploadDeliberaForAttivitaTrasversale(element: any, currentSottoruolo: AuthorityType) {
    return element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADELIBERACOORDINATORE &&
        currentSottoruolo === AuthorityType.COORDINATORE;
}

export function visualizzaUploadDottorandoForAttivitaTrasversale(element: any, currentRuolo: AuthorityType) {
    return (element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTODOTTORANDO ||
        element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.DOCUMENTODOTTORANDORIFIUTATO) &&
        currentRuolo === AuthorityType.STUDENTE;
}

export function visualizzaRifiutoDocumentoDottorandoForAttivitaTrasversale(element: any,
                                                                           currentSottoruolo: AuthorityType,
                                                                           student: StudenteCicloInfoView,
                                                                           currentUserId: string,) {
    return element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.DOCUMENTODOTTORANDOCARICATO &&
        ((currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId))) );
}

export function visualizzaApprovaDocumentoDottorandoForAttivitaTrasversale(element: any,
                                                                           currentSottoruolo: AuthorityType,
                                                                           student: StudenteCicloInfoView,
                                                                           currentUserId: string,) {
    return element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.DOCUMENTODOTTORANDOCARICATO &&
        ((currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId))) );
}

export function visualizzaApproveRequestForAttivitaTrasversale(element: any, currentRuolo: AuthorityType) {
    return (element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESASUPERAMENTO ||
            element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE) &&
        currentRuolo === AuthorityType.STUDENTE;
}

export function visualizzaRegistraSuperamentoForAttivitaTrasversale(element: any,
                                                                    currentSottoruolo: AuthorityType,
                                                                    student: StudenteCicloInfoView,
                                                                    currentUserId: string,) {
    return (element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESASUPERAMENTO ||
        element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.RICHIESTASUPERAMENTO) &&
        ((currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId))) );
}

export function visualizzaRegistraSuperamentoConDocumentoForAttivitaTrasversale(element: any,
                                                                                currentSottoruolo: AuthorityType,
                                                                                student: StudenteCicloInfoView,
                                                                                currentUserId: string,) {
    return (element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.RICHIESTASUPERAMENTOCONDOCUMENTOSUPERVISORE ||
            element?.statoAttivitaTrasversale === AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE) &&
        ((currentSottoruolo === AuthorityType.SUPERVISORE || (currentSottoruolo === AuthorityType.COSUPERVISORE && getCosupPermessoApprovazione(student, currentUserId))) );
}

export function visualizzaDownloadDeliberaForAttivitaTrasversale(element: any, currentRuolo: AuthorityType) {
    return !!element?.deliberaCoordinatoreAttivitaTrasversale && !hideDelibera.includes(currentRuolo);
}

export function visualizzaDownloadDocumentoDottorandoForAttivitaTrasversale(element: any) {
    return !!element?.documentoDottorandoAttivitaTrasversale;
}

export function visualizzaDownloadDocumentoSupervisoreForAttivitaTrasversale(element: any) {
    return !!element?.documentoSupervisoreAttivitaTrasversale;
}

export function visualizzaActionMenuAttivitaTrasversali(element: any,
                                                        currentRuolo: AuthorityType,
                                                        currentSottoruolo: AuthorityType,
                                                        student: StudenteCicloInfoView,
                                                        currentUserId: string,): boolean {
    const condition: boolean = (
        (!(element?.idAttivitaPresunta && !element?.denominazione) && visualizzaUploadDeliberaForAttivitaTrasversale(element, currentSottoruolo)) ||
        (!(element?.idAttivitaPresunta && !element?.denominazione) && visualizzaUploadDottorandoForAttivitaTrasversale(element, currentRuolo)) ||
        visualizzaRifiutoDocumentoDottorandoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId) ||
        visualizzaApprovaDocumentoDottorandoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId) ||
        (!(element?.idAttivitaPresunta && !element?.denominazione) && visualizzaApproveRequestForAttivitaTrasversale(element, currentRuolo)) ||
        (!(element?.idAttivitaPresunta && !element?.denominazione) && visualizzaRegistraSuperamentoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId)) ||
        (!(element?.idAttivitaPresunta && !element?.denominazione) && visualizzaRegistraSuperamentoConDocumentoForAttivitaTrasversale(element, currentSottoruolo, student, currentUserId)) ||
        visualizzaDownloadDeliberaForAttivitaTrasversale(element, currentRuolo) ||
        visualizzaDownloadDocumentoDottorandoForAttivitaTrasversale(element) ||
        visualizzaDownloadDocumentoSupervisoreForAttivitaTrasversale(element));
    return checkRuoliTrasversali(currentRuolo, currentSottoruolo) && condition;
}


export function isCorsoArchivedRemovable(activity: CorsoAggiornamentoPianoDiStudiInfoView, corsiPianoApprovato: CorsoPianoDiStudiInfoView[]): boolean {
    // un'attività didattica è archiviata e da rimuovere se non era inclusa nell'ultimo piano approvato
    return activity?.datiCorsoOffertaFormativa?.isArchiviato && !corsiPianoApprovato.find(activityInApproved =>
        activityInApproved.idCorsoInOffertaFormativa === activity.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa);
}

export function isExtraArchivedRemovable(activity: CorsoAggiornamentoPianoDiStudiInfoView, corsiPianoApprovato: CorsoPianoDiStudiInfoView[]): boolean {
    // un'attività extra è archiviata e da rimuovere se non era inclusa nell'ultimo piano approvato
    return activity?.datiCorsoOffertaFormativa?.isArchiviato && !corsiPianoApprovato.find(activityInApproved =>
        activityInApproved.idCorsoInOffertaFormativa === activity.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa);
}


export function isTrasversaleArchivedRemovable(activity: CorsoAggiornamentoPianoDiStudiInfoView, corsiPianoApprovato: CorsoPianoDiStudiInfoView[]): boolean {
    // un'attività trasversale è archiviata e da rimuovere se non era inclusa nell'ultimo piano approvato
    return activity?.datiCorsoOffertaFormativa?.isArchiviato && !corsiPianoApprovato.find(activityInApproved =>
        activityInApproved.idAttivitaTrasversale === activity.datiCorsoOffertaFormativa?.idAttivitaTrasversale);
}



export function isCorsoArchivedRemovableForTable(activity): boolean {
    return activity.isArchiviato && !activity.superato;
}

export function isExtraArchivedRemovableForTable(activity): boolean {
    return activity.isArchiviato && !cannotRemoveExtraFromPlan(activity);
}

// not used
export function isCustomArchivedRemovableForTable(activity): boolean {
    return activity.isArchiviato;
}

export function isTrasversaleArchivedRemovableForTable(activity): boolean {
    return activity.isArchiviato && !cannotRemoveTrasversalFromPlan(activity);
}

export function cannotRemoveTrasversalFromPlan(activity: AttivitaOffertaFormativaInfoView & {statoAttivitaTrasversale?: AttivitaTrasversaleStatus}): boolean{
    if(activity?.presenzaDeliberaCoordinatore){
        return activity.statoAttivitaTrasversale && activity.statoAttivitaTrasversale !== AttivitaTrasversaleStatus.ATTESADELIBERACOORDINATORE;
    } else if(activity?.presenzaDocumentoDottorando){
        return activity.statoAttivitaTrasversale && activity.statoAttivitaTrasversale !== AttivitaTrasversaleStatus.ATTESADOCUMENTODOTTORANDO;
    } else if(activity?.presenzaDocumentoSupervisore){
        return activity.statoAttivitaTrasversale && activity.statoAttivitaTrasversale !== AttivitaTrasversaleStatus.ATTESADOCUMENTOSUPERVISORE;
    } else {
        return activity?.statoAttivitaTrasversale && activity?.statoAttivitaTrasversale !== AttivitaTrasversaleStatus.ATTESASUPERAMENTO;
    }
}

export function cannotRemoveExtraFromPlan(activity: AttivitaOffertaFormativaInfoView & {statoAttivitaExtra?: AttivitaExtraPianoDiStudiStatus}): boolean{
    const inInitialState = activity?.statoAttivitaExtra === AttivitaExtraPianoDiStudiStatus.INSERITA;
    return activity?.statoAttivitaExtra && !inInitialState;
}
