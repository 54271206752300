import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, finalize, take, takeUntil} from 'rxjs';
import {GenericTableConfigurationModel, TableData, TipoColonnaEnum} from '../table/model/generic-table-model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogTableMultiSelectConfiguration} from '../dialog-table-multi-select/dialog-table-multi-select.model';

import {
    AddDeliberaDTO,
    AuthorityType, BackOfficeUserView,
    DeliberaInfoView,
    DelibereService, PageBackOfficeUserView,
    PageDeliberaInfoViewImpl,
    UserFilterForm,
    UsersService,
    UserView,
    UserViewImpl
} from '../../../../api-clients/generated/services';
import {AbstractDefaultComponent} from '../../abstracts/abstract-default-component/abstract-default-component';
import {FuseConfirmationService} from '../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {Router} from '@angular/router';
import {PageEvent} from '@angular/material/paginator';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LogoutService} from "../../service/logout.service";
import {GenericComponentDialogConfig} from "../../../layout/common/generic-components/generic-components.interface";
import {get, orderBy} from "lodash";
import {TypeDialogFormEnum} from "../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {filter, switchMap} from "rxjs/operators";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {AppInitService} from "../../service/app-init.service";
import {clone, cloneDeep} from "lodash-es";
import {GenericTableComponent} from "../table/generic-table/generic-table.component";
import * as moment from "moment";
import {buildFormConfigForAddNewDelibera} from "../../../modules/landing/gestione-documenti/gestione-documenti-utils";
import {toTitleCase} from "../../utils/utils";
import RuoloEnum = UserFilterForm.RuoloEnum;
import {FuseMediaWatcherService} from "../../../../@fuse/services/media-watcher";


@Component({
  selector: 'app-dialog-select-docenti',
  templateUrl: './dialog-select-docenti.component.html',
  styleUrls: ['./dialog-select-docenti.component.scss']
})
export class DialogSelectDocentiComponent extends AbstractDefaultComponent implements OnInit {


    loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    tableData: TableData;

    alert = {type: 'info', message: ''};
    listaDocentiPagedInfo: PageBackOfficeUserView;
    isGetDocentiError: boolean;
    showTable: boolean;
    loading: boolean;
    showSpinner: boolean;
    listaRuoli: RuoloEnum[]= [];
    form: FormGroup;
    docentiExtra = [];
    hasCustomChanged: boolean;
    @ViewChild(GenericTableComponent) private genericTableComponent: GenericTableComponent;
    loadingDelibere: boolean;
    delibere: DeliberaInfoView[];
    outputFormatData = 'DD/MM/YYYY';
    haveCheckBoxesChanged: boolean;
    permissionColumnFormGroup: FormGroup;
    isScreenSmall: boolean;


    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogTableMultiSelectConfiguration,
                public dialogRef: MatDialogRef<DialogSelectDocentiComponent>,
                private usersService: UsersService,
                private fuseConfirmationService: FuseConfirmationService,
                private _translocoService: TranslocoService,
                private router: Router,
                private formBuilder: FormBuilder,
                private logOutService: LogoutService,
                private dialog: MatDialog,
                private _fuseMediaWatcherService: FuseMediaWatcherService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private changeDetectorRef: ChangeDetectorRef
                ) {

        super();

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.form = this.formBuilder.group({
            filtroRuoloControl: [data?.roleDataTable ? data?.roleDataTable : RuoloEnum.DOCENTE],
            filtroNominativoControl: [],
            deliberaCollegio: [undefined, (data?.showDeliberaField && data?.currentRuolo !== AuthorityType.SUPERADMIN) ? Validators.required : undefined]
        });

        //if(this.data?.showSelectToSetDataTableByRole){
        //    this.listaRuoli = this.data?.rolesToFetch || [RuoloEnum?.DOCENTE, RuoloEnum?.PERSONALEPTA];
        //}

        this.listaRuoli = this.data?.rolesToFetch || [RuoloEnum?.DOCENTE, RuoloEnum?.PERSONALEPTA];

        if (this.data?.showDeliberaField) {
            // get delibere
            this.getDelibereRequest();
        }

        if (this.data.showCosupervisorApprovePermissionColumn) {
            this.permissionColumnFormGroup = new FormGroup({
                array: new FormArray((this.data.righeSelezionate || [])
                    .map(d => new FormGroup({
                        key: new FormControl(d.key),
                        checked: new FormControl(d.data.approvePermission)
                    })))
            });
            // subscribe to checkbox changes
            this.permissionColumnFormGroup.valueChanges.subscribe((value) => this.haveCheckBoxesChanged = true);
        }


    }

    ngOnInit(): void {
        this.getPagedDataSource(true);
        this.docentiExtra = this.data.righeSelezionateCustom;
    }

    closeDialog(): void{
        this.dialogRef.close();
    }

    get filtroRuoloControl(): FormControl{
        return this.form.get('filtroRuoloControl') as FormControl;
    }

    get filtroNomivativoControl(): FormControl{
        return this.form.get('filtroNominativoControl') as FormControl;
    }


    onDataTableChanged(tableData: TableData): void {
        this.tableData = tableData;
        console.log('on data table changed', this.tableData, this.data.righeSelezionate)
    }

    save(): void {
        const confirmInvalid = (this.data?.showDeliberaField && this.form.get('deliberaCollegio')?.invalid)
        || (!this.tableData && !this.hasCustomChanged && !this.haveCheckBoxesChanged)
        || (this.tableData?.removedRows.length === 0 && this.tableData?.addedRows.length === 0 && !this.hasCustomChanged && !this.haveCheckBoxesChanged)
        || (!this.data?.isSingleChoiceSelection && this.data?.isSingleChoiceSelection && this.tableData?.selectedRows.length === 0 && !this.hasCustomChanged && !this.haveCheckBoxesChanged)
        if (confirmInvalid){
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.data.actions.confirm.function(this.tableData, this.dialogRef, this.loading$, this.docentiExtra,
                this.form, cloneDeep(this.data?.table?.configuration?.configuration?.checkboxColumnFormGroup?.value?.array));
        }
    }

    getPagedDataSource(firstTime: boolean, nominativo: string= undefined, page: number = 0, size: number = 10): void {
        if (firstTime) {
            this.loading = true;
        } else {
            this.showSpinner= true;
        }
        this.usersService.getBackofficeUsersForm(
            undefined,
            undefined,
            !!nominativo? nominativo : undefined,
            undefined,
            undefined,
            this.listaRuoli,
            undefined,
            undefined,
            page,
            size).pipe(
            takeUntil(this.destroy$),
            finalize(()=>{
                this.loading= false;
                this.showSpinner= false;
            })
        ).subscribe({
            next: (value: PageBackOfficeUserView) => {
                this.listaDocentiPagedInfo = value;
                if(this.data && this.data?.table){
                    this.data.table.configuration = this.mapDocentiToTableConfig();
                    this.showTable= true;
                }
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this._translocoService,
                    () => this.logOutService.goToHome(),
                    () => this.getPagedDataSource(false, this.filtroNomivativoControl?.value?.trim()),
                    undefined,
                    err?.error?.message);
                this.isGetDocentiError = true;
                this.showTable= false;
                        }
        });
    }

    mapDocentiToTableConfig(docentiExtra?: any): GenericTableConfigurationModel{
        const docentiTableData = this.listaDocentiPagedInfo?.content.map((docente: BackOfficeUserView) => ({
            nomeCognome: ((docente?.cognome ? docente?.cognome : '') + ' '
                + (docente?.nome ? docente?.nome : ''))?.toUpperCase() + this.getAsterisk(docente),
            //ssd: docente?.ssd || '',
            email: docente?.mail?.toLowerCase() || '',
            codiceFiscale: docente?.codiceFiscale?.toUpperCase(),
            id: docente?.codiceFiscale,
            nome: (docente?.nome ? docente?.nome : ''),
            cognome: (docente?.cognome ? docente?.cognome : ''),
            ruoli: docente?.ruoli?.map(r => r.ruolo)?.filter(r => this.data?.rolesToFetch?.includes(r as RuoloEnum))?.join(', '),
            approvePermission: this.data.righeSelezionate?.find(r => r.key === docente.codiceFiscale)?.data?.approvePermission,
        }));
        if (docentiExtra) {
            docentiTableData.push(...docentiExtra);
        }

        return {
            configuration: {
                data: docentiTableData,
                singleChoiceSelection: this.data?.isSingleChoiceSelection,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'nomeCognome',
                    selectedColumnKeyPrimaryKey: 'codiceFiscale',
                },
                cssMatTable: {
                    'max-height': this.isScreenSmall ? 'unset' : '30vh',
                },
                sticky: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: this.listaDocentiPagedInfo.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data_members',
                hidePaginator: false,
                disabledCssClass: 'generic-table-disabled-row',
                checkboxColumnFormGroup: this.permissionColumnFormGroup,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: this.getHeaderByRole(this.filtroRuoloControl?.value),
                        colonnaKey: 'nomeCognome',
                        flex: 25,
                        uppercase: false
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.fiscal_code',
                        colonnaKey: 'codiceFiscale',
                        flex: 25,
                        uppercase: false,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.email',
                        colonnaKey: 'email',
                        flex: 25,
                        uppercase: false,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.ssd',
                        colonnaKey: 'ssd',
                        flex: 15,
                        uppercase: false,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.roles',
                        colonnaKey: 'ruoli',
                        flex: 10,
                        uppercase: false,
                    },
                    {
                        tipo: TipoColonnaEnum.CHECKBOX,
                        nomeColonna: 'student.involved_into_approval_flows',
                        colonnaKey: 'approvePermission',
                        flex: 10,
                        hideColonna: !this.data.showCosupervisorApprovePermissionColumn,
                        showOnlyWhenSelected: true,
                        formControl: {
                            type: TypeDialogFormEnum.BOOLEAN,
                            show: true,
                            name: 'approvePermission',

                        }
                    }
                ],
            }
        };

    }


    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const size = $event.pageSize;
        const page = $event.pageIndex;
        this.getPagedDataSource( false, this.filtroNomivativoControl?.value?.trim() ,page, size);
    }

    applyFilters(): void {
        this.getPagedDataSource( false, !!this.filtroNomivativoControl?.value? this.filtroNomivativoControl?.value?.trim() : this.filtroNomivativoControl?.value ,0, 10);
    }

    svuotaFiltroNominativo(): void{
        this.filtroNomivativoControl.reset();
    }

    openAddExternDialog() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: GenericComponentDialogConfig = {
            title: 'training_offer.add_extern',
            icon: {
                show: true,
                name: 'add',
                color: 'primary'
            },
            readOnly: false,
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.inviteExtern(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
/*            alert: {
                type: 'info',
                message: get(translation, 'training_offer.alert_invite_esterno', null),
            },*/
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'nome',
                    required: true,
                    transloco: 'common.name',
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: true,
                    name: 'cognome',
                    required: true,
                    transloco: 'common.surname',
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: true,
                    name: 'email',
                    required: true,
                    transloco: 'user_list.email',
                    type: TypeDialogFormEnum.TEXT,
                    regex: {
                        pattern: '^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$',
                        text: get(translation, 'form.email', null)
                    }
                },
            ],
            valueForm: {
                nome: undefined,
                cognome: undefined,
                email: undefined
            },
        };
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: data.dismissible,
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe((addedUser: UserView) => {
            if (!!addedUser && addedUser !== 'cancelled') {
                this.getPagedDataSource(false);
                // remove previous selected
                if(this.data?.isSingleChoiceSelection && this.genericTableComponent?.righeSelezionate?.length > 0) {
                    this.genericTableComponent?.toggleSelectedRowData(this.genericTableComponent?.righeSelezionate[0]?.data);
                }
                // select the new value
                this.genericTableComponent?.toggleSelectedRowData({...addedUser, nomeCognome: addedUser.cognome + ' ' + addedUser.nome, id: addedUser.codiceFiscale});
            }
        });
    }

    private inviteExtern(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const formValue = form.getRawValue();
        form.disable();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.usersService.invitaUtenteEsternoForm(
                formValue.nome,
                formValue.cognome,
                formValue.email,
            )),
            takeUntil(this.destroy$),
            finalize(() => {
                form.enable();
            })
        ).subscribe({
            next: (user: UserView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.op_saved', null),
                    type: SnackbarTypes.Success,
                });
                dialogRef.close(user);
            },
            error: (err) => {
                console.log(err)
                if(err?.status == 400 && err?.error.code == 1006) {
                    this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                        () => {},
                        () => this.inviteExtern(form, dialogRef),
                        'dialog.cancel',
                        err?.error?.message);
                } else {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'mobility.error_during_op', null),
                        error: err, type: SnackbarTypes.Error,
                    });
                }
            }
        });
    }

    private getHeaderByRole(value: RuoloEnum) {
        switch(value) {
            case RuoloEnum.DOCENTE:
                return 'common.professor';
            case RuoloEnum.PERSONALEPTA:
                return 'cycle_doctorate.pta';
            case RuoloEnum.ESTERNO:
                return 'common.esterno';
            default:
                return 'common.professor';
        }
    }

    openAddTeacherManually() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: GenericComponentDialogConfig = {
            title: 'training_offer.add_teacher_manually',
            icon: {
                show: true,
                name: 'add',
                color: 'primary'
            },
            readOnly: false,
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.addTeacherManually(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'nome',
                    required: true,
                    transloco: 'common.name',
                    type: TypeDialogFormEnum.TEXT
                },
                {
                    show: true,
                    name: 'cognome',
                    required: true,
                    transloco: 'common.surname',
                    type: TypeDialogFormEnum.TEXT
                },
            ],
            valueForm: {
                nome: undefined,
                cognome: undefined,
            },
        };
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: data.dismissible,
            disableClose: false,
        });
    }


    addTeacherManually(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const nomeCognome = (form.getRawValue()?.cognome ? form.getRawValue()?.cognome : '') + ' '
            + (form.getRawValue()?.nome ? form.getRawValue()?.nome : '');
        const docenteExtra = {...form.getRawValue(), nomeCognome: nomeCognome}
        this.docentiExtra.push({key: docenteExtra.id, data: docenteExtra});
        this.docentiExtra = clone(this.docentiExtra);
        this.hasCustomChanged = true;
        dialogRef.close();
        // if custom changed, update also the tabledata
        this.tableData = {
            selectedRows: this.genericTableComponent.sortRigheSelezionate(this.genericTableComponent.righeSelezionate),
            removedRows: this.genericTableComponent.righeRimosse,
            addedRows: this.genericTableComponent.righeAggiunte
        };
    }

    openAddExternFromExtraDialog(docenteExtra: any) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const data: GenericComponentDialogConfig = {
            title: 'training_offer.add_extern',
            message: get(translation, 'training_offer.add_extern_from_extra_add_course_message', null),
            icon: {
                show: true,
                name: 'add',
                color: 'primary'
            },
            readOnly: false,
            actions: {
                confirm: {
                    show: true,
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.inviteExtern(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: get(translation, 'dialog.cancel', null),
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'nome',
                    required: true,
                    transloco: 'common.name',
                    type: TypeDialogFormEnum.TEXT,
                    disable: true
                },
                {
                    show: true,
                    name: 'cognome',
                    required: true,
                    transloco: 'common.surname',
                    type: TypeDialogFormEnum.TEXT,
                    disable: true
                },
                {
                    show: true,
                    name: 'email',
                    required: true,
                    transloco: 'user_list.email',
                    type: TypeDialogFormEnum.TEXT,
                    regex: {
                        pattern: '^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$',
                        text: get(translation, 'form.email', null)
                    }
                },
            ],
            valueForm: {
                nome: docenteExtra.nome,
                cognome: docenteExtra.cognome,
                email: undefined
            },
        };
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: data.dismissible,
            disableClose: false,
        });
        dialogRef.afterClosed().subscribe((addedUser: UserView) => {
            if (!!addedUser && addedUser !== 'cancelled') {
                // remove docente extra
                console.log('removing extra',  this.docentiExtra.find(d => d.data.id === docenteExtra.id))
                const docenteToRemoveIndex = this.docentiExtra.findIndex(d => d.data.id === docenteExtra.id);
                this.docentiExtra.splice(docenteToRemoveIndex, 1);
                // redo the docenti request
                this.getPagedDataSource(false);
                // select the new docente
                const newRigheSelezionate = cloneDeep(this.data.righeSelezionate);
                newRigheSelezionate.push({
                    'key': addedUser.codiceFiscale,
                    'data': {...addedUser, nomeCognome: addedUser.cognome + ' ' + addedUser.nome}
                });
                this.data.righeSelezionate = newRigheSelezionate;
                console.log('righe sel', this.data.righeSelezionate)
                this.hasCustomChanged = true;
            }
        });
    }

    customSelectedRowChange(hasChanged: boolean) {
        this.hasCustomChanged = hasChanged;
        if(hasChanged){
            // if custom changed, update also the tabledata
            this.tableData = {
                selectedRows: this.genericTableComponent.sortRigheSelezionate(this.genericTableComponent.righeSelezionate),
                removedRows: this.genericTableComponent.righeRimosse,
                addedRows: this.genericTableComponent.righeAggiunte
            };
        }
    }

    private getDelibereRequest() {
        this.loadingDelibere = true;
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.loadingDelibere = false;
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this._translocoService,
                    () => {},
                    () => this.getDelibereRequest(),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    openAddNewDeliberaDialog() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                fileNotaTrasmissione: undefined,
                nota: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera,
            note: rawValue.nota
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this._translocoService,
                    () => {},
                    () => this.addNewDeliberaRequest(form, rawValue, dialogRef),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    private getAsterisk(docente: BackOfficeUserView) {
        if(this.data.showRigheSolaLetturaBecauseAlreadyInCollegio || this.data.showRigheSolaLetturaBecauseCoordinatore || this.data.showRigheSolaLetturaBecauseAlreadyCosupervisore){
            if (this.data.codiceFiscaleCoordinatore === docente.codiceFiscale) {
                return ' **';
            } else if (this.data.idRigheSolaLettura.includes(docente.codiceFiscale)) {
                return ' *';
            } else {
                return ""
            }
        } else {
            return "";
        }
    }

}
