<ng-container *transloco="let t">
    <div class="flex flex-col flex-auto w-full pb-2.5 pl-2.5 pr-2.5" *ngIf="!!getMainFormGroup()"
         #mainContainer>
        <!-- FORMS -->
        <form [formGroup]="filterManagerService.mainFormGroup" (ngSubmit)="applyFilters()"
              (keydown.tab)="$event.stopPropagation()">


            <!-- TITLE -->
            <div style="background-color: #ffffff; z-index: 1000;" #titleDiv
                 class="flex flex-col sm:flex-row gap-3 sm:justify-between pr-2.5"
                 [style]="{'width': (mainContainer?.offsetWidth-10)+'px !important'}">
                <div class="mt-4 text-2xl font-medium ml-2">
                    <strong>{{ t('menu_filter.advanced_search') }}</strong>
                </div>

                <div class="pt-2 pb-2 grid grid-cols-1 sm:grid-cols-2 gap-3">

                    <!-- BUTTONS-->
                    <button mat-flat-button
                            class="multi-line-button"
                            (click)="reset()"
                            type="button"
                            [color]="'accent'">
                        <mat-icon class="mr-2">delete</mat-icon>
                        <span>{{ t('menu_filter.reset') }}</span>
                    </button>

                    <button mat-flat-button
                            type="submit"
                            [color]="'primary'">
                        <mat-icon class="mr-2">search</mat-icon>
                        <span>{{ t('home.apply') }}</span>
                    </button>
                </div>
                <mat-divider class="sm:hidden"></mat-divider>
            </div>

            <div style="overflow: auto">
                <div class="grid grid-cols-1 grid-rows-none sm:grid-cols-2 gap-3">
                    <ng-container *ngFor="let field of cicloConfigurationService.getEnabledFilterFields(currentFields)">

                        <ng-container *ngIf="(field !== _statoCarriera) && (field !== _statoCarrieraMotivazione)">

                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>{{ t(fieldsLabelMap.get(field)) }}</mat-label>
                                <input matInput
                                       [formControl]="getFormControlByPath(field)"
                                       (blur)="getFormControlByPath(field).setValue(getFormControlByPath(field)?.value?.trim())">
                            </mat-form-field>

                        </ng-container>

                        <ng-container *ngIf="field === _statoCarriera">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ t(fieldsLabelMap.get(field)) }}</mat-label>
                                <mat-select [formControl]="getFormControlByPath(field)" multiple (selectionChange)="statoCarrieraFilterChange()">
                                    <ng-container *ngFor="let statoCarriera of Object.values(StudenteCicloStatus)">
                                        <mat-option [value]="statoCarriera" *ngIf="statoCarriera !== StudenteCicloStatus.IPOTESI">
                                            {{t(statoCarriera | statusEnumToLanguagePath: StudenteCicloStatus) | titlecase}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field === _statoCarrieraMotivazione && getFormControlByPath(_statoCarriera)?.value?.length > 0">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ t(fieldsLabelMap.get(field)) }}</mat-label>
                                <mat-select [formControl]="getFormControlByPath(field)" multiple>
                                    <ng-container *ngFor="let statoCarrieraMotivo of getStudenteMotiviForStatus(getFormControlByPath(_statoCarriera)?.value)">
                                        <mat-option [value]="statoCarrieraMotivo">
                                            {{t(statoCarrieraMotivo | statusEnumToLanguagePath: StudenteCicloMotivoStatus) | titlecase}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>


                    </ng-container>
                </div>

            </div>

        </form>
    </div>
</ng-container>
