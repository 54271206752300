/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AggiornamentoBudgetStudenteStatus } from '../model/aggiornamentoBudgetStudenteStatus';
import { AggiornamentoBudgetStudenteStatusCountDTO } from '../model/aggiornamentoBudgetStudenteStatusCountDTO';
import { AggiornamentoPianoDiStudiStatus } from '../model/aggiornamentoPianoDiStudiStatus';
import { AttivitaExtraPianoDiStudiStatus } from '../model/attivitaExtraPianoDiStudiStatus';
import { AttivitaExtraPianoStudiStudenteStatusCountDTO } from '../model/attivitaExtraPianoStudiStudenteStatusCountDTO';
import { AttivitaTrasversaleStatus } from '../model/attivitaTrasversaleStatus';
import { AttivitaTrasversaliPianoStudiStudenteStatusCountDTO } from '../model/attivitaTrasversaliPianoStudiStudenteStatusCountDTO';
import { CountAttivitaStudenteRequest } from '../model/countAttivitaStudenteRequest';
import { CountAttivitaStudenteResponse } from '../model/countAttivitaStudenteResponse';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { MissioneStatus } from '../model/missioneStatus';
import { MissioneStudenteStatusCountDTO } from '../model/missioneStudenteStatusCountDTO';
import { OnboardingEsternoStatus } from '../model/onboardingEsternoStatus';
import { OnboardingEsternoStatusCountDTO } from '../model/onboardingEsternoStatusCountDTO';
import { PeriodoDiMobilitaStudenteStatus } from '../model/periodoDiMobilitaStudenteStatus';
import { PeriodoMobilitaStudenteStatusCountDTO } from '../model/periodoMobilitaStudenteStatusCountDTO';
import { PeriodoRegistroAttivitaStatus } from '../model/periodoRegistroAttivitaStatus';
import { PeriodoRegistroAttivitaStatusCountDTO } from '../model/periodoRegistroAttivitaStatusCountDTO';
import { PianoStudiStudenteStatusCountDTO } from '../model/pianoStudiStudenteStatusCountDTO';
import { RichiestaDiRetribuzioneMobilitaStatus } from '../model/richiestaDiRetribuzioneMobilitaStatus';
import { RichiestaDiRetribuzioneMobilitaStatusCountDTO } from '../model/richiestaDiRetribuzioneMobilitaStatusCountDTO';
import { SpesaStudenteStatus } from '../model/spesaStudenteStatus';
import { SpesaStudenteStatusCountDTO } from '../model/spesaStudenteStatusCountDTO';
import { SpesaStudenteTipologiaCountDTO } from '../model/spesaStudenteTipologiaCountDTO';
import { StatisticheSuperamentoCorsoPerDocenteDTO } from '../model/statisticheSuperamentoCorsoPerDocenteDTO';
import { StudentiCicloAnnoIscrizioneCountDTO } from '../model/studentiCicloAnnoIscrizioneCountDTO';
import { StudentiCicloBorsaStatsDTO } from '../model/studentiCicloBorsaStatsDTO';
import { StudentiCicloCodiceCorsoDiStudiEsse3CountDTO } from '../model/studentiCicloCodiceCorsoDiStudiEsse3CountDTO';
import { StudentiCicloNazionalitaCountDTO } from '../model/studentiCicloNazionalitaCountDTO';
import { StudentiCicloOfSSDSupervisoreCosupervisoreCountDTO } from '../model/studentiCicloOfSSDSupervisoreCosupervisoreCountDTO';
import { StudentiCicloOfSupervisoreCosupervisoreCountDTO } from '../model/studentiCicloOfSupervisoreCosupervisoreCountDTO';
import { StudentiCicloSessoCountDTO } from '../model/studentiCicloSessoCountDTO';
import { StudentiCicloStatusCountDTO } from '../model/studentiCicloStatusCountDTO';
import { SuperamentoAnnoStudenteStatus } from '../model/superamentoAnnoStudenteStatus';
import { SuperamentoAnnoStudenteStatusCountDTO } from '../model/superamentoAnnoStudenteStatusCountDTO';
import { TipoPeriodoEnum } from '../model/tipoPeriodoEnum';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StatsService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce il conteggio delle ore di attività nel registro attività dello studente. (iserire i parametri di tipo data nel formato &#x27;dd/MM/yyyy&#x27;)
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countOreAttivitaStudente(body: CountAttivitaStudenteRequest, observe?: 'body', reportProgress?: boolean): Observable<CountAttivitaStudenteResponse>;
    public countOreAttivitaStudente(body: CountAttivitaStudenteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountAttivitaStudenteResponse>>;
    public countOreAttivitaStudente(body: CountAttivitaStudenteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountAttivitaStudenteResponse>>;
    public countOreAttivitaStudente(body: CountAttivitaStudenteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling countOreAttivitaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CountAttivitaStudenteResponse>('post',`${this.basePath}/api/v1/stats/attivita-studente-count`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sulle richieste di aggiornamento dei budget degli studenti per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAggiornamentiBudgetStudentiPerStatus(status?: Array<AggiornamentoBudgetStudenteStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<AggiornamentoBudgetStudenteStatusCountDTO>>;
    public getAggiornamentiBudgetStudentiPerStatus(status?: Array<AggiornamentoBudgetStudenteStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AggiornamentoBudgetStudenteStatusCountDTO>>>;
    public getAggiornamentiBudgetStudentiPerStatus(status?: Array<AggiornamentoBudgetStudenteStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AggiornamentoBudgetStudenteStatusCountDTO>>>;
    public getAggiornamentiBudgetStudentiPerStatus(status?: Array<AggiornamentoBudgetStudenteStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AggiornamentoBudgetStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/aggiornamenti-budget-studenti-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli stati delle attività extra presenti nei piani di studi.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttivitaExtraPianoStudiStudenteStatusStats(status?: Array<AttivitaExtraPianoDiStudiStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaExtraPianoStudiStudenteStatusCountDTO>>;
    public getAttivitaExtraPianoStudiStudenteStatusStats(status?: Array<AttivitaExtraPianoDiStudiStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaExtraPianoStudiStudenteStatusCountDTO>>>;
    public getAttivitaExtraPianoStudiStudenteStatusStats(status?: Array<AttivitaExtraPianoDiStudiStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaExtraPianoStudiStudenteStatusCountDTO>>>;
    public getAttivitaExtraPianoStudiStudenteStatusStats(status?: Array<AttivitaExtraPianoDiStudiStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AttivitaExtraPianoStudiStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/stats-attivita-extra-piani-di-studi-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli stati delle attività trasversali presenti nei piani di studi.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttivitaTrasversaliPianoStudiStudenteStatusStats(status?: Array<AttivitaTrasversaleStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<AttivitaTrasversaliPianoStudiStudenteStatusCountDTO>>;
    public getAttivitaTrasversaliPianoStudiStudenteStatusStats(status?: Array<AttivitaTrasversaleStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AttivitaTrasversaliPianoStudiStudenteStatusCountDTO>>>;
    public getAttivitaTrasversaliPianoStudiStudenteStatusStats(status?: Array<AttivitaTrasversaleStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AttivitaTrasversaliPianoStudiStudenteStatusCountDTO>>>;
    public getAttivitaTrasversaliPianoStudiStudenteStatusStats(status?: Array<AttivitaTrasversaleStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AttivitaTrasversaliPianoStudiStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/stats-attivita-trasversali-piani-di-studi-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sulle missioni degli studenti per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMissioniStudentiStatusStats(status?: Array<MissioneStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<MissioneStudenteStatusCountDTO>>;
    public getMissioniStudentiStatusStats(status?: Array<MissioneStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MissioneStudenteStatusCountDTO>>>;
    public getMissioniStudentiStatusStats(status?: Array<MissioneStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MissioneStudenteStatusCountDTO>>>;
    public getMissioniStudentiStatusStats(status?: Array<MissioneStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MissioneStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/missioni-studenti-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sui periodi di mobilità degli studenti per stato senza conteggiare gli eventuali Padri.
     * 
     * @param status 
     * @param tipoPeriodoEnum 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodoMobilitaStatusStatsWithFiglioNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoMobilitaStudenteStatusCountDTO>>;
    public getPeriodoMobilitaStatusStatsWithFiglioNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoMobilitaStudenteStatusCountDTO>>>;
    public getPeriodoMobilitaStatusStatsWithFiglioNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoMobilitaStudenteStatusCountDTO>>>;
    public getPeriodoMobilitaStatusStatsWithFiglioNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        if (tipoPeriodoEnum !== undefined && tipoPeriodoEnum !== null) {
            queryParameters = queryParameters.set('tipoPeriodoEnum', <any>tipoPeriodoEnum);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoMobilitaStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/periodo-mobilita-by-status-with-figlio-null`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sui periodi di mobilità degli studenti per stato senza conteggiare gli eventuali Figli.
     * 
     * @param status 
     * @param tipoPeriodoEnum 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodoMobilitaStatusStatsWithPadreNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoMobilitaStudenteStatusCountDTO>>;
    public getPeriodoMobilitaStatusStatsWithPadreNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoMobilitaStudenteStatusCountDTO>>>;
    public getPeriodoMobilitaStatusStatsWithPadreNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoMobilitaStudenteStatusCountDTO>>>;
    public getPeriodoMobilitaStatusStatsWithPadreNull(status?: Array<PeriodoDiMobilitaStudenteStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        if (tipoPeriodoEnum !== undefined && tipoPeriodoEnum !== null) {
            queryParameters = queryParameters.set('tipoPeriodoEnum', <any>tipoPeriodoEnum);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoMobilitaStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/periodo-mobilita-by-status-with-padre-null`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sui periodi di registro attività degli studenti per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodoRegistroAttivitaStudentiPerStatus(status?: Array<PeriodoRegistroAttivitaStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaStatusCountDTO>>;
    public getPeriodoRegistroAttivitaStudentiPerStatus(status?: Array<PeriodoRegistroAttivitaStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaStatusCountDTO>>>;
    public getPeriodoRegistroAttivitaStudentiPerStatus(status?: Array<PeriodoRegistroAttivitaStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaStatusCountDTO>>>;
    public getPeriodoRegistroAttivitaStudentiPerStatus(status?: Array<PeriodoRegistroAttivitaStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoRegistroAttivitaStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/periodi-registro-attivita-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sui piani di studi degli studenti per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianiDiStudioStudentiPerStatus(status?: Array<AggiornamentoPianoDiStudiStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<PianoStudiStudenteStatusCountDTO>>;
    public getPianiDiStudioStudentiPerStatus(status?: Array<AggiornamentoPianoDiStudiStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PianoStudiStudenteStatusCountDTO>>>;
    public getPianiDiStudioStudentiPerStatus(status?: Array<AggiornamentoPianoDiStudiStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PianoStudiStudenteStatusCountDTO>>>;
    public getPianiDiStudioStudentiPerStatus(status?: Array<AggiornamentoPianoDiStudiStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PianoStudiStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/piano-studi-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sulle richieste di retribuzione di mobilità degli studenti per status.
     * 
     * @param status 
     * @param tipoPeriodoEnum 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRichiesteDiRetribuzioneMobilitaStudentiPerStatus(status?: Array<RichiestaDiRetribuzioneMobilitaStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'body', reportProgress?: boolean): Observable<Array<RichiestaDiRetribuzioneMobilitaStatusCountDTO>>;
    public getRichiesteDiRetribuzioneMobilitaStudentiPerStatus(status?: Array<RichiestaDiRetribuzioneMobilitaStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RichiestaDiRetribuzioneMobilitaStatusCountDTO>>>;
    public getRichiesteDiRetribuzioneMobilitaStudentiPerStatus(status?: Array<RichiestaDiRetribuzioneMobilitaStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RichiestaDiRetribuzioneMobilitaStatusCountDTO>>>;
    public getRichiesteDiRetribuzioneMobilitaStudentiPerStatus(status?: Array<RichiestaDiRetribuzioneMobilitaStatus>, tipoPeriodoEnum?: TipoPeriodoEnum, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        if (tipoPeriodoEnum !== undefined && tipoPeriodoEnum !== null) {
            queryParameters = queryParameters.set('tipoPeriodoEnum', <any>tipoPeriodoEnum);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RichiestaDiRetribuzioneMobilitaStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/richieste-di-retribuzione-mobilita-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sulle spese degli studenti per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpeseStudentiPerStatus(status?: Array<SpesaStudenteStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<SpesaStudenteStatusCountDTO>>;
    public getSpeseStudentiPerStatus(status?: Array<SpesaStudenteStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SpesaStudenteStatusCountDTO>>>;
    public getSpeseStudentiPerStatus(status?: Array<SpesaStudenteStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SpesaStudenteStatusCountDTO>>>;
    public getSpeseStudentiPerStatus(status?: Array<SpesaStudenteStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SpesaStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/spese-studenti-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sulle spese degli studenti per tipologia.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSpeseStudentiPerTipologia(observe?: 'body', reportProgress?: boolean): Observable<Array<SpesaStudenteTipologiaCountDTO>>;
    public getSpeseStudentiPerTipologia(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SpesaStudenteTipologiaCountDTO>>>;
    public getSpeseStudentiPerTipologia(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SpesaStudenteTipologiaCountDTO>>>;
    public getSpeseStudentiPerTipologia(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SpesaStudenteTipologiaCountDTO>>('get',`${this.basePath}/api/v1/stats/spese-studenti-by-tipologia`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche di superamento dei corsi tenuti da un docente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatisticheSuperamentoCorsiPerDocente(observe?: 'body', reportProgress?: boolean): Observable<Array<StatisticheSuperamentoCorsoPerDocenteDTO>>;
    public getStatisticheSuperamentoCorsiPerDocente(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StatisticheSuperamentoCorsoPerDocenteDTO>>>;
    public getStatisticheSuperamentoCorsiPerDocente(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StatisticheSuperamentoCorsoPerDocenteDTO>>>;
    public getStatisticheSuperamentoCorsiPerDocente(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StatisticheSuperamentoCorsoPerDocenteDTO>>('get',`${this.basePath}/api/v1/stats/stats-superamento-corsi-by-docente`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche di SSD dei Supervisori/Cosupervisori raggruppati per SSD.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloSSDSupervisoreCosupervisoreStats(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloOfSSDSupervisoreCosupervisoreCountDTO>>;
    public getStudentiCicloSSDSupervisoreCosupervisoreStats(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloOfSSDSupervisoreCosupervisoreCountDTO>>>;
    public getStudentiCicloSSDSupervisoreCosupervisoreStats(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloOfSSDSupervisoreCosupervisoreCountDTO>>>;
    public getStudentiCicloSSDSupervisoreCosupervisoreStats(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloOfSSDSupervisoreCosupervisoreCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-ssd-supervisore-cosupervisore`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per anno di iscrizione.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerAnnoIscrizione(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloAnnoIscrizioneCountDTO>>;
    public getStudentiCicloStatsPerAnnoIscrizione(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloAnnoIscrizioneCountDTO>>>;
    public getStudentiCicloStatsPerAnnoIscrizione(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloAnnoIscrizioneCountDTO>>>;
    public getStudentiCicloStatsPerAnnoIscrizione(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloAnnoIscrizioneCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-anno-iscrizione`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per borsa.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerBorsa(observe?: 'body', reportProgress?: boolean): Observable<StudentiCicloBorsaStatsDTO>;
    public getStudentiCicloStatsPerBorsa(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StudentiCicloBorsaStatsDTO>>;
    public getStudentiCicloStatsPerBorsa(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StudentiCicloBorsaStatsDTO>>;
    public getStudentiCicloStatsPerBorsa(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StudentiCicloBorsaStatsDTO>('get',`${this.basePath}/api/v1/stats/studenti-by-tipo-borsa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per anno di iscrizione.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerCodiceCorsoDiStudiEsse3(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloCodiceCorsoDiStudiEsse3CountDTO>>;
    public getStudentiCicloStatsPerCodiceCorsoDiStudiEsse3(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloCodiceCorsoDiStudiEsse3CountDTO>>>;
    public getStudentiCicloStatsPerCodiceCorsoDiStudiEsse3(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloCodiceCorsoDiStudiEsse3CountDTO>>>;
    public getStudentiCicloStatsPerCodiceCorsoDiStudiEsse3(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloCodiceCorsoDiStudiEsse3CountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-codice-corso-esse3`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per nazionalità.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerNazionalita(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloNazionalitaCountDTO>>;
    public getStudentiCicloStatsPerNazionalita(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloNazionalitaCountDTO>>>;
    public getStudentiCicloStatsPerNazionalita(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloNazionalitaCountDTO>>>;
    public getStudentiCicloStatsPerNazionalita(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloNazionalitaCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-nazionalita`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per nazionalità dell&#x27;Ateneo di provenienza.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerNazioneAteneoProvenienza(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloNazionalitaCountDTO>>;
    public getStudentiCicloStatsPerNazioneAteneoProvenienza(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloNazionalitaCountDTO>>>;
    public getStudentiCicloStatsPerNazioneAteneoProvenienza(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloNazionalitaCountDTO>>>;
    public getStudentiCicloStatsPerNazioneAteneoProvenienza(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloNazionalitaCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-nazione-ateneo-provenienza`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per sesso.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerSesso(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloSessoCountDTO>>;
    public getStudentiCicloStatsPerSesso(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloSessoCountDTO>>>;
    public getStudentiCicloStatsPerSesso(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloSessoCountDTO>>>;
    public getStudentiCicloStatsPerSesso(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloSessoCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-sesso`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli studenti per status.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloStatsPerStatus(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloStatusCountDTO>>;
    public getStudentiCicloStatsPerStatus(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloStatusCountDTO>>>;
    public getStudentiCicloStatsPerStatus(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloStatusCountDTO>>>;
    public getStudentiCicloStatsPerStatus(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sui supervisori/cosupervisori degli studenti.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiCicloSupervisoreCosupervisoreStats(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentiCicloOfSupervisoreCosupervisoreCountDTO>>;
    public getStudentiCicloSupervisoreCosupervisoreStats(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentiCicloOfSupervisoreCosupervisoreCountDTO>>>;
    public getStudentiCicloSupervisoreCosupervisoreStats(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentiCicloOfSupervisoreCosupervisoreCountDTO>>>;
    public getStudentiCicloSupervisoreCosupervisoreStats(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StudentiCicloOfSupervisoreCosupervisoreCountDTO>>('get',`${this.basePath}/api/v1/stats/studenti-by-supervisore-cosupervisore`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sui Superamenti anno degli studenti per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSuperamentiAnnoStudentiStatusStats(status?: Array<SuperamentoAnnoStudenteStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<SuperamentoAnnoStudenteStatusCountDTO>>;
    public getSuperamentiAnnoStudentiStatusStats(status?: Array<SuperamentoAnnoStudenteStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SuperamentoAnnoStudenteStatusCountDTO>>>;
    public getSuperamentiAnnoStudentiStatusStats(status?: Array<SuperamentoAnnoStudenteStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SuperamentoAnnoStudenteStatusCountDTO>>>;
    public getSuperamentiAnnoStudentiStatusStats(status?: Array<SuperamentoAnnoStudenteStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SuperamentoAnnoStudenteStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/superamenti-anno-studenti-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli utenti esterni invitati per status.
     * 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUtentiEsterniPerStatus(status?: Array<OnboardingEsternoStatus>, observe?: 'body', reportProgress?: boolean): Observable<Array<OnboardingEsternoStatusCountDTO>>;
    public getUtentiEsterniPerStatus(status?: Array<OnboardingEsternoStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OnboardingEsternoStatusCountDTO>>>;
    public getUtentiEsterniPerStatus(status?: Array<OnboardingEsternoStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OnboardingEsternoStatusCountDTO>>>;
    public getUtentiEsterniPerStatus(status?: Array<OnboardingEsternoStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OnboardingEsternoStatusCountDTO>>('get',`${this.basePath}/api/v1/stats/utente-esterno-by-status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
