import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {tap} from 'rxjs/operators';
import {distinctUntilChanged, Observable, take, takeUntil} from 'rxjs';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {
    CodiceRispostaEnum,
    MobilitaRequestSelectFormValuesDTO,
    PeriodoDiMobilitaStudenteStatus,
    TipoPeriodoEnum
} from '../../../../../api-clients/generated/services';

import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {isEmpty} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {AuditFilterManagerService} from "../../../../shared/service/audit-filter-manager.service";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";

@Component({
    selector: 'app-audit-sidebar-filter-container',
    templateUrl: './audit-sidebar-filter-container.component.html',
    styleUrls: ['./audit-sidebar-filter-container.component.scss']
})
export class AuditSidebarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    outputFormatData = 'DD/MM/YYYY';
    filterApplied = {
        'codiceRisposta': null,
        'codiceRispostaNonMode': null,
        'nominativo': null,
        'uri': null,
        'operazione': null,
    };
    selectValues: MobilitaRequestSelectFormValuesDTO;
    currentLanguage: Observable<string>;
    // filters names
    _codiceRisposta = "codiceRisposta";
    _nominativo = "nominativo";
    _uri = "uri";
    _operazione = "operazione";

    protected readonly Object = Object;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;

    constructor(public auditFilterManagerService: AuditFilterManagerService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,
                private translocoService: TranslocoService,
                protected cicloConfigurationService: CicloConfigurationService,) {
        super();
        this.currentFields = this.auditFilterManagerService.defaultFormPathList;
        this.fieldsLabelMap = this.auditFilterManagerService.fieldsLabelMap;
        this.currentLanguage = this.translocoService.langChanges$;
    }

    ngOnInit(): void {
        this.appInitService.selectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => this.auditFilterManagerService.init()),
            tap(value =>
                this.currentFields = this.auditFilterManagerService.getFieldsByRole(value?.ruolo, value?.sottoruolo)),
            takeUntil(this.destroy$)
        ).subscribe();

        this.auditFilterManagerService.checkAggiornamentoSelectValues$.asObservable().pipe(
            tap(() => this.selectValues = this.auditFilterManagerService.selectValues),
            takeUntil(this.destroy$)
        ).subscribe();

        this.auditFilterManagerService.filterApplied$.asObservable().pipe(
            tap((value) => this.filterApplied = value),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.auditFilterManagerService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.auditFilterManagerService.mainFormGroup;
    }

    applyFilters(): void {
        //this.filterApplied= this.getMainFormGroup().getRawValue();
        this.auditFilterManagerService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeDrawer.next(true);
    }


    reset(): void {
        this.resetFilterApplied();
        this.auditFilterManagerService?.resetFormGroup();
        this.auditFilterManagerService?.checkApplyFilterClick$.next(true);
        this.close();
    }

    setFilterApplied(): void {
/*        if (!!this.filterApplied) {
            if (!!this.filterApplied?.codiceFiscaleSupervisore && this.filterApplied?.codiceFiscaleSupervisore?.nome && this.filterApplied?.codiceFiscaleSupervisore?.cognome) {
                this.filterApplied.codiceFiscaleSupervisore.nome = capitalizeString(this.filterApplied.codiceFiscaleSupervisore?.nome);
                this.filterApplied.codiceFiscaleSupervisore.cognome = capitalizeString(this.filterApplied.codiceFiscaleSupervisore?.cognome);
            }
            // eslint-disable-next-line max-len
            if (!!this.filterApplied?.codiceFiscaleCosupervisore && this.filterApplied?.codiceFiscaleCosupervisore?.nome && this.filterApplied?.codiceFiscaleCosupervisore?.cognome) {
                this.filterApplied.codiceFiscaleCosupervisore.nome = capitalizeString(this.filterApplied.codiceFiscaleCosupervisore?.nome);
                this.filterApplied.codiceFiscaleCosupervisore.cognome = capitalizeString(this.filterApplied.codiceFiscaleCosupervisore?.cognome);
            }
            if (!!this.filterApplied?.codiceFiscaleDocenteCorso && this.filterApplied?.codiceFiscaleDocenteCorso?.nome && this.filterApplied.codiceFiscaleDocenteCorso?.cognome) {
                this.filterApplied.codiceFiscaleDocenteCorso.nome = capitalizeString(this.filterApplied.codiceFiscaleDocenteCorso?.nome);
                this.filterApplied.codiceFiscaleDocenteCorso.cognome = capitalizeString(this.filterApplied.codiceFiscaleDocenteCorso?.cognome);
            }
            if (!!this.filterApplied?.idCorsoInOffertaFormativa && this.filterApplied?.idCorsoInOffertaFormativa?.denominazione) {
                this.filterApplied.idCorsoInOffertaFormativa.denominazione = capitalizeString(this.filterApplied.idCorsoInOffertaFormativa?.denominazione);
            }
        }*/

        if (!!this.filterApplied && !isEmpty(this.filterApplied)) {
            this.auditFilterManagerService.mainFormGroup.patchValue(
                {
                    'codiceRisposta': this.filterApplied?.codiceRisposta,
                    'codiceRispostaNonMode': this.filterApplied?.codiceRispostaNonMode,
                    'nominativo': this.filterApplied?.nominativo,
                    'uri': this.filterApplied?.uri,
                    'operazione': this.filterApplied?.operazione,
                }
            );
        }
    }

    resetFilterApplied(): void {
        this.filterApplied = {
            'codiceRisposta': null,
            'codiceRispostaNonMode': false,
            'nominativo': null,
            'uri': null,
            'operazione': null,
        };
        this.auditFilterManagerService.resetFormGroup();
    }


    protected readonly CodiceRispostaEnum = CodiceRispostaEnum;
}
