/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi flusso
 */
export type ChiaveFlussoEnum = 'superamento_anno' | 'prima_presentazione_piano_formativo' | 'modifica_piano_formativo';

export const ChiaveFlussoEnum = {
    SuperamentoAnno: 'superamento_anno' as ChiaveFlussoEnum,
    PrimaPresentazionePianoFormativo: 'prima_presentazione_piano_formativo' as ChiaveFlussoEnum,
    ModificaPianoFormativo: 'modifica_piano_formativo' as ChiaveFlussoEnum
};