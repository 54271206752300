import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot,} from '@angular/router';
import {Observable, switchMap} from "rxjs";
import {first} from "rxjs/operators";
import {CicloConfigurationService} from "../../shared/service/ciclo-configuration.service";

export abstract class CicloConfigDependantGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(
        protected cicloConfigurationService: CicloConfigurationService,
        ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        // waiting conf has loaded
        return this.cicloConfigurationService.cicloConfigurationLoaded$.pipe(
            first(value => value),
            switchMap(_ => this._canActivate(route, state)));
    }

    abstract _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>;

}
