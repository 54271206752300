import {
    GenericTableConfigurationModel, TipoClickEnum,
    TipoColonnaEnum
} from "../../../shared/components/table/model/generic-table-model";
import {
    AddDeliberaDTO,
    AuthorityType, DeliberaDiProgrammazioneMobilitaFiltered, DeliberaInfoView,
    DeliberaInfoViewImpl, DeliberaTemplate, PageDeliberaDiProgrammazioneMobilitaFiltered,
    PageDeliberaInfoViewImpl, PageDeliberaTemplate
} from "../../../../api-clients/generated/services";
import {DEFAULT_PAGE_SIZE} from "./gestione-documenti.component";
import moment from "moment";
import {TypeDialogFormEnum} from "../../../layout/common/generic-components/generic-components-enum";
import {optionFile} from "../../../shared/costants/app-constants";


export function buildDelibereTableConfiguration(pageDelibere: PageDeliberaInfoViewImpl,
                                                pageSize: number = DEFAULT_PAGE_SIZE,
                                                currentRuolo: AuthorityType,
                                                currentSottoruolo: AuthorityType,
                                                isAreaAdministrator?: boolean,
): GenericTableConfigurationModel {
    const delibereForUI = pageDelibere?.content?.map((delibera: DeliberaInfoViewImpl) =>
        ({
            ...delibera,
            dataFormatted: moment(delibera?.data, 'YYYY-MM-DD').format('DD/MM/YYYY')
        }));
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: delibereForUI,
            totalElements: pageDelibere?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.approval_number',
                    colonnaKey: 'numero',
                    flex: 30,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.approval_date',
                    colonnaKey: 'dataFormatted',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.course',
                    colonnaKey: 'codiceCorsoDiStudiEsse3',
                    flex: 15,
                    hideColonna: !isAreaAdministrator
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.notes',
                    colonnaKey: 'note',
                    flex: 30,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 15,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    show: (value, element: DeliberaInfoViewImpl) =>
                        !!element?.url
                        || !!element?.urlNotaDiTrasmissione
                        || (!isAreaAdministrator && (currentSottoruolo === AuthorityType.COORDINATORE ||
                            currentSottoruolo === AuthorityType.GRUPPOPTA ||
                            currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO)),
                    button: [
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.DOWNLOAD,
                            tooltip: 'documents_dashboard.approval_download',
                            show: (value, element: DeliberaInfoViewImpl) => !!element?.url,
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.DOWNLOAD_NOTA_TRASMISSIONE,
                            tooltip: 'documents_dashboard.transmission_note_download',
                            show: (value, element: DeliberaInfoViewImpl) => !!element?.urlNotaDiTrasmissione,
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'documents_dashboard.approval_edit',
                            color: 'accent',
                            show: (value) => !isAreaAdministrator && (currentSottoruolo === AuthorityType.COORDINATORE ||
                                currentSottoruolo === AuthorityType.GRUPPOPTA ||
                                currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO),
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.DELETE,
                            tooltip: 'documents_dashboard.approval_delete',
                            color: 'accent',
                            show: (value) => !isAreaAdministrator && (currentSottoruolo === AuthorityType.COORDINATORE ||
                                currentSottoruolo === AuthorityType.GRUPPOPTA ||
                                currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO),
                            valueShow: 'numero'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_document'
        }
    };
    return config;
}

export function buildDelibereProgrammazioneTableConfiguration(pageDelibere: PageDeliberaDiProgrammazioneMobilitaFiltered,
                                                pageSize: number = DEFAULT_PAGE_SIZE,
                                                currentSottoruolo: AuthorityType,
                                                currentRuolo: AuthorityType): GenericTableConfigurationModel {
    const delibereForUI = pageDelibere?.content?.map((deliberaDiProgrammazione: DeliberaDiProgrammazioneMobilitaFiltered) =>
        ({
            ...deliberaDiProgrammazione,
            numero: deliberaDiProgrammazione?.delibera?.numero,
            dataFormatted: deliberaDiProgrammazione?.delibera?.data ? moment(deliberaDiProgrammazione?.delibera?.data).format('DD/MM/YYYY') : undefined,
            codiceCorsoDiStudiEsse3: deliberaDiProgrammazione?.delibera?.codiceCorsoDiStudiEsse3,
            url: deliberaDiProgrammazione?.delibera?.url
        }));
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: delibereForUI,
            totalElements: pageDelibere?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.approval_number',
                    colonnaKey: 'numero',
                    flex: 45,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.approval_date',
                    colonnaKey: 'dataFormatted',
                    flex: 40,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.course',
                    colonnaKey: 'codiceCorsoDiStudiEsse3',
                    flex: 40,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 15,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.DOWNLOAD,
                            tooltip: 'documents_dashboard.approval_download',
                            show: (value, element) => element?.url,
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_DELIBERA_MOBILITA_DETAIL,
                            tooltip: 'mobility.show_detail_delibera',
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'mobility.delibera_edit',
                            color: 'accent',
                            valueShow: 'numero',
                            show: (value, element) => currentSottoruolo === AuthorityType.GRUPPOPTA || currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.DELETE,
                            tooltip: 'mobility.delibera_delete',
                            color: 'accent',
                            valueShow: 'numero',
                            show: (value, element) => currentSottoruolo === AuthorityType.GRUPPOPTA || currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data'
        }
    };
    return config;
}


export function buildTemplateDelibereTableConfiguration(pageDeliberaTemplate: PageDeliberaTemplate, pageSize: number = DEFAULT_PAGE_SIZE,
                                                currentRuolo: AuthorityType, isAreaAdministrator: boolean,         currentSottoruolo: AuthorityType): GenericTableConfigurationModel {
    const delibereForUI = pageDeliberaTemplate?.content?.map((delibera: DeliberaTemplate) =>
        ({
            ...delibera,
            dataUltimoAggiornamento: moment(delibera?.dataUltimoAggiornamento).format('DD/MM/YYYY HH:mm'),
            dataCaricamento: moment(delibera?.dataCaricamento).format('DD/MM/YYYY HH:mm')

        }));
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: delibereForUI,
            totalElements: pageDeliberaTemplate?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.name',
                    colonnaKey: 'nome',
                    flex: 45,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.creation_date',
                    colonnaKey: 'dataCaricamento',
                    flex: 45,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.last_update_date',
                    colonnaKey: 'dataUltimoAggiornamento',
                    flex: 45,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.description',
                    colonnaKey: 'descrizione',
                    flex: 45,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 15,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    show: (value ) => [
                        AuthorityType.COORDINATORE,
                        AuthorityType.GRUPPODICOORDINAMENTO,
                        AuthorityType.DIRETTOREDIDIPARTIMENTO,
                        AuthorityType.GRUPPOPTA,
                        AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
                        ].includes(currentSottoruolo) ||
                        [
                            AuthorityType.SUPERADMIN,
                            AuthorityType.ADMIN
                        ].includes(currentRuolo) ||
                        isAreaAdministrator,
                    button: [
                        {
                            nameIconButton: 'download',
                            click: TipoClickEnum.DOWNLOAD,
                            tooltip: 'common.download',
                            show: (value) => [
                                AuthorityType.COORDINATORE,
                                AuthorityType.GRUPPODICOORDINAMENTO,
                                AuthorityType.DIRETTOREDIDIPARTIMENTO,
                                AuthorityType.GRUPPOPTA,
                                AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
                            ].includes(currentSottoruolo) ||
                            [
                                AuthorityType.SUPERADMIN,
                                AuthorityType.ADMIN
                            ].includes(currentRuolo) ||
                                isAreaAdministrator,
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'common.edit',
                            color: 'accent',
                            show: (value) => isAreaAdministrator && currentRuolo === AuthorityType.SUPERADMIN,
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.DELETE,
                            tooltip: 'common.delete',
                            color: 'accent',
                            show: (value) => isAreaAdministrator && currentRuolo === AuthorityType.SUPERADMIN,
                            valueShow: 'numero'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_template'
        }
    };
    return config;
}

export function buildFormConfigForAddNewDelibera(fileDeliberaRequired: boolean = false) {
    return [
        {
            show: true,
            name: 'dataDelibera',
            transloco: 'budget.approval_date',
            required: true,
            type: TypeDialogFormEnum.DATA,
        },
        {
            show: true,
            name: 'numeroDelibera',
            transloco: 'budget.approval_number',
            required: true,
            type: TypeDialogFormEnum.TEXT,
        },
        {
            show: true,
            name: 'fileDelibera',
            transloco: 'mobility.delibera',
            required: fileDeliberaRequired,
            type: TypeDialogFormEnum.FILE,
            optionFile: optionFile
        },
        {
            show: true,
            name: 'fileNotaTrasmissione',
            transloco: 'documents_dashboard.transmission_note',
            //required: true,
            type: TypeDialogFormEnum.FILE,
            optionFile: optionFile
        },
        {
            show: true,
            name: 'nota',
            transloco: 'common.notes',
            required: false,
            type: TypeDialogFormEnum.TEXTAREA,
            textAreaMinRows: 2,
            textAreaMaxRows: 4,
        },
    ];
}
