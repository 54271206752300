import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AddCategoriaOffertaFormativaDTO,
    AnnoRiferimentoValues,
    AttivitaCategoriaAssociationInfoView,
    AttivitaOffertaFormativaInfoView,
    AuthorityType,
    CategoriaOffertaFormativaInfoView, ChiaveFunzionalitaEnum, ClonaOffertaFormativaResponse,
    ConfigurazioneCategoriaPerAnni,
    CorsoDiStudiViewImpl,
    DatiPercorsoDiStudi,
    ElementoOffertaFormativaType, FunzionalitaStatus,
    GuidaLinguaInfoView,
    LingueEnum,
    MediaService,
    OffertaFormativaInfoViewImpl,
    OffertaFormativaStatus,
    OfferteFormativeService,
    UpdateCategoriaOffertaFormativaDTO,
    UpdateCorsoOffertaFormativaPubblicaDTO,
    UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO,
    UserCodiceFiscaleNomeCognomeView,
    UserFilterForm,
    UserRoleBasedInfoView,
    UsersService
} from '../../../../../api-clients/generated/services';
import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {filter, first, switchMap, tap} from 'rxjs/operators';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {Translation, TranslocoService} from '@ngneat/transloco';
import {FuseConfirmationDialogListDataI, FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, finalize, of, take, takeUntil} from 'rxjs';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TableDataRows,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../shared/components/table/model/generic-table-model';
import {CicloCorsoRuoloInterface} from '../../../../shared/interface/CicloCorsoRuoloInterface';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogInfoComponent, DialogInfoI} from '../../../../shared/components/dialog-info/dialog-info.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {get, head, isEmpty, isNil, sortBy, trim} from 'lodash';
import {docente} from '../cycle-information/cycle-information.component';
import {MatTabGroup} from '@angular/material/tabs';
import {LogoutService} from '../../../../shared/service/logout.service';
import {NoopScrollStrategy} from '@angular/cdk/overlay';
import {
    DialoEditGuidaCoordinatoreI,
    DialogEditGuidaCoordinatoreComponent
} from '../../../../shared/components/dialog-edit-info-compilazione/dialog-edit-guida-coordinatore.component';
import {FormControl, FormGroup} from '@angular/forms';
import {SnackbarTypes} from '../../../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {
    DialogTableMultiSelectConfiguration
} from '../../../../shared/components/dialog-table-multi-select/dialog-table-multi-select.model';
import {
    DialogSelectDocentiComponent
} from '../../../../shared/components/dialog-select-docenti/dialog-select-docenti.component';
import {
    DialogManageActivityComponent,
    DialogManageActivityI
} from '../../../../shared/components/dialog-manage-activity/dialog-manage-activity.component';
import moment from 'moment';
import {
    DialogTableMultiSelectComponent
} from '../../../../shared/components/dialog-table-multi-select/dialog-table-multi-select.component';
import {cloneDeep, uniqBy} from 'lodash-es';
import {TimeService} from '../../../../shared/service/time.service';
import {
    annoInsegnamentoValueToNumber,
    annoRiferimentoFromRomanNumeral,
    annoRiferimentoToRomanNumeral, AnnoRiferimentoWithAllValues,
    handleErrorOnExportPDF, joinArrayAnni,
    makeFilenameFromFE
} from '../../../../shared/utils/utils';
import fs from 'file-saver';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
    DialogErrorsListComponent,
    DialogErrorsListI
} from '../../../../shared/dialogs/dialog-errors-list/dialog-errors-list.component';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '../../../../../@fuse/components/navigation';
import {ExcelConfig, ExcelService} from '../../../../shared/service/excel.service';
import {
    TrainingOfferCategoryAddEditDialogComponent,
    TrainingOfferCategoryAddEditDialogI
} from './training-offer-category-add-edit-dialog/training-offer-category-add-edit-dialog.component';
import {
    TrainingOfferCategoryDataUI
} from '../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component';
import {
    DialogManageActivityService
} from "../../../../shared/components/dialog-manage-activity/dialog-manage-activity.service";
import {
    DialogInvalidStudyPlansComponent,
    DialogInvalidStudyPlansComponentI
} from "./dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {
    annoRiferimentoToTrainingOfferYearFragment,
    createOfferCategoriesByActivityTypeConfigurations,
    getExcelConfigurationsForCorsi,
    getExcelConfigurationsForExtra,
    getExcelConfigurationsForTransversal,
    getExcelTopHeaderConfigurations, getOfferCategoriesByAllYears,
    getOfferCategoriesByYear,
    getOfferCategoriesCompliance,
    mapAttivitaForExcel,
} from "./training-offer-utils";
import {
    getVincoloCfu
} from "../../student-details/study-plan/tab-creation-study-plan/compliancies-utils/draft-compliancies-utils";
import {TrainingOfferService} from "./training-offer.service";
import {
    CurriculumAccordionComponent
} from "../../../../shared/components/curriculum-accordion/curriculum-accordion.component";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {DialogMoveActivityComponent, DialogMoveActivityI} from "./dialog-move-activity/dialog-move-activity.component";
import RuoloEnum = UserFilterForm.RuoloEnum;
import {ButtonType} from "../../../../shared/components/custom-button/custom-button.component";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";

@Component({
    selector: 'app-training-offer',
    templateUrl: './training-offer.component.html',
    styleUrls: ['./training-offer.component.scss'],
})
export class TrainingOfferComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    loading: boolean = false;
    title: string;
    subtitle: string;
    categoriesByYearConfigurations: TrainingOfferCategoryByYearDataUI[];
    _tabsGroup: MatTabGroup;
    guidaCoordinatore: GuidaLinguaInfoView[];
    guidaAteneo: GuidaLinguaInfoView[];
    showEditGuidaCoordinatoreButton: boolean;
    showSelect: boolean = false;
    currentRuolo: AuthorityType;
    sottoruoli: string[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    showEditCategoriesAndActivities: boolean;
    activityTabSelectedIndex = 0;
    showArchived: boolean;
    offertaFormativa: OffertaFormativaInfoViewImpl;
    corsi: Array<CorsoDiStudiViewImpl> | undefined;
    isReadOnly: boolean;
    ctrlCorso: FormControl = new FormControl(null);
    numberOfArchivedActivities: number = 0;
    showEditInfoCompileButton: boolean;
    allCourses: Array<AttivitaOffertaFormativaInfoView & { isAssociazioneArchiviata?: boolean }>;
    anniRiferimento = [
        AnnoRiferimentoValues.PRIMO,
        AnnoRiferimentoValues.SECONDO,
        AnnoRiferimentoValues.TERZO
    ];
    isSomeYearNotCompliant: boolean;
    canClone: boolean;

    @ViewChild(MatTabGroup) set tabsGroup(tabsGroup: MatTabGroup) {
        if (tabsGroup && !this._tabsGroup) {
            this._tabsGroup = tabsGroup;
            this.handleFragmentNavigation(tabsGroup);
        }
    }

    constructor(private offerteFormativeService: OfferteFormativeService,
                public appInitService: AppInitService,
                private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private usersService: UsersService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private dialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                private logOutService: LogoutService,
                private localStorageService: LocalStorageService,
                private timeService: TimeService,
                private fuseNavigationService: FuseNavigationService,
                protected excelService: ExcelService,
                private dialogAddCourseService: DialogManageActivityService,
                private trainingOfferService: TrainingOfferService,
                protected cicloConfigurationService: CicloConfigurationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getSottoruoliAndOfferRequest();
        this.subscribeToSottoruoliValueChange();
    }

    ngOnDestroy() {
        this.dialogAddCourseService.curriculums = [];
        super.ngOnDestroy();
    }

    handleFragmentNavigation(tabsGroup: MatTabGroup) {
        this.activatedRoute.fragment.pipe(takeUntil(this.destroy$), first()).subscribe((fragment: string) => {
            console.log('typed fragment', fragment);
            const tabs = (tabsGroup?._tabs as any)._results;
            console.log(tabs);
            const matchIndex = tabs?.findIndex(x => x.ariaLabel === fragment);
            console.log('matched fragment with tab at position', matchIndex);
            if (matchIndex >= 0) {
                tabsGroup.selectedIndex = matchIndex;
            }
        });
    }

    init(dipartimentoRuoloCiclo: CicloCorsoRuoloInterface): void {
        this.title = dipartimentoRuoloCiclo.ciclo;
        this.subtitle = dipartimentoRuoloCiclo.codiceCorsoStudi;
        this.showEditInfoCompileButton = (dipartimentoRuoloCiclo.ruolo === AuthorityType.DOCENTE &&
            (dipartimentoRuoloCiclo.sottoruolo === AuthorityType.COORDINATORE || dipartimentoRuoloCiclo.sottoruolo === AuthorityType.GRUPPODICOORDINAMENTO || dipartimentoRuoloCiclo.ruolo === AuthorityType.SUPERADMIN));
        this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(dipartimentoRuoloCiclo?.sottoruolo, dipartimentoRuoloCiclo?.ruolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
        this.canClone = false;
        this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
    }

    initAmm(): void {
        this.title = this.appInitService.cicloAmmSelected?.toString();
        this.subtitle = this.ctrlCorso.value;
        this.showEditInfoCompileButton = false;
        const dipartimentoRuoloCiclo = this.localStorageService.getCicloCorsoRuolo();
        this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(dipartimentoRuoloCiclo?.sottoruolo, dipartimentoRuoloCiclo?.ruolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
        this.canClone = false;
        this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
    }

    setOffertaFormativaInfoViewLists(offertaFormativaInfo: OffertaFormativaInfoViewImpl): void {


        // method code
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);

        this.categoriesByYearConfigurations = [AnnoRiferimentoWithAllValues.ALL, ...this.anniRiferimento].map(year => ({
            year: year !== AnnoRiferimentoWithAllValues.ALL ? annoRiferimentoToRomanNumeral(year as AnnoRiferimentoValues) : undefined,
            fragmentLabel: year !== AnnoRiferimentoWithAllValues.ALL ? annoRiferimentoToTrainingOfferYearFragment(year as AnnoRiferimentoValues) : undefined,
            categoriesByActivityType: createOfferCategoriesByActivityTypeConfigurations(
                year !== AnnoRiferimentoWithAllValues.ALL ? getOfferCategoriesByYear(offertaFormativaInfo, year as AnnoRiferimentoValues, this.showArchived) : getOfferCategoriesByAllYears(offertaFormativaInfo, this.showArchived),
                translation,
                year !== AnnoRiferimentoWithAllValues.ALL ? (year as AnnoRiferimentoValues) : undefined,
                (t, category) => this.createTableConfigurationForCourses(t, category),
                (inputList) => this.createTableConfigurationForExtraActivities(inputList),
                (inputList) => this.createTableConfigurationForTransversalActivities(inputList)
            )
        }));

        this.computeCategoriesCompliance();

        this.isSomeYearNotCompliant = !this.categoriesByYearConfigurations[1].activitiesCompliant
            || !this.categoriesByYearConfigurations[2].activitiesCompliant
            || !this.categoriesByYearConfigurations[3].activitiesCompliant;

        this.numberOfArchivedActivities = this.computeNumberOfArchivedActivities(offertaFormativaInfo);
        this.allCourses = this.getAllUniqueCourses(offertaFormativaInfo.categorie);
        console.log(this.categoriesByYearConfigurations, 'listConfigurations')

        this.guidaCoordinatore = offertaFormativaInfo?.guidaCoordinatoreLingue;
        this.guidaAteneo = offertaFormativaInfo?.guidaAteneoLingue;
    }

    private getActivityYearsFormatted(att: AttivitaCategoriaAssociationInfoView) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        let anni = [];
        if (att.primoAnno) {
            anni.push('I');
        }
        if (att.secondoAnno) {
            anni.push('II');
        }
        if (att.terzoAnno) {
            anni.push('III');
        }
        return joinArrayAnni(anni, this._translocoService);
    }

    createTableConfigurationForCourses(translation: Translation,
                                       category?: CategoriaOffertaFormativaInfoView,
    ): GenericTableConfigurationModel {
        let list = category.attivitaCategoriaAssociations?.map((item, index) => ({
            ...item,
            denominazione: item.attivitaOffertaFormativa.denominazione,
            cfu: item.attivitaOffertaFormativa.cfu,
            ore: item.attivitaOffertaFormativa.ore,
            descrizione: item.attivitaOffertaFormativa.descrizione,
            isObbligatorio: item.attivitaOffertaFormativa.isObbligatorio,
            periodoErogazione: item.attivitaOffertaFormativa.periodoErogazione,
            curriculumRiferimento: this.createPercorsoDiStudiListString(item?.attivitaOffertaFormativa.percorsiDiStudi),
            docentiTutti: [...(item.attivitaOffertaFormativa.docenti || []), ...(item.attivitaOffertaFormativa.docentiExtraOffertaFormativa.map(extra => ({
                ...extra,
                highlightChip: ([AuthorityType.COORDINATORE].includes(this.ctrlSelectFormControl.value) || this.currentRuolo === AuthorityType.SUPERADMIN) && !this.appInitService.isAreaAdministrator
            })) || [])],
            dataArchiviazioneFormatted: (item.attivitaOffertaFormativa.dataArchiviazione ?? item.dataArchiviazione) ? moment(item.attivitaOffertaFormativa.dataArchiviazione ?? item.dataArchiviazione).format('DD/MM/YYYY') : '',
            anniDisponibilita: this.getActivityYearsFormatted(item)
        }));
        list = sortBy(list, [
            attivita => trim(attivita?.attivitaOffertaFormativa?.denominazione?.toLowerCase())
        ], ['asc']);
        const config: GenericTableConfigurationModel = {
            configuration: {
                data: list,
                totalElements: list?.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.course_title',
                        colonnaKey: 'denominazione',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.NUMBER,
                        nomeColonna: 'common.cfu',
                        colonnaKey: 'cfu',
                        flex: 5,
                    },
                    {
                        tipo: TipoColonnaEnum.NUMBER,
                        nomeColonna: 'total_hour_table.column_total_hour',
                        colonnaKey: 'ore',
                        flex: 3,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.disbursement_period',
                        colonnaKey: 'periodoErogazione',
                        flex: 12,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.reference_curriculum',
                        colonnaKey: 'curriculumRiferimento',
                        hideColonna: true,
                        flex: 18,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.mandatory',
                        colonnaKey: 'isObbligatorio',
                        flex: 11,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.professor',
                        colonnaKey: 'docentiTutti',
                        chipIconTooltip: (this.ctrlSelectFormControl?.value === AuthorityType.COORDINATORE || this.currentRuolo === AuthorityType.SUPERADMIN) ?
                            'training_offer.replace_manually_added_teacher' : undefined,
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'training_offer.activity_availability_years',
                        colonnaKey: 'anniDisponibilita',
                        flex: 10,
                    },
                    {
                        nomeColonna: 'common.detail',
                        colonnaKey: 'descrizione',
                        hideColonna: !this.showArchived,
                        flex: 8,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: false,
                pageSizeOptions: [10, 20, 30],
                pageSize: 10,
                css: {
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_educational_offer',
            }
        };
        if (!this.showArchived) {
            config.configuration.configurazioneTabella.push(
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'azioni',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.MODIFICA_ATTIVITA_OFFERTA_FORMATIVA,
                            tooltip: 'training_offer.edit_activity',
                            color: 'accent',
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            valueShow: 'denominazione'
                        },
                        {
                            nameIconButton: 'north_east',
                            click: TipoClickEnum.SPOSTA_ATTIVITA_OFFERTA_FORMATIVA,
                            tooltip: 'training_offer.move_activity',
                            color: 'accent',
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            valueShow: 'denominazione'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.RIMUOVI_ATTIVITA_OFFERTA_FORMATIVA,
                            tooltip: 'training_offer.remove_activity',
                            color: 'accent',
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            valueShow: 'denominazione'
                        },
                    ]
                },
            );
        } else {
            config.configuration.configurazioneTabella.push(
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'training_offer.archive_date',
                    colonnaKey: 'dataArchiviazioneFormatted',
                    flex: 10,
                },
            );
        }
        return config;
    }

    createTableConfigurationForExtraActivities(inputList?: CategoriaOffertaFormativaInfoView): GenericTableConfigurationModel {
        let list = inputList.attivitaCategoriaAssociations?.map(item => ({
            ...item,
            denominazione: item.attivitaOffertaFormativa.denominazione,
            cfu: item.attivitaOffertaFormativa.cfu,
            ore: item.attivitaOffertaFormativa.ore,
            descrizione: item.attivitaOffertaFormativa.descrizione,
            curriculumRiferimento: this.createPercorsoDiStudiListString(item?.attivitaOffertaFormativa?.percorsiDiStudi),
            isObbligatorio: item.attivitaOffertaFormativa.isObbligatorio,
            periodoErogazione: item.attivitaOffertaFormativa.periodoErogazione,
            verificaFinale: item.attivitaOffertaFormativa.verificaFinale,
            organizzatoriNomeCognomeSplitted: item?.attivitaOffertaFormativa?.organizzatori?.map(org => ({
                cognome: org.split(' ')[0],
                nome: org.split(' ')[1],
            })) || [],
            dataArchiviazioneFormatted: (item.attivitaOffertaFormativa.dataArchiviazione ?? item.dataArchiviazione) ? moment(item.attivitaOffertaFormativa.dataArchiviazione ?? item.dataArchiviazione).format('DD/MM/YYYY') : '',
            anniDisponibilita: this.getActivityYearsFormatted(item)
        }))
        list = sortBy(list, [
            attivita => trim(attivita?.attivitaOffertaFormativa?.denominazione?.toLowerCase())
        ], ['asc']);
        const config: GenericTableConfigurationModel = {
            configuration: {
                data: list,
                totalElements: list.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.activity_denomination',
                        colonnaKey: 'denominazione',
                        flex: 35,
                    },
                    {
                        tipo: TipoColonnaEnum.NUMBER,
                        nomeColonna: 'common.cfu',
                        colonnaKey: 'cfu',
                        flex: 5,
                    },
                    {
                        tipo: TipoColonnaEnum.NUMBER,
                        nomeColonna: 'total_hour_table.column_total_hour',
                        colonnaKey: 'ore',
                        flex: 3,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.disbursement_period',
                        colonnaKey: 'periodoErogazione',
                        flex: 13,
                        hideColonna: true
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.reference_curriculum',
                        colonnaKey: 'curriculumRiferimento',
                        hideColonna: true,
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.ssd',
                        colonnaKey: 'ssd',
                        flex: 8,
                        hideColonna: true,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.final_check',
                        colonnaKey: 'verificaFinale',
                        flex: 5,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                        hideColonna: true,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.mandatory',
                        colonnaKey: 'isObbligatorio',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.organizer',
                        colonnaKey: 'organizzatoriNomeCognomeSplitted',
                        flex: 22,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.organizational_authority',
                        colonnaKey: 'enteOrganizzativo',
                        flex: 9,
                        hideColonna: true,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'training_offer.activity_availability_years',
                        colonnaKey: 'anniDisponibilita',
                        flex: 10,
                    },
                    {
                        nomeColonna: 'common.detail',
                        colonnaKey: 'descrizione',
                        hideColonna: !this.showArchived,
                        flex: 8,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: false,
                pageSizeOptions: [10, 20, 30],
                pageSize: 10,
                css: {
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_educational_offer'
            }
        };
        if (!this.showArchived) {
            config.configuration.configurazioneTabella.push(
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'azioni',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.MODIFICA_ATTIVITA_OFFERTA_FORMATIVA,
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            tooltip: 'training_offer.edit_activity',
                            color: 'accent',
                            valueShow: 'denominazione'
                        },
                        {
                            nameIconButton: 'north_east',
                            click: TipoClickEnum.SPOSTA_ATTIVITA_OFFERTA_FORMATIVA,
                            tooltip: 'training_offer.move_activity',
                            color: 'accent',
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            valueShow: 'denominazione'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.RIMUOVI_ATTIVITA_OFFERTA_FORMATIVA,
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            tooltip: 'training_offer.remove_activity',
                            color: 'accent',
                            valueShow: 'denominazione'
                        },
                    ]
                },
            );
        } else {
            config.configuration.configurazioneTabella.push(
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'training_offer.archive_date',
                    colonnaKey: 'dataArchiviazioneFormatted',
                    flex: 10,
                },
            );
        }
        return config;
    }

    tableClickAction(event: ClickEvent & {
        category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI
    }): void {
        console.log(event);
        switch (event.tipoClick) {
            case TipoClickEnum.CHIP_DETAIL:
                if (event?.value?.highlightChip) {
                    if (this.ctrlSelectFormControl?.value === AuthorityType.COORDINATORE || this.currentRuolo === AuthorityType.SUPERADMIN) {
                        this.openReplaceExtraDialog(event.value, event.rowValue);
                    }
                } else {
                    this.openInfoMember(event.value);
                }
                break;
            case TipoClickEnum.SHOW:
                this.openDetailsDialog(event?.value);
                break;
            case TipoClickEnum.RIMUOVI_ATTIVITA_OFFERTA_FORMATIVA:
                this.openRemoveActivityDialog(event.value, event.category);
                break;
            case TipoClickEnum.MODIFICA_ATTIVITA_OFFERTA_FORMATIVA:
                this.editAttivita(event.value, event.category);
                break;
            case TipoClickEnum.SPOSTA_ATTIVITA_OFFERTA_FORMATIVA:
                this.openMoveAttivitaDialog(event.value, event.category);
                break;
            default:
                return;
        }
    }

    openInfoMember(user: UserCodiceFiscaleNomeCognomeView): void {
        let userResponse: UserRoleBasedInfoView;
        let imageResponse: SafeUrl;
        this.fuseConfirmationService.showLoader();
        this.usersService.getUserInfoRoleBased(user?.codiceFiscale).pipe(
            tap((value: UserRoleBasedInfoView) => userResponse = value),
            switchMap((user: UserRoleBasedInfoView) => {
                    if (!!user.urlImmagineProfiloThumb && !!user.codiceFiscale) {
                        return this.mediaService.getImmagineDocenteOrPtaForm(user.urlImmagineProfiloThumb, user.codiceFiscale).pipe(
                            tap((image) => {
                                const objectURL = URL.createObjectURL(image);
                                imageResponse = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                            })
                        );
                    } else {
                        return of(user);
                    }
                }
            ),
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: () => {
                const data: DialogInfoI = this.buildDataForDIalog(userResponse, imageResponse);
                this.dialog.open(DialogInfoComponent, {
                    data,
                    panelClass: 'dialog-responsive-full-screen',
                    // scrollStrategy: new NoopScrollStrategy()
                });
            },
            error: (error) => {
                this.fuseConfirmationService.openErrorDialog({error: error},
                    this._translocoService,
                    () => {
                    },
                    () => this.openInfoMember(user),
                    'dialog.close',
                    error?.error?.message);
            }
        });
    }

    buildDataForDIalog(user?: UserRoleBasedInfoView, image?: SafeUrl): DialogInfoI {
        return {
            imageSafeUrl: image,
            showImage: true,
            title: (user.cognome || '') + ' ' + (user.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...docente(user, this._translocoService.getTranslation().get(this._translocoService.getActiveLang()), this.cicloConfigurationService)
        };
    }

    openDetailsDialog(activity: AttivitaCategoriaAssociationInfoView): void {
        const data: DialogInfoI = this.buildInfoAggiuntiveForDIalog(activity);
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            scrollStrategy: new NoopScrollStrategy()
        });
    }

    buildInfoAggiuntiveForDIalog(corso: AttivitaCategoriaAssociationInfoView): DialogInfoI {
        return {
            showImage: false,
            title: corso?.attivitaOffertaFormativa?.denominazione ?? corso?.attivitaOffertaFormativa?.descrizione,
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...this.infoAggiuntiveCorso(corso)
        };
    }

    infoAggiuntiveCorso = (corso: AttivitaCategoriaAssociationInfoView) => {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const yesString = get(translation, 'common.yes', null);
        const noString = get(translation, 'common.no', null);
        const data = {
            readonlyData: [
                {
                    key: 'cycle_doctorate.ssd',
                    value: corso?.attivitaOffertaFormativa?.ssd,
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.CORSO,
                },
                {
                    key: 'total_hour_table.column_total_hour',
                    value: corso?.attivitaOffertaFormativa?.ore
                },
                {
                    key: 'common.cfu',
                    value: corso?.attivitaOffertaFormativa?.cfu
                },
                {
                    key: 'requests.description_CFU',
                    value: corso?.attivitaOffertaFormativa?.descrizioneCfu,
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'cycle_doctorate.disbursement_period',
                    value: corso?.attivitaOffertaFormativa?.periodoErogazione,
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.CORSO,
                },
                {
                    key: 'common.description',
                    value: corso?.attivitaOffertaFormativa?.descrizione,
                    hide: !corso?.attivitaOffertaFormativa?.descrizione
                },
                {
                    key: 'common.note',
                    value: corso?.attivitaOffertaFormativa?.note,
                    hide: !corso?.attivitaOffertaFormativa?.note
                },
                {
                    key: 'cycle_doctorate.final_check',
                    value: corso?.attivitaOffertaFormativa?.verificaFinale ? yesString : noString,
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.CORSO,
                },
                {
                    key: 'cycle_doctorate.mandatory',
                    value: corso?.attivitaOffertaFormativa?.isObbligatorio ? yesString : noString,
                },
                {
                    key: 'cycle_doctorate.student_document',
                    value: corso?.attivitaOffertaFormativa?.tipoDocumentoDottorando || '-',
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'cycle_doctorate.supervisor_document',
                    value: corso?.attivitaOffertaFormativa?.tipoDocumentoSupervisore || '-',
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
                {
                    key: 'cycle_doctorate.coordinator_required',
                    value: corso?.attivitaOffertaFormativa?.presenzaDeliberaCoordinatore ? yesString : noString,
                    hide: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
                },
            ],
            bottomComponent: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ? CurriculumAccordionComponent : undefined,
            bottomComponentData: corso?.attivitaOffertaFormativa?.tipo !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ? corso?.attivitaOffertaFormativa.percorsiDiStudi : undefined,
            bottomComponentLabel: 'cycle_doctorate.reference_curriculum'
        };
        return data;
    };


    openEditGuidaCoordinatoreDialog(language: LingueEnum) {
        const data: DialoEditGuidaCoordinatoreI = {
            title: 'training_offer.edit_guida_coordinatore',
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            onEditPressed: (form, dialogRef) => this.editGuidaCoordinatoreRequest(form, dialogRef, language),
            currentGuidaCoordinatore: this.getGuidaCoordinatoreByLang(language),
            isRequired: language === LingueEnum.IT
        };
        this.dialog.open(DialogEditGuidaCoordinatoreComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            scrollStrategy: new NoopScrollStrategy(),
        });
    }

    private editGuidaCoordinatoreRequest(form: FormGroup, dialogRef: MatDialogRef<any>, language: LingueEnum) {
        this.fuseConfirmationService.showLoader();
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const guidaCoordinatoreString = form?.getRawValue()?.guidaCoordinatore;
        const guidaCoordinatoreBody: UpdateGuidaCoordinatoreOffertaFormativaLinguaDTO = {
            lingua: language,
            valore: guidaCoordinatoreString || ''
        };
        form.disable();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.updateGuidaCoordinatoreLinguaOffertaFormativa(guidaCoordinatoreBody)),
            takeUntil(this.destroy$),
            finalize(() => {
                form.enable();
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                this.offertaFormativa = offertaFormativaInfo;
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'training_offer.guida_coordinatore_saved', null),
                    type: SnackbarTypes.Success,
                });
                this.canClone = offertaFormativaInfo?.isPossibileClonareOffertaFormativa;
                this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
                this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
                this.setOffertaFormativaInfoViewLists(offertaFormativaInfo);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'training_offer.error_saving_guida_coordinatore', null),
                    error: err, type: SnackbarTypes.Error,
                });
            }
        });
    }

    createPercorsoDiStudiListString(percorsiDiStudi: Array<DatiPercorsoDiStudi> | undefined) {
        return percorsiDiStudi?.map(c => c.descrizionePercorsoDiStudi).join(', ').toUpperCase();
    }

    getSottoruoliAndOfferRequest(): void {
        this.loading = true;
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap(value => this.currentRuolo = value?.ruolo),
            tap((value: CicloCorsoRuoloInterface) => this.init(value)),
            switchMap((value) => {
                if (!this.appInitService.isAreaAdministrator && this.isDocenteOrPersonalePtaOrEsterno(this.currentRuolo)) {
                    return this.appInitService.currentSottoruoliUtente$.pipe(first());
                } else {
                    return of(null);
                }
            }),
            tap((sottoruoli: AuthorityType[]) => {
                if (!!sottoruoli) {
                    this.sottoruoli = sottoruoli;
                    this.setCurrentSottoruolo();
                    this.showSelect = this.isDocenteOrPersonalePtaOrEsterno(this.currentRuolo)
                        && !this.isDocenteWithoutSottoruolo();
                }
                if (this.appInitService.isAreaAdministrator) {
                    this.setCorsoAmm();
                }
            }),
            switchMap(() => this.timeService.updateCurrentTime()),
            // switchMap(() => {
            //     if (this.appInitService.isAreaAdministrator && this.appInitService.codiceCorsoStudiAmmSelected) {
            //         return this.cicloConfigurationService.getCicloCorsoConfigurationInAmministrazione(
            //             this.appInitService.cicloAmmSelected, this.appInitService.codiceCorsoStudiAmmSelected
            //         );
            //     } else {
            //         return of(undefined);
            //     }
            // }),
            switchMap(() => {
                if (this.appInitService.isAreaAdministrator) {
                    return this.ctrlCorso.value ? this.offerteFormativeService.getOffertaFormativa() : of(undefined);
                } else {
                    return this.offerteFormativeService.getOffertaFormativa();
                }
            }),
            finalize(() => this.loading = false),
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value: OffertaFormativaInfoViewImpl) => {
                    this.offertaFormativa = value;
                    this.canClone = value?.isPossibileClonareOffertaFormativa;
                    this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
                    this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
                    if (value) {
                        this.setOffertaFormativaInfoViewLists(value);
                    }
                },
                error: (err) => {
                    this.loading = false;
                    this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                        () => this.logOutService.goToHome(),
                        () => this.getSottoruoliAndOfferRequest(),
                        'common.go_to_home',
                        err?.error?.message);
                }
            }
        );
    }

    private setCorsoAmm() {
        this.corsi = this.appInitService.getCorsiAmm();
        this.isReadOnly = true;
        if (this.corsi.some(c => c.codiceEsse3 === this.appInitService.codiceCorsoStudiAmmSelected)) {
            this.ctrlCorso.setValue(this.appInitService.codiceCorsoStudiAmmSelected);
        } else if (this.corsi?.length === 1) {
            this.ctrlCorso.setValue(this.corsi[0].codiceEsse3);
            if (this.appInitService.isAreaAdministrator) {
                this.appInitService.setCodiceCorsoStudiAmm(this.ctrlCorso.getRawValue());
            }
        }
        this.initAmm();
    }

    isDocenteOrPersonalePtaOrEsterno(ruolo?: AuthorityType): boolean {
        return (ruolo === AuthorityType.DOCENTE || ruolo === AuthorityType.PERSONALEPTA || ruolo === AuthorityType.ESTERNO);
    }

    setCurrentSottoruolo(): void {
        const savedSottoruolo: AuthorityType = this.localStorageService?.dipartimentoRuoloCiclo?.sottoruolo;
        if (!!savedSottoruolo) {
            const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
            if (findedSottoruolo) {
                this.ctrlSelectFormControl.setValue(savedSottoruolo, {emitEvent: false});
            } else {
                this.ctrlSelectFormControl.setValue(head(this.sottoruoli), {emitEvent: false});
            }
        } else {
            this.ctrlSelectFormControl.setValue(head(this.sottoruoli), {emitEvent: false});
        }
        this.localStorageService.setSottoruoloCiclo(this.ctrlSelectFormControl?.value)
        this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
        this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
        this.showEditGuidaCoordinatoreButton = (this.ctrlSelectFormControl?.value === AuthorityType.COORDINATORE || this.ctrlSelectFormControl?.value === AuthorityType.GRUPPODICOORDINAMENTO || this.currentRuolo === AuthorityType.SUPERADMIN);
        this.appInitService.setCicloCorsoRuoloSelected$(this.appInitService.cicloCorsoRuoloSelected).subscribe();
    }

    isDocenteWithoutSottoruolo(): boolean {
        return (this.localStorageService.getCicloCorsoRuolo()?.ruolo === AuthorityType.DOCENTE
            && isEmpty(this.sottoruoli));
    }

    private subscribeToSottoruoliValueChange() {
        this.ctrlSelectFormControl.valueChanges.pipe(
            tap((value) => {
                this.localStorageService.setSottoruoloCiclo(value);
                this.appInitService.setCicloCorsoRuoloSelected$(this.appInitService.cicloCorsoRuoloSelected).subscribe();
            }),
        ).subscribe((sottoruolo) => {
            this.showEditGuidaCoordinatoreButton = (this.currentRuolo === AuthorityType.DOCENTE &&
                (sottoruolo === AuthorityType.COORDINATORE || sottoruolo === AuthorityType.GRUPPODICOORDINAMENTO || this.currentRuolo === AuthorityType.SUPERADMIN));
            this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(sottoruolo, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
            this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
            this.setOffertaFormativaInfoViewLists(this.offertaFormativa);
        });
    }

    createTableConfigurationForTransversalActivities(inputList?: CategoriaOffertaFormativaInfoView): GenericTableConfigurationModel {
        let list = inputList?.attivitaCategoriaAssociations?.map(item => ({
            ...item,
            denominazione: item.attivitaOffertaFormativa.denominazione,
            cfu: item.attivitaOffertaFormativa.cfu,
            ore: item.attivitaOffertaFormativa.ore,
            descrizione: item.attivitaOffertaFormativa.descrizione,
            curriculumRiferimento: this.createPercorsoDiStudiListString(item?.attivitaOffertaFormativa?.percorsiDiStudi),
            cfuOrDescCFU: item?.attivitaOffertaFormativa?.cfu || item?.attivitaOffertaFormativa?.descrizioneCfu,
            tipoDocumentoDottorandoFormatted: item?.attivitaOffertaFormativa?.tipoDocumentoDottorando || '-',
            tipoDocumentoSupervisoreFormatted: item?.attivitaOffertaFormativa?.tipoDocumentoSupervisore || '-',
            presenzaDeliberaCoordinatore: item?.attivitaOffertaFormativa?.presenzaDeliberaCoordinatore,
            pre: item?.attivitaOffertaFormativa?.tipoDocumentoSupervisore || '-',
            dataArchiviazioneFormatted: (item.attivitaOffertaFormativa.dataArchiviazione ?? item.dataArchiviazione) ? moment(item.attivitaOffertaFormativa.dataArchiviazione ?? item.dataArchiviazione).format('DD/MM/YYYY') : '',
            isObbligatorio: item.attivitaOffertaFormativa.isObbligatorio,
            anniDisponibilita: this.getActivityYearsFormatted(item)
        }));
        list = sortBy(list, [
            attivita => trim(attivita?.attivitaOffertaFormativa?.denominazione?.toLowerCase())
        ], ['asc']);
        const config: GenericTableConfigurationModel = {
            configuration: {
                data: list,
                totalElements: list.length,
                isPaginatedBE: false,
                sticky: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.activity_typology',
                        colonnaKey: 'denominazione',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'dialog_add_edit_categories.is_desc_cfu',
                        colonnaKey: 'cfuOrDescCFU',
                        flex: 10,
                    },
                    /*                    {
                                            tipo: TipoColonnaEnum.BOOLEAN,
                                            nomeColonna: 'cycle_doctorate.student_document_required',
                                            colonnaKey: 'presenzaDocumentoDottorando',
                                            convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                                            flex: 8,
                                        },*/
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.student_document',
                        colonnaKey: 'tipoDocumentoDottorandoFormatted',
                        flex: 18,
                    },
                    /*                    {
                                            tipo: TipoColonnaEnum.BOOLEAN,
                                            nomeColonna: 'cycle_doctorate.supervisor_document_required',
                                            colonnaKey: 'presenzaDocumentoSupervisore',
                                            convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                                            flex: 8,
                                        },*/
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.supervisor_document',
                        colonnaKey: 'tipoDocumentoSupervisoreFormatted',
                        flex: 18,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.coordinator_required',
                        colonnaKey: 'presenzaDeliberaCoordinatore',
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                        flex: 8,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.mandatory',
                        colonnaKey: 'isObbligatorio',
                        flex: 8,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    /*                    {
                                            tipo: TipoColonnaEnum.BOOLEAN,
                                            nomeColonna: 'cycle_doctorate.mandatory_for_each_year',
                                            colonnaKey: 'isObbligatorioPerOgniAnno',
                                            flex: 5,
                                            convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                                        },*/
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'training_offer.activity_availability_years',
                        colonnaKey: 'anniDisponibilita',
                        flex: 10,
                    },
                    {
                        nomeColonna: 'common.detail',
                        colonnaKey: 'descrizione',
                        hideColonna: !this.showArchived,
                        flex: 8,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: false,
                pageSizeOptions: [10, 20, 30],
                pageSize: 10,
                css: {
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_educational_offer'
            }
        };
        if (!this.showArchived) {
            config.configuration.configurazioneTabella.push(
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'azioni',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.show_detail',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.MODIFICA_ATTIVITA_OFFERTA_FORMATIVA,
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            tooltip: 'training_offer.edit_activity',
                            color: 'accent',
                            valueShow: 'denominazione'
                        },
                        {
                            nameIconButton: 'north_east',
                            click: TipoClickEnum.SPOSTA_ATTIVITA_OFFERTA_FORMATIVA,
                            tooltip: 'training_offer.move_activity',
                            color: 'accent',
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            valueShow: 'denominazione'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.RIMUOVI_ATTIVITA_OFFERTA_FORMATIVA,
                            show: (value, element) => this.showEditCategoriesAndActivities,
                            tooltip: 'training_offer.remove_activity',
                            color: 'accent',
                            valueShow: 'denominazione'
                        },
                    ]
                },
            );
        } else {
            config.configuration.configurazioneTabella.push(
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'training_offer.archive_date',
                    colonnaKey: 'dataArchiviazioneFormatted',
                    flex: 10,
                },
            );
        }
        return config;
    }

    manageActivity(category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI, type: ElementoOffertaFormativaType, attivitaDaModificare?: AttivitaCategoriaAssociationInfoView) {
        console.log(category, 'category');
        const data: DialogManageActivityI = {
            title: this.getTitleManageActivity(attivitaDaModificare, type),
            subtitle: category.denominazione + (attivitaDaModificare ? ' - ' + attivitaDaModificare.attivitaOffertaFormativa.denominazione : ''),
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            close: true,
            annoInsegnamento: category.year,
            activityToEdit: attivitaDaModificare,
            idCategoria: category.id,
            isOffertaPubblica: this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA,
            editGlobalTopAlertTextLabel: (attivitaDaModificare && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA)
                ? 'training_offer.edit_impact_on_public_offer_global' : undefined,
            editYearsTopAlertTextLabel: (attivitaDaModificare && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA)
                ? 'training_offer.edit_impact_on_public_offer_years' : undefined,
            type,
            category,
            filteredCourses: attivitaDaModificare ? [] : this.allCourses?.filter(course =>
                category.tipoAttivitaContenute === course.tipo
                && !course?.isAssociazioneArchiviata
                && !course?.isArchiviato
                && !category.attivitaCategoriaAssociations
                    ?.some(a => a.attivitaOffertaFormativa.id === course.id)),
            trainingOffer: this.offertaFormativa
        };
        const dialogRef = this.dialog.open(DialogManageActivityComponent, {
            data: data,
            panelClass: 'dialog-responsive-xl',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw',
            maxHeight: '100vh',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe((value: OffertaFormativaInfoViewImpl) => {
            if (value) {
                this.offertaFormativa = value;
                this.canClone = value?.isPossibileClonareOffertaFormativa;
                this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
                this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
                this.setOffertaFormativaInfoViewLists(value);
            }
        });
    }

    openMoveAttivitaDialog(actToMove: AttivitaCategoriaAssociationInfoView, category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI,) {
        const data: DialogMoveActivityI = {
            title: 'training_offer.move_activity',
            subtitle: category.denominazione + (actToMove ? ' - ' + actToMove.attivitaOffertaFormativa.denominazione : ''),
            icon: {
                name: 'mat_outline:north_east',
                color: 'primary'
            },
            close: true,
            annoInsegnamento: category.year,
            actToMove: actToMove,
            idCategoria: category.id,
            isOffertaPubblica: this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA,
            category,
            trainingOffer: this.offertaFormativa,
            filteredCategories: this.offertaFormativa?.categorie?.filter(c => !c.isArchiviata
                && c.id !== category.id
                && c.tipoAttivitaContenute === category.tipoAttivitaContenute
                && !c.attivitaCategoriaAssociations?.find(ass => ass.attivitaOffertaFormativa?.id === actToMove?.attivitaOffertaFormativa?.id && !ass.isArchiviato))
        };
        const dialogRef = this.dialog.open(DialogMoveActivityComponent, {
            data: data,
            panelClass: 'dialog-responsive-xl',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw',
            maxHeight: '100vh',
            disableClose: true
        });
        dialogRef.afterClosed().subscribe((value: OffertaFormativaInfoViewImpl) => {
            if (value) {
                this.offertaFormativa = value;
                this.canClone = value?.isPossibileClonareOffertaFormativa;
                this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
                this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
                this.setOffertaFormativaInfoViewLists(value);
            }
        });
    }

    private getTitleManageActivity(attivitaDaModificare: AttivitaOffertaFormativaInfoView, type: ElementoOffertaFormativaType) {
        switch (type) {
            case ElementoOffertaFormativaType.CORSO:
                return !attivitaDaModificare ? 'training_offer.add_course' : 'training_offer.edit_course';
            case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                return !attivitaDaModificare ? 'training_offer.add_extra_activity' : 'training_offer.edit_extra_activity';
            case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                return !attivitaDaModificare ? 'training_offer.add_transversal_activity' : 'training_offer.edit_transversal_activity';
        }

    }

    /*    aggiungiAttivitaExtra(annoInsegnamento: string, attivitaDaModificare?: AttivitaOffertaFormativaInfoView) {
            const data: DialogAddExtraActivityI = {
                title: !attivitaDaModificare ? 'training_offer.add_extra_activity' : 'training_offer.edit_extra_activity',
                icon: {
                    name: 'mat_outline:assignment',
                    color: 'primary'
                },
                annoInsegnamento: annoInsegnamento,
                activityToEdit: attivitaDaModificare,
                topAlertTextLabel: (attivitaDaModificare && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA)
                    ? 'training_offer.edit_impact_on_public_offer' : undefined,
            };
            const dialogRef = this.dialog.open(DialogAddExtraActivityComponent, {
                data: data,
                panelClass: 'dialog-responsive-full-screen',
                hasBackdrop: true,
                autoFocus: false
            });
            dialogRef.afterClosed().subscribe((value) => {
                if (value) {
                    this.offertaFormativa = value;
                    this.showButtonModificaCorsiAttivita = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo);
                    this.setOffertaFormativaInfoViewLists(value);
                    this.activityTabSelectedIndex = 1;
                }
            });
        }*/

    /*    aggiungiAttivitaTrasversale(annoInsegnamento: string, attivitaDaModificare?: AttivitaOffertaFormativaInfoView) {
            const data: DialogAddTransversalActivityI = {
                title: !attivitaDaModificare ? 'training_offer.add_transversal_activity' : 'training_offer.edit_transversal_activity',
                icon: {
                    name: 'mat_outline:assignment',
                    color: 'primary'
                },
                annoInsegnamento: annoInsegnamento,
                activityToEdit: attivitaDaModificare,
                topAlertTextLabel: (attivitaDaModificare && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA)
                    ? 'training_offer.edit_impact_on_public_offer' : undefined,
            };
            const dialogRef = this.dialog.open(DialogAddTransversalActivityComponent, {
                data: data,
                panelClass: 'dialog-responsive-full-screen',
                hasBackdrop: true,
                autoFocus: false
            });
            dialogRef.afterClosed().subscribe((value) => {
                if (value) {
                    this.offertaFormativa = value;
                    this.showButtonModificaCorsiAttivita = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo);
                    this.setOffertaFormativaInfoViewLists(value);
                    this.activityTabSelectedIndex = 2;
                }
            });
        }*/

    private openRemoveActivityDialog(activity: AttivitaCategoriaAssociationInfoView, category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI) {
        console.log(activity);
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'training_offer.remove_activity', null),
                message: get(translation, 'training_offer.remove_activity_question_message', null),
                subtitle: category.denominazione + (activity ? ' - ' + activity.attivitaOffertaFormativa.denominazione : ''),
                icon: {
                    show: true,
                    name: 'delete',
                    color: 'primary'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                alert: this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA ? {
                    type: 'warning',
                    message: get(translation, 'training_offer.remove_impact_on_public_offer', null)
                } : undefined,
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        function: () => this.removeActivityRequest(category, activity)
                    }]
            }
        );
    }

    private removeActivityRequest(category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI, activity: AttivitaCategoriaAssociationInfoView, confirm?: boolean) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.offerteFormativeService.deleteAttivitaACategoriaOffertaFormativa(category.id, activity.attivitaOffertaFormativa.id, confirm).pipe(
            takeUntil(this.destroy$),
        ).subscribe({
            next: (offertaInformativaInfoView: OffertaFormativaInfoViewImpl) => {
                // updating the ui
                this.offertaFormativa = offertaInformativaInfoView;
                this.canClone = offertaInformativaInfoView?.isPossibileClonareOffertaFormativa;
                this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
                this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
                this.setOffertaFormativaInfoViewLists(offertaInformativaInfoView);
                this.fuseConfirmationService.hideLoader();
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'training_offer.activity_removed', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.hideLoader();
                if (err?.status === 409 && err?.error?.code === 1035) {
                    this.openErrorConfirmRemoveActivity(category, activity, err?.error?.message);
                } else {
                    this.fuseConfirmationService.openErrorDialog(
                        {error: err},
                        this._translocoService,
                        () => {
                        },
                        () => this.removeActivityRequest(category, activity, confirm),
                        'dialog.cancel',
                        err?.error?.message
                    );
                }
            }
        });
    }

    openErrorConfirmRemoveActivity(category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI, activity: AttivitaCategoriaAssociationInfoView, message?: string) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                //title: get(translation, 'training_offer.archive_activity', null),
                message: message,// || get(translation, 'training_offer.archive_activity_question_message', null),
                subtitle: category.denominazione + (activity ? ' - ' + activity.attivitaOffertaFormativa.denominazione : ''),
                icon: {
                    show: true,
                    name: 'delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        function: () => this.removeActivityRequest(category, activity, true)
                    }]
            }
        );
    }

    onArchivedToggleChange($event: MatSlideToggleChange) {
        this.showArchived = $event?.checked;
        this.trainingOfferService.isShowingArchived = this.showArchived;
        this.setOffertaFormativaInfoViewLists(this.offertaFormativa);
    }

    protected readonly ElementoOffertaFormativaType = ElementoOffertaFormativaType;

    private editAttivita(attivita: AttivitaCategoriaAssociationInfoView, category: CategoriaOffertaFormativaInfoView & TrainingOfferCategoryDataUI) {
        this.manageActivity(category, attivita.attivitaOffertaFormativa.tipo, attivita);
    }

    protected readonly LingueEnum = LingueEnum;
    languages = Object.values(LingueEnum);


    getGuidaCoordinatoreByLang(language: LingueEnum): string {
        return this.guidaCoordinatore?.find(info => info.lingua === language)?.valore;
    }


    openReplaceExtraDialog(docenteExtra: any, corso: AttivitaCategoriaAssociationInfoView) {
        const data: DialogTableMultiSelectConfiguration = {
            title: 'training_offer.replace_manually_added_teacher',
            subtitle: docenteExtra.cognome + ' ' + docenteExtra.nome,
            icon: {
                name: 'mat_outline:person_add',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.replaceExtraRequest(tableData, dialogRef, docenteExtra, corso, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'common.professor'
            },
            righeSelezionate: undefined,
            isSingleChoiceSelection: true,
            //showSelectToSetDataTableByRole: true,
            rolesToFetch: this.cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.GestioneEsterni)?.stato === FunzionalitaStatus.ABILITATA
                ? [RuoloEnum.DOCENTE, RuoloEnum.ESTERNO] : [RuoloEnum.DOCENTE],
            infoMessage: 'training_offer.replace_manually_added_teacher_message',
            showInvitaEsterni: true,
            showAggiungiDocenteManualmente: false
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    private replaceExtraRequest(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, docenteExtra: any, corso: AttivitaCategoriaAssociationInfoView, loading$: BehaviorSubject<boolean>) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const successMessage = 'training_offer.activity_edited';
        const docentiExtra = cloneDeep(corso?.attivitaOffertaFormativa?.docentiExtraOffertaFormativa);
        const docenteExtraIndex = docentiExtra?.findIndex(d => d.id === docenteExtra.id);
        docentiExtra.splice(docenteExtraIndex, 1);
        loading$.next(true);
        const editCorsoBody: UpdateCorsoOffertaFormativaPubblicaDTO = {
            codici_fiscali_docenti: [...corso?.attivitaOffertaFormativa?.docenti?.map(d => d.codiceFiscale), tableData.selectedRows[0]?.data?.codiceFiscale],
            docenti_extra: docentiExtra?.map(d => ({nome: d.nome, cognome: d.cognome})),
        };
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.updateDocentiInCorsoOffertaFormativa(editCorsoBody, corso.attivitaOffertaFormativa?.id)),
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
            next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                this.offertaFormativa = offertaFormativaInfo;
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, successMessage, null),
                    type: SnackbarTypes.Success,
                });
                this.canClone = offertaFormativaInfo?.isPossibileClonareOffertaFormativa;
                this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
                this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
                this.setOffertaFormativaInfoViewLists(offertaFormativaInfo);
                this.activityTabSelectedIndex = 0;
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => {
                    },
                    () => this.replaceExtraRequest(tableData, dialogRef, docenteExtra, corso, loading$),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    updateOffertaFormativaByConfiguration(offertaFormativaUpdated: any) {
        this.offertaFormativa = offertaFormativaUpdated;
        this.canClone = offertaFormativaUpdated?.isPossibileClonareOffertaFormativa;
        this.showEditCategoriesAndActivities = showButtonModificaAttivitaCondition(this.ctrlSelectFormControl?.value, this.currentRuolo, this.appInitService.isAreaAdministrator, this.appInitService.getTheMajorRole);
        this.trainingOfferService.canEditOffer = this.showEditCategoriesAndActivities;
        this.computeCategoriesCompliance();
    }

    sortRigheSelezionate(list: TableDataRows[]): TableDataRows[] {
        return sortBy(list, [function (o) {
            return o.data?.nomeCognome;
        }]);
    }

    setCurrentCorso(codiceCorsoStudiAmm: string): void {
        this.appInitService.setCodiceCorsoStudiAmm(codiceCorsoStudiAmm);
        this.loading = true;
        // const obs = (this.appInitService.isAreaAdministrator && this.appInitService.codiceCorsoStudiAmmSelected) ?
        //     this.cicloConfigurationService.getCicloCorsoConfigurationInAmministrazione(
        //         this.appInitService.cicloAmmSelected, this.appInitService.codiceCorsoStudiAmmSelected
        //     ) : of(undefined);
        this.offerteFormativeService.getOffertaFormativa().pipe(
            finalize(() => this.loading = false),
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value) => {
                    this.initAmm();
                    this.offertaFormativa = value;
                    this.setOffertaFormativaInfoViewLists(value);
                },
                error: (err) => {
                    this.loading = false;
                    this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                        () => this.logOutService.goToHome(),
                        () => this.setCurrentCorso(codiceCorsoStudiAmm),
                        'common.go_to_home',
                        err?.error?.message);
                }
            }
        );
    }

    protected readonly AuthorityType = AuthorityType;
    trackByIndex = (index: number) => index;

    openModaleDownloadPdf(): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'training_offer.export_pdf', null),
                message: get(translation, 'training_offer.export_pdf_message', null),
                icon: {
                    name: 'mat_outline:info',
                    color: 'info'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.downloadPdfRequest()
                    }]
            }
        );

    }

    private downloadPdfRequest() {
        this.fuseConfirmationService.showLoader();
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.getPdfOffertaFormativaDaDeliberare('response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                fs.saveAs(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                handleErrorOnExportPDF(err,
                    () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, 'student.file_download_error', null),
                            type: SnackbarTypes.Warning,
                        });
                    },
                    (errorObject) => {
                        this.openErrorsListOnExportPDF(
                            errorObject?.message,
                            errorObject?.errors
                        );
                    },
                    (errorObject) => {
                        this.fuseConfirmationService.openErrorDialog({error: errorObject},
                            this._translocoService,
                            () => {
                            },
                            undefined,
                            'common.close',
                            errorObject?.message
                        );
                    });
                console.log(err);
            }
        });
    }

    private openErrorsListOnExportPDF(message?: string, errors?: string[]) {
        const data: DialogErrorsListI = {
            title: 'student.file_download_error',
            message: message || '',
            icon: {
                name: 'mat_solid:error_outline',
                color: 'error'
            },
            errors: errors
        };
        this.dialog.open(DialogErrorsListComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    isMenuOpen() {
        return this.fuseNavigationService?.getComponent<FuseVerticalNavigationComponent>('mainNavigation')?.opened;
    }

    openExportExcelDialog() {
        this.exportExcelRequest();
    }

    private exportExcelRequest() {
        this.fuseConfirmationService.showLoader();
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        try {
            let activities = this.offertaFormativa.categorie
                ?.flatMap(c => c.attivitaCategoriaAssociations
                    ?.filter(a => !a.isArchiviato && !a.attivitaOffertaFormativa?.isArchiviato)
                    ?.map(a => ({
                        ...a.attivitaOffertaFormativa,
                        categoryDenomination: c.denominazione,
                        // mapping year from category-activity association
                        primoAnno: a.primoAnno,
                        secondoAnno: a.secondoAnno,
                        terzoAnno: a.terzoAnno,
                    })));
            // corsi
            const corsiConfig = getExcelConfigurationsForCorsi(activities, translation);
            const corsiTopHeaderConfig = getExcelTopHeaderConfigurations(corsiConfig, translation);
            // extra
            const extraConfig = getExcelConfigurationsForExtra(activities, translation);
            const extraTopHeaderConfig = getExcelTopHeaderConfigurations(extraConfig, translation);
            // transversal
            const transversalConfig = getExcelConfigurationsForTransversal();
            const transversalTopHeaderConfig = getExcelTopHeaderConfigurations(transversalConfig, translation);
            // combining conf
            const excelConfig: ExcelConfig[] = [
                {
                    nameSheet: get(translation, 'common.didactic_activities', null),
                    data: mapAttivitaForExcel(activities, ElementoOffertaFormativaType.CORSO, translation),
                    config: corsiConfig,
                    configTopHeader: corsiTopHeaderConfig,
                },
                {
                    nameSheet: get(translation, 'student.extra_activities', null),
                    data: mapAttivitaForExcel(activities, ElementoOffertaFormativaType.ATTIVITAEXTRA, translation),
                    config: extraConfig,
                    configTopHeader: extraTopHeaderConfig,
                },
                {
                    nameSheet: get(translation, 'student.transversal_activities', null),
                    data: mapAttivitaForExcel(activities, ElementoOffertaFormativaType.ATTIVITATRASVERSALE, translation),
                    config: transversalConfig,
                    configTopHeader: transversalTopHeaderConfig,
                },
            ];
            this.excelService.generateExcel(excelConfig).pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                })
            ).subscribe({
                next: (excelFile: Blob) => {
                    const fileTypeForName = 'OFFERTA_FORMATIVA_EXPORT_EXCEL';
                    const outputFileName = makeFilenameFromFE(this.localStorageService.getCicloCorsoRuolo(), '.xlsx', fileTypeForName);
                    fs.saveAs(excelFile, outputFileName);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_success', null),
                        type: SnackbarTypes.Success,
                    });
                },
                error: (err) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_error', null),
                        error: err, type: SnackbarTypes.Error,
                    });
                    console.log(err);
                }
            });
        } catch (error) {
            this.fuseConfirmationService.hideLoader();
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'student.file_download_error', null),
                error: error,
                type: SnackbarTypes.Error,
            });
            console.log(error);
        }
    }

    openAddEditCategoryDialog(categoryToEdit: CategoriaOffertaFormativaInfoView, annoInsegnamento: string, wantsToEditConfig?: boolean, tipoAttivitaContenute?: ElementoOffertaFormativaType) {
        const data: TrainingOfferCategoryAddEditDialogI = {
            title: categoryToEdit ? 'training_offer.edit_category' : 'training_offer.add_category',
            subtitle: categoryToEdit ? categoryToEdit.denominazione : undefined,
            icon: {
                name: categoryToEdit ? 'mat_outline:edit' : 'mat_outline:add',
                color: 'info'
            },
            close: true,
            categoryToEdit: categoryToEdit, //true means editing
            annoInsegnamento: !categoryToEdit ? annoInsegnamento : undefined,
            activityType: !categoryToEdit ? tipoAttivitaContenute : categoryToEdit.tipoAttivitaContenute,
            trainingOffer: this.offertaFormativa,
            topAlertTextLabel: (categoryToEdit && this.offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA)
                ? 'training_offer.category_edit_impact_on_public_offer' : undefined,
            onConfirm: (denominazione, descrizione, note, configurazioni,
                        loading$, dialogRef) => !categoryToEdit ?
                this.addCategoryRequest(denominazione, descrizione, note, configurazioni, tipoAttivitaContenute, loading$, dialogRef)
                : this.editCategoryRequest(denominazione, descrizione, note, configurazioni, categoryToEdit, loading$, dialogRef),
            wantsToEditConfig: wantsToEditConfig,
        };
        this.dialog.open(TrainingOfferCategoryAddEditDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-xl',
            hasBackdrop: true,
            autoFocus: false,
            width: '90vw',
            maxHeight: '100vh',
            disableClose: true
        });
    }

    openRemoveCategoryDialog(category: TrainingOfferCategoryDataUI) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        if (category?.numberOfShowedActivitiesForTypeAndYear > 0) {
            this.fuseConfirmationService.open({
                title: get(translation, 'training_offer.remove_category', null),
                message: get(translation, 'training_offer.category_has_activities', null) + '.',
                subtitle: category.denominazione,
                icon: {
                    show: true,
                    name: 'delete',
                    color: 'warn'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            });
        } else {
            this.fuseConfirmationService.open({
                    title: get(translation, 'training_offer.remove_category', null),
                    message: get(translation, 'training_offer.remove_category_question_message', null) + ' ' + category.denominazione + '?',
                    subtitle: category.denominazione,
                    icon: {
                        show: true,
                        name: 'delete',
                        color: 'primary'
                    },
                    onBackdrop: {
                        show: false,
                        backdrop: true
                    },
                    actions: [
                        {
                            color: 'accent',
                            label: get(translation, 'common.close', null), icon: 'close',
                        },
                        {
                            color: 'primary',
                            label: get(translation, 'dialog.confirm', null), icon: 'check',
                            function: () => this.removeCategoryRequest(category.id)
                        }]
                }
            );
        }
    }

    private removeCategoryRequest(idCategory: string) {
        this.fuseConfirmationService.showLoader();
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.deleteCategoriaOffertaFormativa(idCategory)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (offertaFormativaUpdated: OffertaFormativaInfoViewImpl) => {
                this.offertaFormativa = offertaFormativaUpdated;
                this.setOffertaFormativaInfoViewLists(offertaFormativaUpdated);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                console.log(err);
                this.fuseConfirmationService.openErrorDialog(
                    {error: err},
                    this._translocoService,
                    () => {
                    },
                    () => this.removeCategoryRequest(idCategory),
                    'dialog.cancel',
                    err?.error?.message
                );
            }
        });
    }

    private addCategoryRequest(denominazione: string, descrizione: string, note: string, configurazioni: ConfigurazioneCategoriaPerAnni[],
                               activityType: ElementoOffertaFormativaType, loading$: BehaviorSubject<boolean>,
                               dialogRef1: MatDialogRef<TrainingOfferCategoryAddEditDialogComponent>,
                               dialogRef2?: MatDialogRef<DialogInvalidStudyPlansComponent>,
                               forced = false) {
        dialogRef2?.close();
        loading$.next(true);
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const requestBody: AddCategoriaOffertaFormativaDTO = {
            denominazione: denominazione,
            descrizione: descrizione,
            note: note,
            configurazioni: configurazioni,
            tipo_attivita_contenute: activityType,
            forced: forced,
        };
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.addCategoriaOffertaFormativa(requestBody)),
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
            next: (offertaFormativaUpdated: OffertaFormativaInfoViewImpl) => {
                this.offertaFormativa = offertaFormativaUpdated;
                this.setOffertaFormativaInfoViewLists(offertaFormativaUpdated);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
                dialogRef1.close();
            },
            error: (err) => {
                console.log(err);
                if (err.status === 409 && err?.error?.code == 1034) {
                    this.openInvalidStudyPlansDialog(requestBody, dialogRef1, err?.error?.utentiConPianoNonPiuValido, requestBody, loading$, activityType);
                } else {
                    this.fuseConfirmationService.openErrorDialog(
                        {error: err},
                        this._translocoService,
                        () => {
                        },
                        () => this.addCategoryRequest(denominazione, descrizione, note, configurazioni, activityType, loading$, dialogRef1),
                        'dialog.cancel',
                        err?.error?.message
                    );
                }
            }
        });
    }

    private editCategoryRequest(denominazione: string, descrizione: string, note: string, configurazioni: ConfigurazioneCategoriaPerAnni[],
                                categoryToEdit: CategoriaOffertaFormativaInfoView, loading$: BehaviorSubject<boolean>,
                                dialogRef1: MatDialogRef<TrainingOfferCategoryAddEditDialogComponent>,
                                dialogRef2?: MatDialogRef<DialogInvalidStudyPlansComponent>,
                                forced = false) {
        dialogRef2?.close();
        loading$.next(true);
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const requestBody: UpdateCategoriaOffertaFormativaDTO = {
            denominazione: denominazione,
            descrizione: descrizione,
            note: note,
            configurazioni: configurazioni,
            forced: forced,
        };
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.updateCategoriaOffertaFormativa(requestBody, categoryToEdit.id)),
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
            next: (offertaFormativaUpdated: OffertaFormativaInfoViewImpl) => {
                this.offertaFormativa = offertaFormativaUpdated;
                this.setOffertaFormativaInfoViewLists(offertaFormativaUpdated);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
                dialogRef1.close();
            },
            error: (err: HttpErrorResponse) => {
                console.log(err);
                if (err.status === 409 && err?.error?.code == 1034) {
                    this.openInvalidStudyPlansDialog(requestBody, dialogRef1, err?.error?.utentiConPianoNonPiuValido, categoryToEdit, loading$);
                } else {
                    this.fuseConfirmationService.openErrorDialog(
                        {error: err},
                        this._translocoService,
                        () => {
                        },
                        () => this.editCategoryRequest(denominazione, descrizione, note, configurazioni, categoryToEdit, loading$, dialogRef1),
                        'dialog.cancel',
                        err?.error?.message
                    );
                }
            }
        });
    }

    private computeNumberOfArchivedActivities(offertaFormativaInfo: OffertaFormativaInfoViewImpl) {
        return offertaFormativaInfo?.categorie.reduce((acc, category) =>
            acc + (category.attivitaCategoriaAssociations?.filter(a => a.attivitaOffertaFormativa?.isArchiviato || a.isArchiviato)?.length ?? 0), 0);
    }

    private getAllUniqueCourses(categories: Array<CategoriaOffertaFormativaInfoView>): Array<AttivitaCategoriaAssociationInfoView> {
        const allCourses: Array<AttivitaCategoriaAssociationInfoView> = [];
        categories?.forEach((category) => {
            allCourses.push(...(category?.attivitaCategoriaAssociations ?? []));
        });

        return !!allCourses.length ? uniqBy(allCourses.map(c => ({
            ...c.attivitaOffertaFormativa,
            isAssociazioneArchiviata: c.isArchiviato
        })), 'id') : [];
    }

    openInvalidStudyPlansDialog(requestBody: UpdateCategoriaOffertaFormativaDTO,
                                dialogRef1: MatDialogRef<TrainingOfferCategoryAddEditDialogComponent>,
                                utentiConPianoNonPiuValido: any[],
                                categoryToEdit: CategoriaOffertaFormativaInfoView,
                                loading$: BehaviorSubject<boolean>,
                                activityTypeToAdd?: ElementoOffertaFormativaType) {
        const data: DialogInvalidStudyPlansComponentI = {
            utentiConPianoNonPiuValido: utentiConPianoNonPiuValido,
            icon: {
                name: 'warning_amber',
                color: 'warning'
            },
            onConfirm: (dialogRef2) => !activityTypeToAdd ?
                this.editCategoryRequest(requestBody.denominazione, requestBody.descrizione, requestBody.note, requestBody.configurazioni, categoryToEdit, loading$, dialogRef1, dialogRef2, true)
                : this.addCategoryRequest(requestBody.denominazione, requestBody.descrizione, requestBody.note, requestBody.configurazioni, activityTypeToAdd, loading$, dialogRef1, dialogRef2, true)
        };
        this.dialog.open(DialogInvalidStudyPlansComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            disableClose: true,
            width: '80vw',
        });
    }

    private computeCategoriesCompliance() {
        this.categoriesByYearConfigurations?.forEach(categoriesByYear => {
            categoriesByYear.categoriesByActivityType?.forEach(categoriesByType => {
                categoriesByType.categoriesConfiguration?.forEach(categoryConfig => {
                    if (!categoriesByYear.year) {
                        categoryConfig.activitiesCompliant = true;
                        categoryConfig.activitiesCfuCompliant = true;
                        categoryConfig.activitiesNumCompliant = true;
                        categoryConfig.totaleCfu = 0;
                    } else if (categoriesByType.activityType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE) {
                        const {
                            cfuCompliance,
                            activityNumberCompliance,
                            totCfu,
                            totAtt
                        } = getOfferCategoriesCompliance(categoryConfig, this.categoriesByYearConfigurations, ElementoOffertaFormativaType.ATTIVITATRASVERSALE)
                        categoryConfig.activitiesCompliant = cfuCompliance && activityNumberCompliance;
                        categoryConfig.activitiesCfuCompliant = cfuCompliance;
                        categoryConfig.activitiesNumCompliant = activityNumberCompliance;
                    } else {
                        const {
                            cfuCompliance,
                            activityNumberCompliance,
                            totCfu,
                            totAtt
                        } = getOfferCategoriesCompliance(categoryConfig, this.categoriesByYearConfigurations, categoriesByType.activityType)
                        categoryConfig.activitiesCompliant = cfuCompliance && activityNumberCompliance;
                        categoryConfig.activitiesCfuCompliant = cfuCompliance;
                        categoryConfig.activitiesNumCompliant = activityNumberCompliance;
                        categoryConfig.totaleCfu = totCfu;
                    }
                });
                if (!categoriesByYear.year) {
                    categoriesByType.compliantVincoloOfferta = true;
                    categoriesByType.totaleCfu = 0;
                    categoriesByType.vincoloCfu = {min: 0, max: 0};
                    categoriesByType.activitiesCompliant = true;
                } else {
                    const {
                        compliant,
                        totaleCfu,
                        vincoloCfu
                    } = this.getActivitiesCompliantWithVincoloOfferta(categoriesByType, annoRiferimentoFromRomanNumeral(categoriesByYear.year));
                    categoriesByType.compliantVincoloOfferta = compliant;
                    categoriesByType.totaleCfu = totaleCfu;
                    categoriesByType.vincoloCfu = vincoloCfu;
                    categoriesByType.activitiesCompliant = this.getActivitiesCompliantForCategoryType(categoriesByType) && categoriesByType.compliantVincoloOfferta;
                }
            });
            if (!categoriesByYear.year) {
                categoriesByYear.activitiesCompliant = true;
            } else {
                categoriesByYear.activitiesCompliant = this.getActivitiesCompliantForCategoryYear(categoriesByYear);
            }
        });
        this.trainingOfferService.isTrainingOfferValid = this.categoriesByYearConfigurations?.reduce(
            (previousValue, currentValue) => previousValue && currentValue.activitiesCompliant, true);
    }

    private getActivitiesCompliantForCategoryYear(categoriesByYear: TrainingOfferCategoryByYearDataUI) {
        return categoriesByYear.categoriesByActivityType
            ?.map(a => a.activitiesCompliant)
            ?.reduce((previousValue, currentValue) => previousValue && currentValue, true);
    }

    private getActivitiesCompliantForCategoryType(categoriesByType: TrainingOfferCategoryByTypeDataUI) {
        return categoriesByType.categoriesConfiguration
            ?.map(a => a.activitiesCompliant)
            ?.reduce((previousValue, currentValue) => previousValue && currentValue, true);
    }

    private getActivitiesCompliantWithVincoloOfferta(categoriesByType: TrainingOfferCategoryByTypeDataUI, year: AnnoRiferimentoValues) {
        const vincoliCfu = this.offertaFormativa?.configurazione?.vincoli_cfu_per_tipo_attivita;
        const hasSomeCategoryWithPresunteOrProposte = categoriesByType.categoriesConfiguration?.find(c =>
            c.configurazioneForThisYear?.flag_aggiunta_attivita_proposte || c.configurazioneForThisYear?.flag_aggiunta_attivita_presunte);
        let vincoloCfu = getVincoloCfu(year, categoriesByType, vincoliCfu);
        const areTrasversalCompliant = categoriesByType.activityType === ElementoOffertaFormativaType.ATTIVITATRASVERSALE
            && (categoriesByType.categoriesConfiguration?.find(c => c.attivitaCategoriaAssociations?.length > 0) || vincoloCfu.min == 0);
        if (!vincoliCfu || hasSomeCategoryWithPresunteOrProposte || areTrasversalCompliant) {
            return {compliant: true, totaleCfu: 0};
        } else {
            const totaleCfu = uniqBy(
                    categoriesByType?.categoriesConfiguration
                        ?.flatMap(c => c.attivitaCategoriaAssociations ?? []), att => att.attivitaOffertaFormativa?.id
                )
                    ?.map(a => a.attivitaOffertaFormativa?.cfu ?? 0)
                    ?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                ?? 0;
            return {compliant: totaleCfu >= vincoloCfu.min, totaleCfu: totaleCfu, vincoloCfu: vincoloCfu};
        }
    }

    openYearNotValidDialog() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const errors: FuseConfirmationDialogListDataI[] = [];
        this.fuseConfirmationService.open({
                title: 'training_offer.year_not_valid',
                message: get(translation, 'training_offer.year_not_valid_message', null),
                icon: {
                    show: true,
                    name: 'mat_solid:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            },
        );
    }

    openTypeNotValidDialog() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        const errors: FuseConfirmationDialogListDataI[] = [];
        this.fuseConfirmationService.open({
                title: 'training_offer.type_not_valid',
                message: get(translation, 'training_offer.type_not_valid_message', null),
                icon: {
                    show: true,
                    name: 'mat_solid:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            },
        );
    }

    protected readonly annoRiferimentoToRomanNumeral = annoRiferimentoToRomanNumeral;
    offerYearSelected = AnnoRiferimentoWithAllValues.ALL;
    protected readonly annoInsegnamentoValueToNumber = annoInsegnamentoValueToNumber;

    configurationIndexByYear(offerYearSelected?: AnnoRiferimentoWithAllValues) {
        if (offerYearSelected === AnnoRiferimentoWithAllValues.ALL) {
            return 0;
        } else if (offerYearSelected === AnnoRiferimentoWithAllValues.PRIMO) {
            return 1;
        } else if (offerYearSelected === AnnoRiferimentoWithAllValues.SECONDO) {
            return 2;
        } else {
            return 3;
        }
    }

    protected readonly AnnoRiferimentoValues = AnnoRiferimentoValues;
    protected readonly AnnoRiferimentoWithAllValues = AnnoRiferimentoWithAllValues;
    protected readonly ButtonType = ButtonType;

    openCloneDialog() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'training_offer.clone_offer', null),
                message: get(translation, 'training_offer.clone_offer_message', null),
                icon: {
                    name: 'mat_outline:content_copy',
                    color: 'info'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.cloneOffer()
                    }]
            }
        );
    }

    private cloneOffer() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.offerteFormativeService.clonaOffertaFormativaDaCicloPrecedente()),
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (response: ClonaOffertaFormativaResponse) => {
                    this.offertaFormativa = response?.offertaFormativa;
                    this.cicloConfigurationService.setConfigurationInGestionale(response?.getConfigurazioneCicloDottoratoResponse);
                    this.canClone = response?.offertaFormativa?.isPossibileClonareOffertaFormativa;
                    this.setOffertaFormativaInfoViewLists(response?.offertaFormativa);
                    this.fuseConfirmationService.open({
                            title: get(translation, 'training_offer.clone_offer', null),
                            message: get(translation, 'common.clone_operation_result_success_message', null),
                            icon: {
                                name: 'mat_outline:done',
                                color: 'success'
                            },
                            onBackdrop: {
                                show: false,
                                backdrop: true
                            },
                            actions: [
                                {
                                    color: 'accent',
                                    label: get(translation, 'common.close', null), icon: 'close',
                                }
                            ]
                        }
                    );
                },
                error: (err) => {
                    console.log(err);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'error.clone_error', null),
                        type: SnackbarTypes.Error,
                    });
                }
            }
        );
    }

    protected readonly FunzionalitaStatus = FunzionalitaStatus;
    protected readonly ChiaveFunzionalitaEnum = ChiaveFunzionalitaEnum;
}

export interface TrainingOfferCategoryByYearDataUI extends CategoriaOffertaFormativaInfoView {
    year?: string;
    categoriesByActivityType: TrainingOfferCategoryByTypeDataUI[];
    fragmentLabel?: string;
    totaleCfu?: number;
    canEditActivitiesForYear?: boolean;
    numeroAttivitaSelezionate?: number;
    compliant?: boolean;
    activitiesCompliant?: boolean;
}

export interface TrainingOfferCategoryByTypeDataUI {
    //tableConfiguration?: GenericTableConfigurationModel;
    categoriesConfiguration: Array<TrainingOfferCategoryDataUI>;
    activityType: ElementoOffertaFormativaType;
    activityLabel: string;
    fragmentLabel: string;
    totaleCfu?: number;
    vincoloCfu?: { min: number; max: number };
    compliant?: boolean;
    activitiesCompliant?: boolean;
    compliantVincoloOfferta?: boolean;
}

export enum TrainingOfferYearFragments {
    FIRST = 'I-anno',
    SECOND = 'II-anno',
    THIRD = 'III-anno',
}

export function showButtonModificaAttivitaCondition(sottoruolo: AuthorityType, ruolo: AuthorityType, isAmmSection = false, ammMajorRole?: AuthorityType): boolean {
    return !isAmmSection && (sottoruolo === AuthorityType.COORDINATORE || sottoruolo === AuthorityType.GRUPPODICOORDINAMENTO || ruolo === AuthorityType.SUPERADMIN);
}
