import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {finalize, Observable, switchMap, take, takeUntil} from "rxjs";
import {filter} from "rxjs/operators";
import {
    AddDeliberaCoordinatoreAttivitaTrasversaleRequest,
    AddDeliberaDTO, CorsoPianoDiStudiInfoView, DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService, PageDeliberaInfoViewImpl, PianiDiStudiService, PianoDiStudiInfoView,
    StudentiCicloService
} from 'api-clients/generated/services';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AbstractDefaultComponent } from 'app/shared/abstracts/abstract-default-component/abstract-default-component';
import { DialogWrapI } from 'app/layout/common/dialog-wrap/dialog-wrap.component';
import { AppInitService } from 'app/shared/service/app-init.service';
import { SnackbarTypes } from '@fuse/services/confirmation/snackbar/snackbar.component';
import {get, orderBy} from "lodash";
import {
    GenericComponentDialogConfig
} from "../../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../../layout/common/generic-components/generic-components-enum";
import {
    GenericDialogComponent
} from "../../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";
import {optionFile} from "../../../../../../shared/costants/app-constants";
import {buildFormConfigForAddNewDelibera} from "../../../../gestione-documenti/gestione-documenti-utils";


export interface DialogCoordinatorAddDeliberaI extends DialogWrapI {
    currentPiano: PianoDiStudiInfoView,
    corso: CorsoPianoDiStudiInfoView & {idCategoriaOffertaFormativa?: string};
}

@Component({
    selector: 'app-dialog-coordinator-add-delibera-request',
    templateUrl: './dialog-coordinator-add-delibera-request.component.html',
    styleUrls: ['./dialog-coordinator-add-delibera-request.component.scss']
})
export class DialogCoordinatorAddDeliberaRequestComponent extends AbstractDefaultComponent implements OnInit {

    form: FormGroup;
    loading: boolean;
    currentLanguage: Observable<string>;
    idUtente: string;
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogCoordinatorAddDeliberaI,
                public dialogRef: MatDialogRef<DialogCoordinatorAddDeliberaRequestComponent>,
                private fb: FormBuilder,
                private appInitService: AppInitService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private dialog: MatDialog,
                private delibereService: DelibereService,
                private pianiDiStudiService: PianiDiStudiService

    ) {
        super();
        this.idUtente = location.pathname.split('/')[2];
        this.currentLanguage = this.translocoService.langChanges$;
        this.createForm();
        if(!data?.corso?.deliberaCoordinatoreAttivitaTrasversale) {
            this.getDelibereRequest();
        }
    }

    ngOnInit(): void {
    }

    createForm(): void {
        this.form = this.fb.group({
            deliberaCollegio: [this.data?.corso?.deliberaCoordinatoreAttivitaTrasversale?.id, Validators.required],
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    save() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if(!this.form.valid){
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.inserisciDeliberaAttivitaTrasversale();
        }
    }

    openAddNewDeliberaDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                nota: undefined,
                fileNotaTrasmissione: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }


    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera,
            note: rawValue.nota
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    private getDelibereRequest() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getDelibereRequest(), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }


    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    inserisciDeliberaAttivitaTrasversale(): void {
        if (this.form.enabled) {
            const deliberaRequestBody: AddDeliberaCoordinatoreAttivitaTrasversaleRequest = {
                id_delibera: this.form?.getRawValue().deliberaCollegio,
                id_categoria_offerta_formativa: this.data?.corso?.idCategoriaOffertaFormativa,
            }
            this.form.disable({emitEvent: false});
            this.loading = true;
            this.pianiDiStudiService.addDeliberaCoordinatoreAttivitaTrasversale(
                deliberaRequestBody,
                this.data?.currentPiano?.id,
                this.data?.corso?.idAttivitaTrasversale || this.data?.corso?.idAttivitaProposta || this.data.corso?.idAttivitaPresunta
            ).pipe(
                finalize(() => {
                    this.form.enable();
                    this.loading = false;
                })
            ).subscribe({
                next: (pianoDiStudi: PianoDiStudiInfoView) => {
                    this.dialogRef.close(pianoDiStudi);
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.operation_success', null);
                    this.fuseConfirmationService.openSnackBar({message: message});
                },
                error: (err: HttpErrorResponse) => {
                    const activeLang = this.translocoService.getActiveLang();
                    const translation = this.translocoService.getTranslation().get(activeLang);
                    const message = get(translation, 'common.not_loading_data', null);
                    this.fuseConfirmationService.openSnackBar({
                        message: err?.error?.message || message,
                        error: err, type: SnackbarTypes.Error
                    });
                }
            });
        }
    }



}

