import {Injectable} from '@angular/core';
import moment from 'moment/moment';
import {
    catchError,
    from,
    interval,
    map,
    Observable,
    of,
    startWith,
    Subscription,
    switchMap,
    takeWhile,
    tap
} from 'rxjs';
import {DateTimeDTO, UtilitiesService} from '../../../api-clients/generated/services';
import {cloneDeep} from 'lodash-es';
import {Moment} from 'moment';
import * as moment_tz from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class TimeService {


    // timestamp da BE, aggiornato ogni volta che la data/orario è un parametro critico di una richietsa
    private _currentTime: moment.Moment = moment();


    // timestamp da BE per generazione Nonce delle richieste,
    // allineato al BE ogni X = (refreshCycle * cycleDuration) ms
    // ogni 3s viene aggiornato
    private _currentTimeForNonce: Moment = moment();
    private cycleDuration: number = 3000; // Indica la durata di ogni Nonce
    private timerTaskSubscription: Subscription | undefined;
    private isTimerTaskInExecution: boolean = true;
    private currentTimeForNonceLastUpdate: Moment;
    private currentTimeForNonceLastAlignment: Moment;

    constructor(private utilitiesService: UtilitiesService) {}

    get currentTime(): moment.Moment {
        return cloneDeep(this._currentTime);
    }

    public updateCurrentTime(): Observable<moment.Moment>{
        return this.utilitiesService.getTimestampNow().pipe(
            map((dateTimeDTO: DateTimeDTO) => moment.utc(dateTimeDTO?.timestamp).local()),
            // set frontend time if cannot reach be
            catchError((error) => {
                this._currentTime = moment();
                console.log('TimeService: cannot reach BE for current date time. Setting FE date time', this._currentTime);
                return of(this.currentTime);
            }),
            tap((dateTime: moment.Moment) => {
                this._currentTime = dateTime;
                console.log('TimeService: retrieved BE date time', this._currentTime);
            })
        );
    }

    // MARK: TIMER TASK
    setupTimerTask() {
        interval(this.cycleDuration)
            .pipe(
                switchMap(_ => from(this.computeTime())),
                takeWhile(() => this.isTimerTaskInExecution)
            ).subscribe();
    }

    computeTime(): Promise<Moment> {
        return new Promise((resolve, reject) => {
            if (!this.currentTimeForNonceLastAlignment || (moment().diff(this.currentTimeForNonceLastAlignment, "seconds") >= 300)) {
                this.utilitiesService.getTimestampNow().subscribe((result) => {
                    this._currentTimeForNonce = moment_tz.utc(result.timestamp);
                    this.currentTimeForNonceLastUpdate = moment();
                    this.currentTimeForNonceLastAlignment = moment();
                    console.log('TimeService: aligned currentTimeForNonce with BE time:',  this._currentTimeForNonce.format('DD/MM/YYYY HH:mm:ss'));
                    resolve(this._currentTimeForNonce);
                }, (error) => {
                    /*
                     Errore durante la sincronizzazione. Non Aggiorno il counter in modo
                     che alla prossima esecuzione si riprovi la sincronizzazione col server
                     */
                    const millisFromLastUpdate = moment().diff(this.currentTimeForNonceLastUpdate);
                    this._currentTimeForNonce = this._currentTimeForNonce.add(millisFromLastUpdate, 'milliseconds');
                    console.log('TimeService: Error during BE alignment. currentTimeForNonce FE updated:',  this._currentTimeForNonce.format('DD/MM/YYYY HH:mm:ss'),
                        '\nLast BE alignment:', moment().diff(this.currentTimeForNonceLastAlignment, "seconds"), 's ago',
                        '\nLast FE update:', moment().diff(this.currentTimeForNonceLastUpdate, "seconds"), 's ago');
                    console.log('FE Time', moment().utc(false).format('DD/MM/YYYY HH:mm:ss'));
                    this.currentTimeForNonceLastUpdate = moment();
                    resolve(this._currentTimeForNonce);
                });
            } else {
                const millisFromLastUpdate = moment().diff(this.currentTimeForNonceLastUpdate);
                this._currentTimeForNonce = this._currentTimeForNonce.add(millisFromLastUpdate, 'milliseconds');
                console.log('TimeService: currentTimeForNonce FE updated:',  this._currentTimeForNonce.format('DD/MM/YYYY HH:mm:ss'),
                    '\nLast BE alignment:', moment().diff(this.currentTimeForNonceLastAlignment, "seconds"), 's ago',
                    '\nLast FE update:', moment().diff(this.currentTimeForNonceLastUpdate, "seconds"), 's ago');
                console.log('FE Time', moment().utc(false).format('DD/MM/YYYY HH:mm:ss'));
                this.currentTimeForNonceLastUpdate = moment();
                resolve(this._currentTimeForNonce);
            }
        });
    }

    date(): Date {
        return this._currentTimeForNonce.toDate();
    }

    currentTimeForNonce(): Moment {
        return this._currentTimeForNonce;
    }


}
