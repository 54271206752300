import {isEmpty} from "lodash";
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {
    AbstractDefaultComponent
} from "../../abstracts/abstract-default-component/abstract-default-component";
import {
    AnnoRiferimentoValues,
    AttivitaOffertaFormativaInfoView,
    UserSottoruoloView
} from "api-clients/generated/services";
import {FormControl, FormGroup} from "@angular/forms";
import {AppInitService} from "app/shared/service/app-init.service";
import {distinctUntilChanged, take, takeUntil, tap} from "rxjs";
import {LocalStorageService} from "../../service/local-storage.service";
import {
    TrasversaliBarFilterManagerService
} from "../../service/trasversali-bar-filter-manager.service";
import {CicloConfigurationService} from "../../service/ciclo-configuration.service";

@Component({
    selector: 'app-trasversali-bar-filter-container',
    templateUrl: './trasversali-bar-filter-container.component.html',
    styleUrls: ['./trasversali-bar-filter-container.component.scss']
})
export class TrasversaliBarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeBar: EventEmitter<boolean> = new EventEmitter<boolean>();
    mainForm: FormGroup | undefined;
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    _denomDescrAttivita = 'denomDescrAttivita';
    _dettaglioAttivitaTrasversale = 'dettaglioAttivitaTrasversale';
    _annoInsegnamento = 'annoInsegnamento';
    _nominativoStudente = 'nominativoStudente';
    _cfStudente = 'cfStudente'
    _matricolaStudente = 'matricolaStudente';
    _emailStudente = 'emailStudente';
    cosupervisoreList: UserSottoruoloView[];
    supervisoreList: UserSottoruoloView[];
    docenteList: UserSottoruoloView[];
    filterApplied = {
        'denomDescrAttivita': null,
        'dettaglioAttivitaTrasversale': null,
        'annoInsegnamento': null,
        'nominativoStudente': null,
        'cfStudente': null,
        'matricolaStudente': null,
        'emailStudente': null
    };
    protected readonly Object = Object;
    constructor(public filterManagerService: TrasversaliBarFilterManagerService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,
                protected cicloConfigurationService: CicloConfigurationService,) {
        super();
        this.currentFields = this.filterManagerService.defaultFormPathList;
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;

    }

    ngOnInit(): void {
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => console.log('Aggiorna filtri')),
            tap(value =>
                this.currentFields = this.filterManagerService.getFieldsByRole(value?.ruolo, value?.sottoruolo)),
            takeUntil(this.destroy$)
        ).subscribe();

        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            tap(() => this.filterManagerService.init()),
            tap(value =>
                this.currentFields = this.filterManagerService.getFieldsByRole(
                    this.localStorageService?.dipartimentoRuoloCiclo?.ruolo,
                    value)
            ),
            takeUntil(this.destroy$)
        ).subscribe();


        this.filterManagerService.filterApplied$.asObservable().pipe(
            tap((value) => this.filterApplied = value),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.filterManagerService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService.mainFormGroup;
    }

    applyFilters(): void {
        //this.filterApplied= this.getMainFormGroup().getRawValue();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.filterManagerService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeBar.next(true);
    }


    reset(): void {
        this.resetFilterApplied();
        this.getMainFormGroup().reset();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
        this.close();
    }

    setFilterApplied(): void {

        if (!!this.filterApplied && !isEmpty(this.filterApplied)) {
            this.filterManagerService.mainFormGroup.setValue(
                {
                    'denomDescrAttivita': this.filterApplied?.denomDescrAttivita,
                    'dettaglioAttivitaTrasversale': this.filterApplied?.dettaglioAttivitaTrasversale,
                    'annoInsegnamento': this.filterApplied?.annoInsegnamento,
                    'nominativoStudente': this.filterApplied?.nominativoStudente,
                    'cfStudente': this.filterApplied?.cfStudente,
                    'matricolaStudente': this.filterApplied?.matricolaStudente,
                    'emailStudente': this.filterApplied?.emailStudente,
                }
            );
        }
    }

    resetFilterApplied(): void {
        this.filterApplied = {
            'denomDescrAttivita': null,
            'dettaglioAttivitaTrasversale': null,
            'annoInsegnamento': null,
            'nominativoStudente': null,
            'cfStudente': null,
            'matricolaStudente': null,
            'emailStudente': null
        };
        this.filterManagerService.mainFormGroup.reset();
    }


    protected readonly AnnoRiferimentoValues = AnnoRiferimentoValues;
}
