import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {
    MobilitaRequestSelectFormValuesDTO,
    PeriodoDiMobilitaStudenteStatus,
    StatoTemporaleEnum,
    TipoPeriodoEnum
} from "api-clients/generated/services";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppInitService} from "app/shared/service/app-init.service";
import {distinctUntilChanged, Observable, take, takeUntil, tap} from "rxjs";
import {LocalStorageService} from "../../service/local-storage.service";
import {TranslocoService} from "@ngneat/transloco";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import * as moment from "moment/moment";
import {MobilitaFilterManagerService} from "../../service/mobilita-filter-manager.service";
import {isEmpty} from "lodash";
import {CicloConfigurationService} from "../../service/ciclo-configuration.service";

@Component({
    selector: 'app-mobilita-ricerca-bar-filter-container',
    templateUrl: './mobilita-ricerca-bar-filter-container.component.html',
    styleUrls: ['./mobilita-ricerca-bar-filter-container.component.scss']
})
export class MobilitaRicercaBarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeBar: EventEmitter<boolean> = new EventEmitter<boolean>();
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    outputFormatData = 'DD/MM/YYYY';
    filterApplied = {
        'nominativoStudente': null,
        'statoSvolgimento': null,
        'periodoDa': null,
        'periodoA': null,
        'annoAccademico': null,
        'nominativoStrutturaEsteraOspitante': null,
        'codiceNazioneStruttura': null,
        'dataFineNull': null,
        'codiceTipologiaMobilita': null,
        'codiceTipologiaBonus': null,
        'codiceMobilita': null,
        'stato': null,
        'dataUltimaModifica': null
    };
    selectValues: MobilitaRequestSelectFormValuesDTO;
    currentLanguage: Observable<string>;
    // filters names
    _nominativoStudente = 'nominativoStudente';
    _statoSvolgimento = 'statoSvolgimento';
    _periodoDa = 'periodoDa';
    _periodoA = 'periodoA';
    _nominativoStrutturaEsteraOspitante = 'nominativoStrutturaEsteraOspitante';
    _annoAccademico = 'annoAccademico';
    _codiceNazioneStruttura = 'codiceNazioneStruttura';
    _dataFineNull = 'dataFineNull';
    _codiceTipologiaMobilita = 'codiceTipologiaMobilita';
    _codiceTipologiaBonus = 'codiceTipologiaBonus';
    _codiceMobilita = 'codiceMobilita';
    _stato = 'stato';
    _dataUltimaModifica = 'dataUltimaModifica'

    protected readonly Object = Object;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly StatoTemporaleEnum = StatoTemporaleEnum;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;

    constructor(public mobilitaFilterManagerService: MobilitaFilterManagerService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,
                private translocoService: TranslocoService,
                protected cicloConfigurationService: CicloConfigurationService,) {
        super();
        this.currentFields = this.mobilitaFilterManagerService.defaultFormPathList;
        this.fieldsLabelMap = this.mobilitaFilterManagerService.fieldsLabelMap;
        this.currentLanguage = this.translocoService.langChanges$;
    }

    ngOnInit(): void {
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => console.log('Aggiorna filtri')),
            tap(value =>
                this.currentFields = this.mobilitaFilterManagerService.getFieldsByRole(value?.ruolo, value?.sottoruolo)),
            takeUntil(this.destroy$)
        ).subscribe();

        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            tap(() => this.mobilitaFilterManagerService.init()),
            tap(value =>
                this.currentFields = this.mobilitaFilterManagerService.getFieldsByRole(
                    this.localStorageService?.dipartimentoRuoloCiclo?.ruolo,
                    value)
            ),
            takeUntil(this.destroy$)
        ).subscribe();

        this.mobilitaFilterManagerService.checkAggiornamentoSelectValues$.asObservable().pipe(
            tap(() => this.selectValues = this.mobilitaFilterManagerService.selectValues),
            takeUntil(this.destroy$)
        ).subscribe();

        this.mobilitaFilterManagerService.filterApplied$.asObservable().pipe(
            tap((value) => this.filterApplied = value),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.mobilitaFilterManagerService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.mobilitaFilterManagerService.mainFormGroup;
    }

    applyFilters(): void {
        //this.filterApplied= this.getMainFormGroup().getRawValue();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.mobilitaFilterManagerService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeBar.next(true);
    }


    reset(): void {
        this.resetFilterApplied();
        this.getMainFormGroup().reset();
        this.mobilitaFilterManagerService?.checkApplyFilterClick$.next(true);
        this.close();
    }

    setFilterApplied(): void {
        /*        if (!!this.filterApplied) {
                    if (!!this.filterApplied?.codiceFiscaleSupervisore && this.filterApplied?.codiceFiscaleSupervisore?.nome && this.filterApplied?.codiceFiscaleSupervisore?.cognome) {
                        this.filterApplied.codiceFiscaleSupervisore.nome = capitalizeString(this.filterApplied.codiceFiscaleSupervisore?.nome);
                        this.filterApplied.codiceFiscaleSupervisore.cognome = capitalizeString(this.filterApplied.codiceFiscaleSupervisore?.cognome);
                    }
                    // eslint-disable-next-line max-len
                    if (!!this.filterApplied?.codiceFiscaleCosupervisore && this.filterApplied?.codiceFiscaleCosupervisore?.nome && this.filterApplied?.codiceFiscaleCosupervisore?.cognome) {
                        this.filterApplied.codiceFiscaleCosupervisore.nome = capitalizeString(this.filterApplied.codiceFiscaleCosupervisore?.nome);
                        this.filterApplied.codiceFiscaleCosupervisore.cognome = capitalizeString(this.filterApplied.codiceFiscaleCosupervisore?.cognome);
                    }
                    if (!!this.filterApplied?.codiceFiscaleDocenteCorso && this.filterApplied?.codiceFiscaleDocenteCorso?.nome && this.filterApplied.codiceFiscaleDocenteCorso?.cognome) {
                        this.filterApplied.codiceFiscaleDocenteCorso.nome = capitalizeString(this.filterApplied.codiceFiscaleDocenteCorso?.nome);
                        this.filterApplied.codiceFiscaleDocenteCorso.cognome = capitalizeString(this.filterApplied.codiceFiscaleDocenteCorso?.cognome);
                    }
                    if (!!this.filterApplied?.idCorsoInOffertaFormativa && this.filterApplied?.idCorsoInOffertaFormativa?.denominazione) {
                        this.filterApplied.idCorsoInOffertaFormativa.denominazione = capitalizeString(this.filterApplied.idCorsoInOffertaFormativa?.denominazione);
                    }
                }*/

        if (!!this.filterApplied && !isEmpty(this.filterApplied)) {
            this.mobilitaFilterManagerService.mainFormGroup.setValue(
                {
                    'nominativoStudente': this.filterApplied?.nominativoStudente,
                    'statoSvolgimento': this.filterApplied?.statoSvolgimento,
                    'periodoDa': this.filterApplied?.periodoDa,
                    'periodoA': this.filterApplied?.periodoA,
                    'annoAccademico': this.filterApplied?.annoAccademico,
                    'nominativoStrutturaEsteraOspitante': this.filterApplied?.nominativoStrutturaEsteraOspitante,
                    'codiceNazioneStruttura': this.filterApplied?.codiceNazioneStruttura,
                    'dataFineNull': this.filterApplied?.dataFineNull,
                    'codiceTipologiaMobilita': this.filterApplied?.codiceTipologiaMobilita,
                    'codiceTipologiaBonus': this.filterApplied?.codiceTipologiaBonus,
                    'codiceMobilita': this.filterApplied?.codiceMobilita,
                    'stato': this.filterApplied?.stato,
                    'dataUltimaModifica': this.filterApplied?.dataUltimaModifica,
                }
            );
        }
    }

    resetFilterApplied(): void {
        this.filterApplied = {
            'nominativoStudente': null,
            'statoSvolgimento': null,
            'periodoDa': null,
            'periodoA': null,
            'annoAccademico': null,
            'nominativoStrutturaEsteraOspitante': null,
            'codiceNazioneStruttura': null,
            'dataFineNull': null,
            'codiceTipologiaMobilita': null,
            'codiceTipologiaBonus': null,
            'codiceMobilita': null,
            'stato': null,
            'dataUltimaModifica': null,
        };
        this.mobilitaFilterManagerService.mainFormGroup.reset();
    }

    dateEvent(event: MatDatepickerInputEvent<Date>, fieldCtrl: AbstractControl, required: boolean) {
        fieldCtrl.setValue(moment(event.value).format(this.outputFormatData));
        fieldCtrl.setErrors(null);
        fieldCtrl.setValidators([]);
        if (required) {
            fieldCtrl.addValidators(Validators.required);
        }
        fieldCtrl.updateValueAndValidity();
        this.prefillDateFields();
    }

    clearDateField(formControl: FormControl<any>) {
        formControl.setValue(undefined);
        formControl.setErrors(null);
        formControl.setValidators([]);
        this.prefillDateFields(true);
    }

    dateValue(fieldCtrl: AbstractControl) {
        return fieldCtrl.value ? moment(fieldCtrl.value, this.outputFormatData).toDate() : null;
    }

    private prefillDateFields(isRemovingData: boolean = false) {
        // prefill the other date field (to prevent sending only one of the dates)
        const periodoDaCtrl = this.getFormControlByPath('periodoDa');
        const periodoACtrl = this.getFormControlByPath('periodoA');
        if(isRemovingData) {
            periodoACtrl.patchValue(null);
            periodoDaCtrl.patchValue(null);
        } else {
            if (!!periodoDaCtrl.value && !periodoACtrl.value) {
                periodoACtrl.patchValue(periodoDaCtrl.value);
            } else if (!periodoDaCtrl.value && !!periodoACtrl.value) {
                periodoDaCtrl.patchValue(periodoACtrl.value);
            }
        }
    }
}
