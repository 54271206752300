/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest } from '../model/approvazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest';
import { ApprovazioneMassivaAggiornamentiBudgetStudenteResponse } from '../model/approvazioneMassivaAggiornamentiBudgetStudenteResponse';
import { ApprovazioneMassivaMissioniStudenteRequest } from '../model/approvazioneMassivaMissioniStudenteRequest';
import { ApprovazioneMassivaMissioniStudenteResponse } from '../model/approvazioneMassivaMissioniStudenteResponse';
import { ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest } from '../model/approvazioneMassivaPeriodiRegistroAttivitaStudenteRequest';
import { ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse } from '../model/approvazioneMassivaPeriodiRegistroAttivitaStudenteResponse';
import { ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest } from '../model/approvazioneMassivaRichiestaDiConclusioneMobilitaRequest';
import { ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse } from '../model/approvazioneMassivaRichiestaDiConclusioneMobilitaResponse';
import { ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest } from '../model/approvazioneMassivaRichiestaDiRetribuzioneMobilitaRequest';
import { ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse } from '../model/approvazioneMassivaRichiestaDiRetribuzioneMobilitaResponse';
import { ApprovazioneMassivaSpeseStudentiByGruppoPTARequest } from '../model/approvazioneMassivaSpeseStudentiByGruppoPTARequest';
import { AutorizzazioneMassivaMobilitaDottorandiResponse } from '../model/autorizzazioneMassivaMobilitaDottorandiResponse';
import { CaricamentoMassivoDeliberaAttivitaTrasversaliRequest } from '../model/caricamentoMassivoDeliberaAttivitaTrasversaliRequest';
import { CaricamentoMassivoDeliberaInizioAttivitaRequest } from '../model/caricamentoMassivoDeliberaInizioAttivitaRequest';
import { CaricamentoMassivoDeliberaInizioAttivitaResponse } from '../model/caricamentoMassivoDeliberaInizioAttivitaResponse';
import { CaricamentoMassivoDeliberaPianiDiStudiRequest } from '../model/caricamentoMassivoDeliberaPianiDiStudiRequest';
import { CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiRequest } from '../model/caricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiRequest';
import { CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiResponse } from '../model/caricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiResponse';
import { DeliberaApprovaPeriodiDiRicercaRequest } from '../model/deliberaApprovaPeriodiDiRicercaRequest';
import { DeliberaApprovaPeriodiDiRicercaResponse } from '../model/deliberaApprovaPeriodiDiRicercaResponse';
import { DeliberaApprovaPeriodiDiSoggiornoEsteroRequest } from '../model/deliberaApprovaPeriodiDiSoggiornoEsteroRequest';
import { DeliberaApprovaPeriodiDiSoggiornoEsteroResponse } from '../model/deliberaApprovaPeriodiDiSoggiornoEsteroResponse';
import { DeliberaApprovaSpeseStudentiRequest } from '../model/deliberaApprovaSpeseStudentiRequest';
import { DeliberaApprovaSpeseStudentiResponse } from '../model/deliberaApprovaSpeseStudentiResponse';
import { DeliberaMassivaAttivitaTrasversaliRequest } from '../model/deliberaMassivaAttivitaTrasversaliRequest';
import { DeliberaMassivaPianiDiStudiRequest } from '../model/deliberaMassivaPianiDiStudiRequest';
import { DeliberaMassivaPianiDiStudiResponse } from '../model/deliberaMassivaPianiDiStudiResponse';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { OperazioneMassivaSuAttivitaExtraResponse } from '../model/operazioneMassivaSuAttivitaExtraResponse';
import { OperazioneMassivaSuAttivitaTrasversaliResponse } from '../model/operazioneMassivaSuAttivitaTrasversaliResponse';
import { SuperamentoMassivoAttivitaExtraDottorandiRequest } from '../model/superamentoMassivoAttivitaExtraDottorandiRequest';
import { SuperamentoMassivoAttivitaTrasversaliDottorandiRequest } from '../model/superamentoMassivoAttivitaTrasversaliDottorandiRequest';
import { SuperamentoMassivoCorsoRequest } from '../model/superamentoMassivoCorsoRequest';
import { SuperamentoMassivoCorsoResponse } from '../model/superamentoMassivoCorsoResponse';
import { UtenteIdsDTO } from '../model/utenteIdsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OperazioniMassiveService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette al GRUPPO-PTA di approvare massivamente gli aggiornamenti al budget di più dottorandi.
     * 
     * @param specifiche 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaAggiornamentiBudgetStudentiByGruppoPTAForm(specifiche?: ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest, observe?: 'body', reportProgress?: boolean): Observable<ApprovazioneMassivaAggiornamentiBudgetStudenteResponse>;
    public approvazioneMassivaAggiornamentiBudgetStudentiByGruppoPTAForm(specifiche?: ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovazioneMassivaAggiornamentiBudgetStudenteResponse>>;
    public approvazioneMassivaAggiornamentiBudgetStudentiByGruppoPTAForm(specifiche?: ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovazioneMassivaAggiornamentiBudgetStudenteResponse>>;
    public approvazioneMassivaAggiornamentiBudgetStudentiByGruppoPTAForm(specifiche?: ApprovazioneMassivaAggiornamentiBudgetStudenteByGruppoPTARequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (specifiche !== undefined) {
            formParams = formParams.append('specifiche', new Blob([JSON.stringify(specifiche)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<ApprovazioneMassivaAggiornamentiBudgetStudenteResponse>('post',`${this.basePath}/api/v1/operazione-massiva/approvazione-massiva-aggiornamenti-budget-studente/gruppo-pta`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di approvare massivamente le richieste di missione di più dottorandi.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaMissioniDottorandi(body: ApprovazioneMassivaMissioniStudenteRequest, observe?: 'body', reportProgress?: boolean): Observable<ApprovazioneMassivaMissioniStudenteResponse>;
    public approvazioneMassivaMissioniDottorandi(body: ApprovazioneMassivaMissioniStudenteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovazioneMassivaMissioniStudenteResponse>>;
    public approvazioneMassivaMissioniDottorandi(body: ApprovazioneMassivaMissioniStudenteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovazioneMassivaMissioniStudenteResponse>>;
    public approvazioneMassivaMissioniDottorandi(body: ApprovazioneMassivaMissioniStudenteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling approvazioneMassivaMissioniDottorandi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApprovazioneMassivaMissioniStudenteResponse>('post',`${this.basePath}/api/v1/operazione-massiva/approvazione-massiva-missioni-dottorandi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di approvare massivamente le richieste di validazione dei periodi di registro attività di più dottorandi.
     * 
     * @param specifiche 
     * @param file_allegati 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaPeriodiRegistroAttivitaDottorandiForm(specifiche?: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest, file_allegati?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse>;
    public approvazioneMassivaPeriodiRegistroAttivitaDottorandiForm(specifiche?: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest, file_allegati?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse>>;
    public approvazioneMassivaPeriodiRegistroAttivitaDottorandiForm(specifiche?: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest, file_allegati?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse>>;
    public approvazioneMassivaPeriodiRegistroAttivitaDottorandiForm(specifiche?: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest, file_allegati?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (specifiche !== undefined) {
            formParams = formParams.append('specifiche', new Blob([JSON.stringify(specifiche)], { type: 'application/json'})) as any || formParams;
        }
        if (file_allegati) {
            file_allegati.forEach((element) => {
                formParams = formParams.append('file_allegati', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse>('post',`${this.basePath}/api/v1/operazione-massiva/approvazione-massiva-periodi-registro-attivita-dottorandi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di caricare la delibera e approvare massivamente i piani di studi di più studenti deliberate con stessa delibera.
     * 
     * @param request 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaPianiDiStudiConDeliberaForm(request?: DeliberaMassivaPianiDiStudiRequest, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<DeliberaMassivaPianiDiStudiResponse>;
    public approvazioneMassivaPianiDiStudiConDeliberaForm(request?: DeliberaMassivaPianiDiStudiRequest, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaMassivaPianiDiStudiResponse>>;
    public approvazioneMassivaPianiDiStudiConDeliberaForm(request?: DeliberaMassivaPianiDiStudiRequest, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaMassivaPianiDiStudiResponse>>;
    public approvazioneMassivaPianiDiStudiConDeliberaForm(request?: DeliberaMassivaPianiDiStudiRequest, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (request !== undefined) {
            formParams = formParams.append('request', new Blob([JSON.stringify(request)], { type: 'application/json'})) as any || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('files', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<DeliberaMassivaPianiDiStudiResponse>('post',`${this.basePath}/api/v1/operazione-massiva/delibera-approva-piani-di-studi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di approvare massivamente le richieste di conclusione di mobilità di più dottorandi.
     * 
     * @param specifiche 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaRichiestaDiConclusioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest, observe?: 'body', reportProgress?: boolean): Observable<ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse>;
    public approvazioneMassivaRichiestaDiConclusioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse>>;
    public approvazioneMassivaRichiestaDiConclusioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse>>;
    public approvazioneMassivaRichiestaDiConclusioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (specifiche !== undefined) {
            formParams = formParams.append('specifiche', new Blob([JSON.stringify(specifiche)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse>('post',`${this.basePath}/api/v1/operazione-massiva/approvazione-massiva-richieste-di-conclusione-mobilita`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di approvare massivamente le richieste di retribuzione di mobilità di più dottorandi.
     * 
     * @param specifiche 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaRichiestaDiRetribuzioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest, observe?: 'body', reportProgress?: boolean): Observable<ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse>;
    public approvazioneMassivaRichiestaDiRetribuzioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse>>;
    public approvazioneMassivaRichiestaDiRetribuzioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse>>;
    public approvazioneMassivaRichiestaDiRetribuzioneMobilitaForm(specifiche?: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (specifiche !== undefined) {
            formParams = formParams.append('specifiche', new Blob([JSON.stringify(specifiche)], { type: 'application/json'})) as any || formParams;
        }

        return this.httpClient.request<ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse>('post',`${this.basePath}/api/v1/operazione-massiva/approvazione-massiva-richieste-di-retribuzione-mobilita`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al GRUPPO-PTA di approvare massivamente le richieste di spesa di più studenti.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvazioneMassivaSpeseStudentiByGruppoPTA(body: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest, observe?: 'body', reportProgress?: boolean): Observable<DeliberaApprovaSpeseStudentiResponse>;
    public approvazioneMassivaSpeseStudentiByGruppoPTA(body: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaApprovaSpeseStudentiResponse>>;
    public approvazioneMassivaSpeseStudentiByGruppoPTA(body: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaApprovaSpeseStudentiResponse>>;
    public approvazioneMassivaSpeseStudentiByGruppoPTA(body: ApprovazioneMassivaSpeseStudentiByGruppoPTARequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling approvazioneMassivaSpeseStudentiByGruppoPTA.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeliberaApprovaSpeseStudentiResponse>('post',`${this.basePath}/api/v1/operazione-massiva/approva-spese-studenti/gruppo-pta`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al COORDINATORE di autorizzare massivamente la mobilità di più dottorandi.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public autorizzazioneMassivaMobilitaDottorandi(body: UtenteIdsDTO, observe?: 'body', reportProgress?: boolean): Observable<AutorizzazioneMassivaMobilitaDottorandiResponse>;
    public autorizzazioneMassivaMobilitaDottorandi(body: UtenteIdsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AutorizzazioneMassivaMobilitaDottorandiResponse>>;
    public autorizzazioneMassivaMobilitaDottorandi(body: UtenteIdsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AutorizzazioneMassivaMobilitaDottorandiResponse>>;
    public autorizzazioneMassivaMobilitaDottorandi(body: UtenteIdsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling autorizzazioneMassivaMobilitaDottorandi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AutorizzazioneMassivaMobilitaDottorandiResponse>('post',`${this.basePath}/api/v1/operazione-massiva/autorizzazione-massiva-mobilita-dottorandi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare massivamente la delibera per le attività trasversali di più studenti deliberate con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public caricamentoMassivoDeliberaAttivitaTrasversali(body: CaricamentoMassivoDeliberaAttivitaTrasversaliRequest, observe?: 'body', reportProgress?: boolean): Observable<OperazioneMassivaSuAttivitaTrasversaliResponse>;
    public caricamentoMassivoDeliberaAttivitaTrasversali(body: CaricamentoMassivoDeliberaAttivitaTrasversaliRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperazioneMassivaSuAttivitaTrasversaliResponse>>;
    public caricamentoMassivoDeliberaAttivitaTrasversali(body: CaricamentoMassivoDeliberaAttivitaTrasversaliRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperazioneMassivaSuAttivitaTrasversaliResponse>>;
    public caricamentoMassivoDeliberaAttivitaTrasversali(body: CaricamentoMassivoDeliberaAttivitaTrasversaliRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling caricamentoMassivoDeliberaAttivitaTrasversali.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OperazioneMassivaSuAttivitaTrasversaliResponse>('post',`${this.basePath}/api/v1/operazione-massiva/caricamento-massivo-delibera-attivita-trasversali`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al GRUPPO_PTA di caricare massivamente la delibera di inizio attività di più studenti deliberate con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public caricamentoMassivoDeliberaInizioAttivita(body: CaricamentoMassivoDeliberaInizioAttivitaRequest, observe?: 'body', reportProgress?: boolean): Observable<CaricamentoMassivoDeliberaInizioAttivitaResponse>;
    public caricamentoMassivoDeliberaInizioAttivita(body: CaricamentoMassivoDeliberaInizioAttivitaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CaricamentoMassivoDeliberaInizioAttivitaResponse>>;
    public caricamentoMassivoDeliberaInizioAttivita(body: CaricamentoMassivoDeliberaInizioAttivitaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CaricamentoMassivoDeliberaInizioAttivitaResponse>>;
    public caricamentoMassivoDeliberaInizioAttivita(body: CaricamentoMassivoDeliberaInizioAttivitaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling caricamentoMassivoDeliberaInizioAttivita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CaricamentoMassivoDeliberaInizioAttivitaResponse>('post',`${this.basePath}/api/v1/operazione-massiva/caricamento-massivo-delibera-inizio-attivita`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al GRUPPO_PTA di caricare massivamente la delibera per i piani di studi di più studenti deliberate con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public caricamentoMassivoDeliberaPianiDiStudi(body: CaricamentoMassivoDeliberaPianiDiStudiRequest, observe?: 'body', reportProgress?: boolean): Observable<DeliberaMassivaPianiDiStudiResponse>;
    public caricamentoMassivoDeliberaPianiDiStudi(body: CaricamentoMassivoDeliberaPianiDiStudiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaMassivaPianiDiStudiResponse>>;
    public caricamentoMassivoDeliberaPianiDiStudi(body: CaricamentoMassivoDeliberaPianiDiStudiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaMassivaPianiDiStudiResponse>>;
    public caricamentoMassivoDeliberaPianiDiStudi(body: CaricamentoMassivoDeliberaPianiDiStudiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling caricamentoMassivoDeliberaPianiDiStudi.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeliberaMassivaPianiDiStudiResponse>('post',`${this.basePath}/api/v1/operazione-massiva/caricamento-massivo-delibera-piani-di-studi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al GRUPPO_PTA di caricare massivamente la delibera di assegnazione Supervisore e Cosupervisore di più studenti deliberate con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public caricamentoMassivoDeliberaSupervisoriCosupervisoriStudenti(body: CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiRequest, observe?: 'body', reportProgress?: boolean): Observable<CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiResponse>;
    public caricamentoMassivoDeliberaSupervisoriCosupervisoriStudenti(body: CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiResponse>>;
    public caricamentoMassivoDeliberaSupervisoriCosupervisoriStudenti(body: CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiResponse>>;
    public caricamentoMassivoDeliberaSupervisoriCosupervisoriStudenti(body: CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling caricamentoMassivoDeliberaSupervisoriCosupervisoriStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CaricamentoMassivoDeliberaSupervisoriCosupervisoriStudentiResponse>('post',`${this.basePath}/api/v1/operazione-massiva/caricamento-massivo-delibera-supervisori-cosupervisori-studenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare i periodi di ricerca di più studenti deliberati con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deliberaApprovaPeriodiDiRicerca(body: DeliberaApprovaPeriodiDiRicercaRequest, observe?: 'body', reportProgress?: boolean): Observable<DeliberaApprovaPeriodiDiRicercaResponse>;
    public deliberaApprovaPeriodiDiRicerca(body: DeliberaApprovaPeriodiDiRicercaRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaApprovaPeriodiDiRicercaResponse>>;
    public deliberaApprovaPeriodiDiRicerca(body: DeliberaApprovaPeriodiDiRicercaRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaApprovaPeriodiDiRicercaResponse>>;
    public deliberaApprovaPeriodiDiRicerca(body: DeliberaApprovaPeriodiDiRicercaRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deliberaApprovaPeriodiDiRicerca.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeliberaApprovaPeriodiDiRicercaResponse>('post',`${this.basePath}/api/v1/operazione-massiva/delibera-periodi-di-ricerca`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare i periodi di soggiorno estero di più studenti deliberati con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deliberaApprovaPeriodiDiSoggiornoEstero(body: DeliberaApprovaPeriodiDiSoggiornoEsteroRequest, observe?: 'body', reportProgress?: boolean): Observable<DeliberaApprovaPeriodiDiSoggiornoEsteroResponse>;
    public deliberaApprovaPeriodiDiSoggiornoEstero(body: DeliberaApprovaPeriodiDiSoggiornoEsteroRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaApprovaPeriodiDiSoggiornoEsteroResponse>>;
    public deliberaApprovaPeriodiDiSoggiornoEstero(body: DeliberaApprovaPeriodiDiSoggiornoEsteroRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaApprovaPeriodiDiSoggiornoEsteroResponse>>;
    public deliberaApprovaPeriodiDiSoggiornoEstero(body: DeliberaApprovaPeriodiDiSoggiornoEsteroRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deliberaApprovaPeriodiDiSoggiornoEstero.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeliberaApprovaPeriodiDiSoggiornoEsteroResponse>('post',`${this.basePath}/api/v1/operazione-massiva/delibera-periodi-di-soggiorno-estero`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare massivamente le richieste di spesa di più studenti deliberate con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deliberaApprovaSpeseStudenti(body: DeliberaApprovaSpeseStudentiRequest, observe?: 'body', reportProgress?: boolean): Observable<DeliberaApprovaSpeseStudentiResponse>;
    public deliberaApprovaSpeseStudenti(body: DeliberaApprovaSpeseStudentiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaApprovaSpeseStudentiResponse>>;
    public deliberaApprovaSpeseStudenti(body: DeliberaApprovaSpeseStudentiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaApprovaSpeseStudentiResponse>>;
    public deliberaApprovaSpeseStudenti(body: DeliberaApprovaSpeseStudentiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deliberaApprovaSpeseStudenti.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeliberaApprovaSpeseStudentiResponse>('post',`${this.basePath}/api/v1/operazione-massiva/delibera-spese-studenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare massivamente la delibera per le attività trasversali di più studenti deliberate con stessa delibera.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deliberaMassivaAttivitaTrasversali(body: DeliberaMassivaAttivitaTrasversaliRequest, observe?: 'body', reportProgress?: boolean): Observable<OperazioneMassivaSuAttivitaTrasversaliResponse>;
    public deliberaMassivaAttivitaTrasversali(body: DeliberaMassivaAttivitaTrasversaliRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperazioneMassivaSuAttivitaTrasversaliResponse>>;
    public deliberaMassivaAttivitaTrasversali(body: DeliberaMassivaAttivitaTrasversaliRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperazioneMassivaSuAttivitaTrasversaliResponse>>;
    public deliberaMassivaAttivitaTrasversali(body: DeliberaMassivaAttivitaTrasversaliRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deliberaMassivaAttivitaTrasversali.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OperazioneMassivaSuAttivitaTrasversaliResponse>('post',`${this.basePath}/api/v1/operazione-massiva/delibera-attivita-trasversali`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di registrare massivamente il superamento di un corso a più studenti.
     * 
     * @param body 
     * @param idCorsoInOffertaFormativa id del corso in offerta formativa
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public superamentoCorsoMassivo(body: SuperamentoMassivoCorsoRequest, idCorsoInOffertaFormativa: string, observe?: 'body', reportProgress?: boolean): Observable<SuperamentoMassivoCorsoResponse>;
    public superamentoCorsoMassivo(body: SuperamentoMassivoCorsoRequest, idCorsoInOffertaFormativa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SuperamentoMassivoCorsoResponse>>;
    public superamentoCorsoMassivo(body: SuperamentoMassivoCorsoRequest, idCorsoInOffertaFormativa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SuperamentoMassivoCorsoResponse>>;
    public superamentoCorsoMassivo(body: SuperamentoMassivoCorsoRequest, idCorsoInOffertaFormativa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling superamentoCorsoMassivo.');
        }

        if (idCorsoInOffertaFormativa === null || idCorsoInOffertaFormativa === undefined) {
            throw new Error('Required parameter idCorsoInOffertaFormativa was null or undefined when calling superamentoCorsoMassivo.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SuperamentoMassivoCorsoResponse>('post',`${this.basePath}/api/v1/operazione-massiva/superamento-corso/${encodeURIComponent(String(idCorsoInOffertaFormativa))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al SUPERVISORE/COSUPERVISORE di registrare massivamente il superamento delle attività extra di più dottorandi.
     * 
     * @param specifiche 
     * @param file_allegati 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public superamentoMassivoAttivitaExtraDottorandiForm(specifiche?: SuperamentoMassivoAttivitaExtraDottorandiRequest, file_allegati?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<OperazioneMassivaSuAttivitaExtraResponse>;
    public superamentoMassivoAttivitaExtraDottorandiForm(specifiche?: SuperamentoMassivoAttivitaExtraDottorandiRequest, file_allegati?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperazioneMassivaSuAttivitaExtraResponse>>;
    public superamentoMassivoAttivitaExtraDottorandiForm(specifiche?: SuperamentoMassivoAttivitaExtraDottorandiRequest, file_allegati?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperazioneMassivaSuAttivitaExtraResponse>>;
    public superamentoMassivoAttivitaExtraDottorandiForm(specifiche?: SuperamentoMassivoAttivitaExtraDottorandiRequest, file_allegati?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (specifiche !== undefined) {
            formParams = formParams.append('specifiche', new Blob([JSON.stringify(specifiche)], { type: 'application/json'})) as any || formParams;
        }
        if (file_allegati) {
            file_allegati.forEach((element) => {
                formParams = formParams.append('file_allegati', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<OperazioneMassivaSuAttivitaExtraResponse>('post',`${this.basePath}/api/v1/operazione-massiva/superamento-massivo-attivita-extra-dottorandi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al SUPERVISORE/COSUPERVISORE di registrare massivamente il superamento delle attività trasversali di più dottorandi.
     * 
     * @param specifiche 
     * @param file_allegati 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public superamentoMassivoAttivitaTrasversaliDottorandiForm(specifiche?: SuperamentoMassivoAttivitaTrasversaliDottorandiRequest, file_allegati?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<OperazioneMassivaSuAttivitaTrasversaliResponse>;
    public superamentoMassivoAttivitaTrasversaliDottorandiForm(specifiche?: SuperamentoMassivoAttivitaTrasversaliDottorandiRequest, file_allegati?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperazioneMassivaSuAttivitaTrasversaliResponse>>;
    public superamentoMassivoAttivitaTrasversaliDottorandiForm(specifiche?: SuperamentoMassivoAttivitaTrasversaliDottorandiRequest, file_allegati?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperazioneMassivaSuAttivitaTrasversaliResponse>>;
    public superamentoMassivoAttivitaTrasversaliDottorandiForm(specifiche?: SuperamentoMassivoAttivitaTrasversaliDottorandiRequest, file_allegati?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (specifiche !== undefined) {
            formParams = formParams.append('specifiche', new Blob([JSON.stringify(specifiche)], { type: 'application/json'})) as any || formParams;
        }
        if (file_allegati) {
            file_allegati.forEach((element) => {
                formParams = formParams.append('file_allegati', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<OperazioneMassivaSuAttivitaTrasversaliResponse>('post',`${this.basePath}/api/v1/operazione-massiva/superamento-massivo-attivita-trasversali-dottorandi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
