import {Injectable} from '@angular/core';
import {
    CorsoPianoDiStudiPerExportViewImpl,
    StudenteCicloIdAndUtenteInfoView,
    UserCodiceFiscaleNomeCognomeAttivoView,
    UsersViewInfoView
} from "../../../api-clients/generated/services";
import {SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {studenteUIMappedI} from "../../modules/landing/budget-list/budget-list-utils";
import {CicloConfigurationService} from "./ciclo-configuration.service";

@Injectable({
    providedIn: 'root'
})
export class DialogInfoManagerService {

    constructor(
        private dialog: MatDialog,
        private cicloConfigurationService: CicloConfigurationService
    ) {
    }

    public openStudenteInfoDialogUVIV(studente: UsersViewInfoView, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.cognome || '') + ' ' + (studente.nome || '')?.toUpperCase(),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.cognome?.charAt(0)?.toUpperCase() || '') + (studente?.nome?.charAt(0)?.toUpperCase() || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.cognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.nome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.mail?.toLowerCase()
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.codiceFiscale?.toUpperCase()
                }
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    public openStudenteInfoDialogSCIUIV(studente: StudenteCicloIdAndUtenteInfoView, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente?.utente?.cognome || '') + ' ' + (studente?.utente?.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utente?.cognome?.charAt(0) || '') + (studente?.utente?.nome?.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utente?.cognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utente?.nome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.emailAteneo?.toLowerCase(),
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utente?.telefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.utente?.codiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.matricola,
                    hide: !this.cicloConfigurationService.showMatricola,
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    public openStudenteInfoDialogUCFNCAV(studente: UserCodiceFiscaleNomeCognomeAttivoView, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.cognome || '') + ' ' + (studente.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.cognome?.charAt(0) || '') + (studente?.nome?.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.cognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.nome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.mail?.toLowerCase(),
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.telefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.codiceFiscale
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    public openStudenteInfoDialogCPSEVI(studente: CorsoPianoDiStudiPerExportViewImpl, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.utenteCognome || '') + ' ' + (studente.utenteNome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utenteCognome?.charAt(0) || '') + (studente?.utenteNome.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utenteCognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utenteNome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.utenteMail?.toLowerCase()
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utenteTelefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.studenteCodiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.studenteMatricola,
                    hide: !this.cicloConfigurationService.showMatricola,
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

    public openStudenteInfoDialogSUIM(studente: studenteUIMappedI, profileImage?: SafeUrl) {
        const data: DialogInfoI = {
            imageSafeUrl: profileImage,
            showImage: true,
            title: (studente.utenteCognome || '') + ' ' + (studente.utenteNome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            imageIniziali: (studente?.utenteCognome?.charAt(0) || '') + (studente?.utenteNome.charAt(0) || ''),
            readonlyData: [
                {
                    key: 'user_list.surname',
                    value: studente?.utenteCognome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'user_list.name',
                    value: studente?.utenteNome,
                    pipe: UpperCasePipe
                },
                {
                    key: 'sign-up.email',
                    value: studente?.utenteMail?.toLowerCase(),
                },
                { //telefono
                    key: 'sign-up.phone_number',
                    value: studente?.utenteTelefono
                },
                {
                    key: 'sign-up.fiscal_code',
                    value: studente?.studenteCodiceFiscale
                },
                {
                    key: 'students_list.enrollment_number',
                    value: studente?.studenteMatricola,
                    hide: !this.cicloConfigurationService.showMatricola,
                },
            ]
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
        });
    }

}
