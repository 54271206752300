/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi documento
 */
export type ChiaveDocumentoEnum = 'relazione_fine_anno_dottorando' | 'presentazione_fine_anno_dottorando' | 'relazione_fine_anno_supervisore' | 'giudizio_commissione' | 'delibera_collegio';

export const ChiaveDocumentoEnum = {
    RelazioneFineAnnoDottorando: 'relazione_fine_anno_dottorando' as ChiaveDocumentoEnum,
    PresentazioneFineAnnoDottorando: 'presentazione_fine_anno_dottorando' as ChiaveDocumentoEnum,
    RelazioneFineAnnoSupervisore: 'relazione_fine_anno_supervisore' as ChiaveDocumentoEnum,
    GiudizioCommissione: 'giudizio_commissione' as ChiaveDocumentoEnum,
    DeliberaCollegio: 'delibera_collegio' as ChiaveDocumentoEnum
};