import {CicloConfigurationService} from "../../shared/service/ciclo-configuration.service";
import {ChiaveFunzionalitaEnum, FunzionalitaStatus} from "../../../api-clients/generated/services";


export function checkFunctionalityState(cicloConfigurationService: CicloConfigurationService,
                                        chiaveFunzionalita: ChiaveFunzionalitaEnum,
                                        passingStates: FunzionalitaStatus[] = [FunzionalitaStatus.ABILITATA]) {
    const functionalityEnablingState = cicloConfigurationService.getFunctionalityEnablingState(chiaveFunzionalita);
    return passingStates.includes(functionalityEnablingState?.stato);
}

export function checkSubfunctionalityState(cicloConfigurationService: CicloConfigurationService,
                                        chiaveFunzionalita: ChiaveFunzionalitaEnum,
                                        chiaveSottoFunzionalita: ChiaveFunzionalitaEnum,
                                        passingStates: FunzionalitaStatus[] = [FunzionalitaStatus.ABILITATA]) {
    const functionalityEnablingState = cicloConfigurationService.getSubFunctionalityEnablingState(chiaveFunzionalita, chiaveSottoFunzionalita);
    return passingStates.includes(functionalityEnablingState?.stato);
}

