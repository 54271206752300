import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';

@Injectable()
export class FuseSplashScreenService
{

    private loadingReasons = new Map<string, boolean>([['appInitRunning', true]]);


    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _router: Router
    )
    {
        // Hide it on the first NavigationEnd event
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                this.hide();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(loadingReason: 'appInitRunning' | 'cicloCorsoConfigurationFetching' = 'appInitRunning'): void
    {
        this.loadingReasons.set(loadingReason, true);
        this._document.body.classList.remove('fuse-splash-screen-hidden');
    }

    /**
     * Hide the splash screen
     */
    hide(loadingReason: 'appInitRunning' | 'cicloCorsoConfigurationFetching' = 'appInitRunning', delayMillis?: number,): void
    {
        if (!delayMillis) {
            if(!this.loadingForAnyOtherReason(loadingReason)) {
                this._document.body.classList.add('fuse-splash-screen-hidden');
            }
            this.loadingReasons.set(loadingReason, false);
        } else {
            setTimeout(() => {
                if(!this.loadingForAnyOtherReason(loadingReason)) {
                    this._document.body.classList.add('fuse-splash-screen-hidden');
                }
                this.loadingReasons.set(loadingReason, false);
            }, delayMillis)
        }
    }

    private loadingForAnyOtherReason(loadingReason: "appInitRunning" | "cicloCorsoConfigurationFetching") {
        return !![...this.loadingReasons.entries()].find(([reason, loading]) => loading && reason !== loadingReason);
    }
}
