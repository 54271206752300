/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi operazione
 */
export type ChiaveOperazioneEnum = 'richiesta_dottorando' | 'verifica_supervisore' | 'approvazione_supervisore' | 'verifica_coordinatore' | 'attestazione_coordinatore' | 'approvazione_coordinatore';

export const ChiaveOperazioneEnum = {
    RichiestaDottorando: 'richiesta_dottorando' as ChiaveOperazioneEnum,
    VerificaSupervisore: 'verifica_supervisore' as ChiaveOperazioneEnum,
    ApprovazioneSupervisore: 'approvazione_supervisore' as ChiaveOperazioneEnum,
    VerificaCoordinatore: 'verifica_coordinatore' as ChiaveOperazioneEnum,
    AttestazioneCoordinatore: 'attestazione_coordinatore' as ChiaveOperazioneEnum,
    ApprovazioneCoordinatore: 'approvazione_coordinatore' as ChiaveOperazioneEnum
};