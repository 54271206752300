import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, from, map, Observable, of, switchMap, tap, throwError} from 'rxjs';
import CryptoJS from 'crypto-js';
import {TimeService} from "../shared/service/time.service";
import moment from "moment";


@Injectable()
export class EncryptDecryptInterceptor implements HttpInterceptor {


    static secretKey: string = '';
    static isRequestCryptEnabled: boolean = false;
    static isResponseCryptEnabled: boolean = false;
    static isNonceEnabled: boolean = false;

    constructor(private timeService: TimeService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (requestsNoCrypt.some(path => req.url?.includes(path))) {
            console.log('Intercettata chiamata di login, refresh token, NON eseguo il crypt: ', req.url);
            return next.handle(req);
        }

        return this.processRequest(req).pipe(
            switchMap(modifiedRequest =>
                this.processResponse(req, modifiedRequest, next))
        );
    }

    private processRequest(req: HttpRequest<any>,): Observable<HttpRequest<any>>{
        let modifiedRequest = req;

        let reqHeaders;

        if (EncryptDecryptInterceptor.isRequestCryptEnabled) {
            const contentType = req.headers.get('Content-Type') || '';

            reqHeaders = req.headers;

            if (EncryptDecryptInterceptor.isNonceEnabled) {
                reqHeaders = reqHeaders.append('Nonce', ''+this.timeService.currentTimeForNonce());
            }

            let modifiedHeaders = reqHeaders;

            // Itera sugli header noti
            headersToCrypt.forEach((headerName) => {
                if (modifiedHeaders.has(headerName)) {
                    const originalValue = modifiedHeaders.get(headerName);
                    const modifiedValue = this.encryptBody(originalValue);
                    modifiedHeaders = modifiedHeaders.set(headerName, modifiedValue);
                }
            });

            // Clona la richiesta con i query parametri modificati
            modifiedRequest = modifiedRequest.clone({
                headers: modifiedHeaders,
            });

            if (modifiedRequest.params.keys().length > 0) {
                // Crea un nuovo HttpParams modificato
                let modifiedParams = modifiedRequest.params;

                modifiedRequest.params.keys().forEach((key) => {
                    const values = modifiedRequest.params.getAll(key);
                    const modifiedValues = values.map(value => this.encryptBody(value));
                    modifiedParams = modifiedParams.delete(key);
                    modifiedValues.forEach((modifiedValue) => {
                        modifiedParams = modifiedParams.append(key, modifiedValue);  // Aggiungi il valore modificato
                    });
                });

                // Clona la richiesta con i query parametri modificati
                modifiedRequest = modifiedRequest.clone({
                    params: modifiedParams,
                });
            }

            if (contentType.includes('application/json')) {
                modifiedRequest = modifiedRequest.clone({body: this.encryptBody(modifiedRequest.body)});
            } else {
                if (modifiedRequest.body instanceof HttpParams) {
                    const urlSearchParams = new URLSearchParams(modifiedRequest.body.toString());

                    if (urlSearchParams) {
                        let modifiedParams = new HttpParams();
                        // Iterare su chiavi e valori esistenti e modificarli
                        urlSearchParams.forEach((value, key) => {
                            // Modifica dei valori (puoi personalizzare questa logica)
                            const modifiedValue = this.encryptBody(value); // Esempio di modifica
                            modifiedParams = modifiedParams.append(key, modifiedValue); // Aggiungere chiave e valore modificato
                        });
                        modifiedRequest = modifiedRequest.clone({body: modifiedParams});
                    }
                } else if (modifiedRequest.body instanceof FormData) {
                    // Crea un array di promesse per leggere ogni parametro che è un Blob JSON
                    const paramReadPromises: Array<Promise<[string, any]>> = [];

                    const modifiedFormData = new FormData();
                    modifiedRequest.body.forEach((value: any, key: string) => {
                        if (value.type && value.type.includes('application/json')) {
                            const promise = value.text().then((text) => {
                                // Modifica il contenuto del JSON come desiderato
                                const parsedValue = this.encryptBody(text);

                                // Ritorna la chiave e il valore modificato
                                return [key, new Blob([parsedValue], {type: 'application/json'})] as [string, Blob];
                            });
                            paramReadPromises.push(promise);
                        } else {
                            paramReadPromises.push(Promise.resolve([key, value]));
                        }
                    });
                    return from(Promise.all(paramReadPromises)).pipe(
                        switchMap((modifiedParams) => {
                            // Crea un nuovo FormData con i parametri modificati
                            const newFormData = new FormData();
                            modifiedParams.forEach(([key, value]) => newFormData.append(key, value));

                            // Clona la richiesta con il nuovo FormData
                            modifiedRequest = modifiedRequest.clone({
                                body: newFormData
                            });

                            // debug code
                            new Response(req.body).text().then(reqBody => {
                                const reqType = EncryptDecryptInterceptor.isRequestCryptEnabled ? 'Yes' : 'No';
                                console.debug(`%c[REQ]%c[${req.method} %c${req.url}%c | Query params: ${req.params}]\n[Is Encrypted? ${reqType}]\n[Headers: %o]\n[Body: %o]\n[Request time: ${moment().utc(false)}]`,
                                    'color: Gray', 'color: black','color: DodgerBlue', 'color: black', this.getHeaders(reqHeaders), reqBody);
                            });

                            return of(modifiedRequest);
                        })
                    );
                }
            }
        }

        // debug code
        const reqType = EncryptDecryptInterceptor.isRequestCryptEnabled ? 'Yes' : 'No';
        console.debug(`%c[REQ]%c[${req.method} %c${req.url}%c | Query params: ${req.params}]\n[Is Encrypted? ${reqType}]\n[Headers: %o]\n[Body: %o]\n[Request time: ${moment().utc(false)}]`,
            'color: Gray', 'color: black', 'color: DodgerBlue', 'color: black', this.getHeaders(reqHeaders), req.body);

        return of(modifiedRequest);

    }

    private processResponse(req: HttpRequest<any>, modifiedRequest: HttpRequest<any>, next: HttpHandler) {

        if (EncryptDecryptInterceptor.isResponseCryptEnabled) {
            return this.handleRequestWithResponseDecrypt(modifiedRequest, next);
        } else {
            return next.handle(modifiedRequest).pipe(
                catchError(err => {
                    console.debug(`%c[RESP]%c[${req.method} %c${req.url}%c]\n[Is Encrypted? No]\n[Headers: %o]\n%c[Error: %o]`,
                        'color: Green', 'color: black', 'color: DodgerBlue', 'color: black', this.getHeaders(err.headers), 'color: red', err)
                    return throwError(err);
                }),
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        console.debug(`%c[RESP]%c[${req.method} %c${req.url}%c]\n[Is Encrypted? No]\n[Headers: %o]\n[Body: %o]`,
                            'color: Green', 'color: black', 'color: DodgerBlue', 'color: black', this.getHeaders(event.headers), event.body)
                    }
                })
            );
        }
    }

    handleRequestWithResponseDecrypt(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                // decrypting errors
                if (!!err.error.value) {
                    err.error = this.decryptBody(err.error.value);
                }
                console.debug(`%c[RESP]%c[${req.method} %c${req.url}%c]\n[Is Encrypted? Yes]\n[Headers: %o]\n%c[Error: %o]`,
                    'color: Green', 'color: black', 'color: DodgerBlue', 'color: black', err.headers, 'color: red', err);
                return throwError(err);
            }),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // decrypting responses body
                    if (!!event.body && !!event.body.value) {
                        const decryptedBody = this.decryptBody(event.body.value);
                        console.debug(`%c[RESP]%c[${req.method} %c${req.url}%c]\n[Is Encrypted? Yes]\n[Headers: %o]\n[Body: %o]`,
                            'color: Green', 'color: black', 'color: DodgerBlue', 'color: black', this.getHeaders(event.headers), decryptedBody)
                        return event.clone({body: decryptedBody});
                    } else {
                        console.debug(`%c[RESP]%c[${req.method} %c${req.url}%c]\n[Is Encrypted? Yes]\n[Headers: %o]`,
                            'color: Green', 'color: black', 'color: DodgerBlue', 'color: black', this.getHeaders(event.headers))
                        return event.clone();
                    }
                }
                return event;
            })
        );
    }

    private decryptBody(encryptedBody: any): any {
        try {
            // Supponiamo che il body sia una stringa codificata in Base64
            const encryptedBase64 = encryptedBody; // Modifica secondo il formato della risposta
            const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedBase64);

            // Decifra utilizzando AES
            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: encryptedBytes },
                CryptoJS.enc.Utf8.parse(EncryptDecryptInterceptor.secretKey),
                {
                    mode: CryptoJS.mode.ECB, // Modalità AES, verifica se ECB o CBC
                    padding: CryptoJS.pad.Pkcs7,
                }
            );

            // Converte il risultato in una stringa UTF-8
            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

            // Supponiamo che il risultato sia JSON: parsalo
            return JSON.parse(decryptedText);
        } catch (error) {
            console.error('Errore nella decriptazione del body:', error);
            return encryptedBody;
        }
    }

    private encryptBody(body: any): string {
        try {
            // Converte il body in una stringa JSON se non è già una stringa
            const bodyString = typeof body === 'string' ? body : JSON.stringify(body);

            // Cifra il body usando AES
            const encrypted = CryptoJS.AES.encrypt(
                bodyString,
                CryptoJS.enc.Utf8.parse(EncryptDecryptInterceptor.secretKey),
                {
                    mode: CryptoJS.mode.ECB, // Modalità AES (verifica se ECB è quella corretta)
                    padding: CryptoJS.pad.Pkcs7,
                }
            );

            // Converte il risultato in Base64
            const encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

            return encryptedBase64;
        } catch (error) {
            console.error('Errore nella crittografia del body:', error);
            throw error; // Puoi decidere di gestire l'errore diversamente
        }
    }

    static generateEncryptionKey(length: number): string {
        // MAX 255 CHARS!
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            const randomBytes = CryptoJS.lib.WordArray.random(1); // Generate 1 random byte
            const hexString = randomBytes.toString(CryptoJS.enc.Hex);
            const decimalValue = parseInt(hexString, 16); // Convert hex to decimal
            result += characters.charAt(decimalValue % charactersLength);
        }

        return result;
    }


    private getHeaders(headers: HttpHeaders) {
        const headersObj = {};
        headers?.keys()?.forEach(key => {
            headersObj[key] = headers.get(key);
        });
        return headersObj;
    }
}

export const requestsNoCrypt = [
    'login',
    'token',
    'now',
    'secret'
];

export const headersToCrypt = [
    'Authorization',
    'Accept-Language',
    'Dottorandi-Corso-Di-Studi-Codice',
    'Dottorandi-User-Ruolo',
    'Dottorandi-Dottorato-Ciclo',
    'Dottorandi-User-Sottoruolo',
    'Nonce'
];
