import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {trim} from "lodash";
import {DialogWrapI} from "../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AuthorityType,
    CollegioDocentiInfoView,
    DeliberaDiProgrammazioneMobilitaInfoView
} from "../../../../../../api-clients/generated/services";
import {italianDateWithFormat} from 'app/shared/utils/utils-date';
import moment from "moment";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";
import {StudentDetailsService} from "../../../student-details/student-details.service";
import {CicloConfigurationService} from "../../../../../shared/service/ciclo-configuration.service";


export interface DialogDettaglioDeliberaProgrammazioneI extends DialogWrapI {
    deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView;
    hideStudents?: boolean;
}

@Component({
    selector: 'app-dialog-dettaglio-delibera-programmazione',
    templateUrl: './dialog-dettaglio-delibera-programmazione.component.html',
    styleUrls: ['./dialog-dettaglio-delibera-programmazione.component.scss']
})
export class DialogDettaglioDeliberaProgrammazioneComponent extends AbstractDefaultComponent implements OnInit {

    protected readonly italianDateWithFormat = italianDateWithFormat;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDettaglioDeliberaProgrammazioneI,
                public dialogRef: MatDialogRef<DialogDettaglioDeliberaProgrammazioneComponent>,
                private fuseConfirmationService: FuseConfirmationService,
                private router: Router,
                private studentDetailsService: StudentDetailsService,
                protected cicloConfigurationService: CicloConfigurationService,
    ) {
        super();
    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }


    sortList(list): [] {
        return list.sort((a, b) => {
            if (a.cognome && b.cognome) {
                return a.cognome.localeCompare(b.cognome);
            } else {
                return list;
            }
        });
    }

    buildNomeCompleto(member: CollegioDocentiInfoView): string {
        return trim((member?.cognome || '') + ' ' + (member?.nome || ''));
    }

    protected readonly moment = moment;
    protected readonly AuthorityType = AuthorityType;

    showDelibera() {
        this.fuseConfirmationService.openDialogDelibera({
            delibera: this.data?.deliberaDettaglio?.delibera,
            codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3,
        });
    }

    goToStudentPage(idUtente: string) {
        this.dialogRef.close();
        const url = this.router.serializeUrl(
            this.router.createUrlTree([PathEnum.STUDENTS, idUtente, PathEnum.MOBILITA])
        );
        window.open(url, '_blank');
    }

}
