<ng-container *transloco="let t">
    <ng-container
        *ngIf="cicloConfigurationService.getFunctionalityEnablingState(ChiaveFunzionalitaEnum.SuperamentoAnno)?.stato === FunzionalitaStatus.ABILITATA">
        <div class="w-full p-4 grid grid-cols-4 rounded-lg mt-4 mb-3 bg-slate-100 text-secondary"
             *ngIf="getSuperamentoAnnoConfig() as configuration">
            <div class="flex flex-row gap-3 items-center col-span-3">
                <mat-icon>event_available</mat-icon>
                <div class="font-medium">{{t('year_passing.year_passing_relation')}}</div>
            </div>
            <div>{{(configuration?.cfuRelazioneDiFineAnno) || 0}} <span class="text-sm">CFU</span></div>
        </div>
    </ng-container>
</ng-container>
