import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {AuthorityType, ChiaveFunzionalitaEnum, FunzionalitaStatus} from "../../../api-clients/generated/services";
import {LocalStorageService} from "../../shared/service/local-storage.service";
import {PathEnum} from "../../app.routing";
import {map, Observable, of, switchMap} from "rxjs";
import {AppInitService} from "../../shared/service/app-init.service";
import {first} from "rxjs/operators";
import {CicloCorsoRuoloInterface} from "../../shared/interface/CicloCorsoRuoloInterface";
import {checkFunctionalityState} from "./guards-utils";
import {CicloConfigurationService} from "../../shared/service/ciclo-configuration.service";
import {LogoutService} from "../../shared/service/logout.service";
import {CicloConfigDependantGuard} from "./ciclo-config-dependant.guard";


@Injectable({
    providedIn: 'root'
})
export class MissionsManagementGuard extends CicloConfigDependantGuard {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private localStorageService: LocalStorageService,
        private appInitService: AppInitService,
        protected cicloConfigurationService: CicloConfigurationService,
        private logoutService: LogoutService,
    ) {
        super(cicloConfigurationService)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */


    _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const cicloCorsoRuolo = this.localStorageService.getCicloCorsoRuolo(true);
        const canActivateAccordingEnablingState = checkFunctionalityState(this.cicloConfigurationService, ChiaveFunzionalitaEnum.Missioni);
        if(!canActivateAccordingEnablingState){
            this.logoutService.goToHome();
            return of(false);
        }
        console.log('cicloCorsoRuolo from richieste da delibnerare guard', cicloCorsoRuolo)
        if (cicloCorsoRuolo?.ruolo === AuthorityType.DOCENTE || cicloCorsoRuolo?.ruolo === AuthorityType.PERSONALEPTA) {
            return this.appInitService.currentSottoruoliUtente$.pipe(
                first(),
                map(sottoruoli => {
                    if(!!sottoruoli) {
                        // condition for users with subroles
                        const canActivate = MissionsManagementGuard.accessConditionForSubroles(sottoruoli, true, true);
                        if(!canActivate) {
                            this._router.navigate([PathEnum.HOME]);
                        }
                        return canActivate;
                    }
                })
            );
        } else {
            // condition for users without subroles
            const canActivate = MissionsManagementGuard.accessConditionForRoles(cicloCorsoRuolo);
            if(!canActivate) {
                this._router.navigate([PathEnum.HOME]);
            }
            return of(canActivate);
        }
    }

    static accessConditionForRoles(cicloCorsoRuolo: CicloCorsoRuoloInterface): boolean {
        return cicloCorsoRuolo?.ruolo === AuthorityType.SUPERADMIN
            || cicloCorsoRuolo?.ruolo === AuthorityType.ADMIN;
    }

    static accessConditionForSubroles(sottoruoli: AuthorityType[], inPathMenuGroup = false, inRecapMenuGroup = false): boolean {
        return !!sottoruoli?.find(s =>
            (s === AuthorityType.GRUPPODICOORDINAMENTO && inPathMenuGroup)
            || (s === AuthorityType.COORDINATORE && inPathMenuGroup)
            || (s === AuthorityType.DIRETTOREDIDIPARTIMENTO && inPathMenuGroup)
            || (s === AuthorityType.SUPERVISORE && inPathMenuGroup)
            || (s === AuthorityType.COSUPERVISORE && inPathMenuGroup)
            || (s === AuthorityType.GRUPPOPTA && inRecapMenuGroup)
            || (s === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO && inRecapMenuGroup)
            || (s === AuthorityType.COLLEGIODOCENTI && inPathMenuGroup));
    }

    static accessCondition(cicloCorsoRuolo: CicloCorsoRuoloInterface,
                           cicloConfigurationService: CicloConfigurationService,
                           passingStates: FunzionalitaStatus[] = [FunzionalitaStatus.ABILITATA],
                           sottoruoli: AuthorityType[],
                           inPathMenuGroup = false,
                           inRecapMenuGroup = false) {
        return  checkFunctionalityState(cicloConfigurationService, ChiaveFunzionalitaEnum.Missioni, passingStates)
            && (MissionsManagementGuard.accessConditionForRoles(cicloCorsoRuolo) || MissionsManagementGuard.accessConditionForSubroles(sottoruoli, inPathMenuGroup, inRecapMenuGroup));
    }


}
