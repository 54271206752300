import {AbstractDefaultComponent} from "app/shared/abstracts/abstract-default-component/abstract-default-component";
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum
} from "../../../shared/components/table/model/generic-table-model";
import {ActivatedRoute, Router} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {
    AuthorityType,
    DeliberaDiProgrammazioneMobilitaFiltered,
    DeliberaDiProgrammazioneMobilitaInfoView,
    DelibereService,
    GetDeliberaDTO,
    MediaService,
    MobilitaRequestSelectFormValuesDTO,
    PageDeliberaDiProgrammazioneMobilitaFiltered,
    PagePeriodoDiMobilitaStudenteInfoView,
    PageStudenteCicloUtenteViewImpl,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    RichiestaDiRetribuzioneMobilitaInfoView,
    StudenteCicloIdAndUtenteInfoView,
    StudenteCicloUtenteViewImpl,
    StudentiCicloService,
    TipoPeriodoEnum
} from "../../../../api-clients/generated/services";
import {DialogInfoComponent, DialogInfoI} from "../../../shared/components/dialog-info/dialog-info.component";
import {get, isEmpty} from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {PathEnum} from "../../../app.routing";
import {TranslocoService} from "@ngneat/transloco";
import {
    catchError,
    finalize,
    map,
    Observable,
    ObservedValueOf,
    of,
    OperatorFunction,
    Subscription,
    take,
    takeUntil
} from "rxjs";
import {FuseMediaWatcherService} from "../../../../@fuse/services/media-watcher";
import {MatDrawer} from "@angular/material/sidenav";
import {filter, first, switchMap, tap} from "rxjs/operators";
import {MobilitaFilterManagerService, MobilitaFilters} from "../../../shared/service/mobilita-filter-manager.service";
import {AppInitService} from "../../../shared/service/app-init.service";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {LogoutService} from "../../../shared/service/logout.service";
import {
    MobilitaSidebarFilterContainerComponent
} from "./mobilita-sidebar-filter-container/mobilita-sidebar-filter-container.component";
import {FormControl, FormGroup} from "@angular/forms";
import moment from "moment/moment";
import {UpperCasePipe} from "@angular/common";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import * as fs from "file-saver";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {LocalStorageService} from "../../../shared/service/local-storage.service";
import {ExcelService} from "../../../shared/service/excel.service";
import {
    getLanguagePathByMobilitaStatus,
    getLanguagePathByStudenteCicloMotivoStatus,
    getLanguagePathByStudenteCicloStatus,
    makeFilenameFromFE,
    openFileInBlankWindow
} from "../../../shared/utils/utils";
import {
    buildAutorizzazioniTableConfiguration,
    buildDelibereTableConfiguration,
    buildMobilityRequestsTableConfiguration,
    infoAggiuntivePeriodoMobilitaDashboard,
    mapPeriodiForExcel,
    PERIODI_EXCEL_SHEET_CONFIG
} from "./mobility-dashboard-utils";
import {MatTabGroup} from "@angular/material/tabs";
import {GenericTableComponent} from "../../../shared/components/table/generic-table/generic-table.component";
import {CicloCorsoRuoloInterface} from "../../../shared/interface/CicloCorsoRuoloInterface";
import {
    DialogMobilitaDashboardInfoComponent,
    DialogMobilitaDashboardInfoI
} from "./dialog-mobilita-dashboard-info/dialog-mobilita-dashboard-info.component";
import {RicercaFilterManagerService} from "../../../shared/service/ricerca-filter-manager.service";
import {
    RicercaSidebarFilterContainerComponent
} from "./ricerca-sidebar-filter-container/ricerca-sidebar-filter-container.component";
import {DelibereFilters, DelibereFiltersManagerService} from "app/shared/service/delibere-filter-manager.service";
import {
    DelibereSidebarFilterContainerComponent
} from "./delibere-sidebar-filter-container/delibere-sidebar-filter-container.component";
import {
    DialogDettaglioDeliberaProgrammazioneComponent,
    DialogDettaglioDeliberaProgrammazioneI
} from "./dialog-edit-delibera-programmazione/dialog-dettaglio-delibera-programmazione/dialog-dettaglio-delibera-programmazione.component";
import {
    DialogEditDeliberaProgrammazioneComponent,
    DialogEditDeliberaProgrammazioneI
} from "./dialog-edit-delibera-programmazione/dialog-edit-delibera-programmazione.component";
import {HttpResponse} from "@angular/common/http";
import {StudentDetailsService} from "../student-details/student-details.service";
import {GenericComponentDialogConfig} from "../../../layout/common/generic-components/generic-components.interface";
import {
    GenericDialogComponent
} from "../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {
    MobilitaValidazioneDettaglioComponent
} from "../../../shared/components/mobilita-validazione-dettaglio/mobilita-validazione-dettaglio.component";
import {
    AutorizzazioniSidebarFilterContainerComponent
} from "../../../shared/components/autorizzazioni-sidebar-filter-container/autorizzazioni-sidebar-filter-container.component";
import {
    AutorizzazioniFilterManagerService,
    AutorizzazioniFilters
} from "../../../shared/service/autorizzazioni-filter-manager.service";
import {DialogInfoManagerService} from "../../../shared/service/dialog-info-manager.service";
import {CicloConfigurationService} from "../../../shared/service/ciclo-configuration.service";

export const DEFAULT_PAGE_SIZE = 10;

class PeriodsTabData {
    tabLabel: string;
    tabAriaLabel: string;
    tableConfiguration: GenericTableConfigurationModel;
    tipologiaPeriodi: TipoMobilitaDashboardTab;
    page: number;
    pageSize: number;
}

export enum TipoMobilitaDashboardTab {
    SOGGIORNOESTERO="SOGGIORNO_ESTERO",
    RICERCA="RICERCA",
    DELIBERE="DELIBERE",
    AUTORIZZAZIONI="AUTORIZZAZIONI",
}

@Component({
    selector: 'app-mobility-dashboard',
    templateUrl: './mobility-dashboard.component.html',
    styleUrls: ['./mobility-dashboard.component.scss']
})
export class MobilityDashboardComponent extends AbstractDefaultComponent implements OnDestroy, OnInit {

    loading: boolean;
    mobilityRequestsListTableConfiguration: GenericTableConfigurationModel;
    protected readonly Object = Object;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;
    currentFilterListChipLabels = new Map<TipoMobilitaDashboardTab, Array<string>>();
    drawerOpened: boolean = false;
    @ViewChild('drawer') drawer: MatDrawer;
    mobilitaFieldsLabelMap: Map<string, string>;
    deliberaFieldsLabelMap: Map<string, string>;
    autorizzazioniFieldsLabelMap: Map<string, string>;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    showMiniLoader: boolean = false;
    mobilitaSidebarContainerComponent: MobilitaSidebarFilterContainerComponent;
    ricercaSidebarContainerComponent: RicercaSidebarFilterContainerComponent;
    delibereSidebarContainerComponent: DelibereSidebarFilterContainerComponent;
    autorizzazioniSidebarContainerComponent: AutorizzazioniSidebarFilterContainerComponent;
    @ViewChild('mobilitaSidebarContainer', { static: false }) set mobilitaSidebarContainer(c: MobilitaSidebarFilterContainerComponent){
        if(!!c) {
            this.mobilitaSidebarContainerComponent = c;
        }
    }
    @ViewChild('ricercaSidebarContainer', { static: false }) set ricercaSidebarContainer(c: RicercaSidebarFilterContainerComponent){
        if(!!c) {
            this.ricercaSidebarContainerComponent = c;
        }
    }
    @ViewChild('delibereSidebarContainer', { static: false }) set delibereSidebarContainer(c: DelibereSidebarFilterContainerComponent){
        if(!!c) {
            this.delibereSidebarContainerComponent = c;
        }
    }
    @ViewChild('autorizzazioniSidebarContainer', { static: false }) set autorizzazioniSidebarContainer(c: AutorizzazioniSidebarFilterContainerComponent){
        if(!!c) {
            this.autorizzazioniSidebarContainerComponent = c;
        }
    }

    isFirstTime: boolean;
    currentLanguage: string;
    currentRuolo: AuthorityType;
    sottoruoli: AuthorityType[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    currentSubroleCanAccess: boolean;
    isSubroleSelected: boolean;
    mustChooseSubrole: boolean;
    protected readonly AuthorityType = AuthorityType;
    mobilitaTabData: PeriodsTabData;
    ricercaTabData: PeriodsTabData;
    delibereTabData: PeriodsTabData;
    autorizzazioniTabData: PeriodsTabData;
    selectedTabTipo: TipoMobilitaDashboardTab = TipoMobilitaDashboardTab.SOGGIORNOESTERO;
    private filterChangesSubscription = new Map<TipoMobilitaDashboardTab, Subscription>();
    _tabsGroup: MatTabGroup;
    ricercaFieldsLabelMap: Map<string, string>;

    @ViewChild(MatTabGroup) set tabsGroup (tabsGroup: MatTabGroup)  {
        if(tabsGroup && !this._tabsGroup) {
            this._tabsGroup = tabsGroup;
            this.handleFragmentNavigation(tabsGroup)
        }
    }
    private table: GenericTableComponent;
    @ViewChild(GenericTableComponent) set genericTable(gt: GenericTableComponent) {
        this.table = gt;
    }

    constructor(private router: Router,
                private translocoService: TranslocoService,
                private dialog: MatDialog,
                private _fuseMediaWatcherService: FuseMediaWatcherService,
                private mobilitaFilterManagerService: MobilitaFilterManagerService,
                private ricercaFilterManagerService: RicercaFilterManagerService,
                private delibereFilterManagerService: DelibereFiltersManagerService,
                private autorizzazioniFilterManagerService: AutorizzazioniFilterManagerService,
                private appInitService: AppInitService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                private fuseConfirmationService: FuseConfirmationService,
                private logoutService: LogoutService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private localStorageService: LocalStorageService,
                private excelService: ExcelService,
                private activatedRoute: ActivatedRoute,
                private delibereService: DelibereService,
                private studentiCicloService: StudentiCicloService,
                private dialogInfoManagerService: DialogInfoManagerService,
                private cicloConfigurationService: CicloConfigurationService

    ) {
        super();
        this.mobilitaFieldsLabelMap = this.mobilitaFilterManagerService.fieldsLabelMap;
        this.ricercaFieldsLabelMap = this.ricercaFilterManagerService.fieldsLabelMap;
        this.deliberaFieldsLabelMap = this.delibereFilterManagerService.fieldsLabelMap;
        this.autorizzazioniFieldsLabelMap = this.autorizzazioniFilterManagerService.fieldsLabelMap;
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                this.drawerOpened = false;
            });
        if (!this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes(PathEnum.MOBILITA)) {
            this.getMainFormGroup(this.selectedTabTipo)?.reset({emitEvent: false});
        }
        this.translocoService.langChanges$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(lang => this.currentLanguage = lang);
    }

    ngOnInit(): void {
        this.initTabsData();
        this.getSottoruoliRequest();
        this.subscribeToSottoruoliValueChange();
    }

    handleFragmentNavigation(tabsGroup){
        this.activatedRoute.fragment.pipe(takeUntil(this.destroy$), first()).subscribe((fragment: string) => {
            console.log('typed fragment', fragment);
            const tabs = (tabsGroup?._tabs as any)._results;
            console.log(tabs)
            let matchIndex = tabs?.findIndex(x => x.ariaLabel === fragment);
            console.log('matched fragment with tab at position', matchIndex)
            if (matchIndex >= 0) {
                tabsGroup.selectedIndex = matchIndex;
            }
        });
    }

    getSottoruoliRequest(): void {
        this.loading = true;
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            tap(value => this.currentRuolo = value?.ruolo),
            switchMap((value) => {
                if (this.isDocenteOrPTA(this.currentRuolo)) {
                    return this.appInitService.currentSottoruoliUtente$.pipe(first());
                } else {
                    return of(null);
                }
            }),
            tap((sottoruoli: AuthorityType[]) => {
                if (!!sottoruoli) {
                    this.sottoruoli = sottoruoli;
                    this.setCurrentSottoruolo();
                    this.mustChooseSubrole = true//this.sottoruoli?.length > 1;
                    this.loading = false;
                    if(this.ctrlSelectFormControl?.value === AuthorityType.GRUPPOPTA
                        || this.ctrlSelectFormControl?.value === AuthorityType.COORDINATORE
                        || this.ctrlSelectFormControl?.value === AuthorityType.GRUPPODICOORDINAMENTO
                        || this.ctrlSelectFormControl?.value === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO) {
                        // doing the request when the page load for the first time
                        if(!this.filterChangesSubscription.get(this.selectedTabTipo)) {
                            this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, true));
                        } else {
                            this.reset(this.selectedTabTipo);
                        }
                        this.currentSubroleCanAccess = true;
                    } else {
                        this.currentSubroleCanAccess = false;
                    }
                } else {
                    this.mustChooseSubrole = false;
                    // if you see the page and there are no sottoruoli you can execute the request
                    // doing the request when the page load for the first time
                    if(!this.filterChangesSubscription.get(this.selectedTabTipo)) {
                        this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, true));
                    } else {
                        this.reset(this.selectedTabTipo);
                    }
                    this.currentSubroleCanAccess = true;
                }
            }),
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value) => {
                },
                error: (err) => {
                    this.loading = false;
                    this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                        () => this.logoutService.goToHome(),
                        () => this.getSottoruoliRequest(),
                        'common.go_to_home',
                        err?.error?.message);
                }
            }
        );
    }

    private subscribeToSottoruoliValueChange() {
        this.ctrlSelectFormControl.valueChanges.subscribe(sottoruolo => {
            this.initTabsData();
            this.localStorageService.setSottoruoloCiclo(sottoruolo);
            this.appInitService.setCicloCorsoRuoloSelected$(this.appInitService.cicloCorsoRuoloSelected).subscribe();
            if(sottoruolo === AuthorityType.GRUPPOPTA
                || sottoruolo === AuthorityType.COORDINATORE
                || sottoruolo === AuthorityType.GRUPPODICOORDINAMENTO
                || sottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO) {
                // if not already subscribed to filter changes subscribe, else just reset the filters (which will trigger the periodi search request)
                if(!this.filterChangesSubscription.get(this.selectedTabTipo)) {
                    this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, true));
                } else {
                    this.reset(this.selectedTabTipo);
                }
                this.currentSubroleCanAccess = true;
            } else {
                this.mobilityRequestsListTableConfiguration = undefined;
                this.currentSubroleCanAccess = false;
                this.selectedTabTipo = TipoMobilitaDashboardTab.SOGGIORNOESTERO;
            }
            this.isSubroleSelected = true;
        });
    }

    setCurrentSottoruolo(): void {
        const savedSottoruolo: AuthorityType = this.localStorageService?.dipartimentoRuoloCiclo?.sottoruolo;
        if (!!savedSottoruolo) {
            const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
            if (findedSottoruolo) {
                this.ctrlSelectFormControl.setValue(savedSottoruolo, {emitEvent: false});
                this.isSubroleSelected = true;
            }
        }
        this.localStorageService.setSottoruoloCiclo(this.ctrlSelectFormControl?.value);
        this.appInitService.setCicloCorsoRuoloSelected$(this.appInitService.cicloCorsoRuoloSelected).subscribe();
    }

    isDocenteOrPTA(ruolo?: AuthorityType): boolean {
        return ruolo === AuthorityType.DOCENTE || ruolo === AuthorityType.PERSONALEPTA;
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup(this.selectedTabTipo)?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup(this.selectedTabTipo)?.value);
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            const fieldsMap = this.selectedTabTipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO ? this.mobilitaFieldsLabelMap :
                (this.selectedTabTipo === TipoMobilitaDashboardTab.RICERCA ? this.ricercaFieldsLabelMap :
                    (this.selectedTabTipo === TipoMobilitaDashboardTab.AUTORIZZAZIONI ? this.autorizzazioniFieldsLabelMap : this.deliberaFieldsLabelMap));
            return filteredEntries.map(([key, value]) => {
                const translatedKey: string = get(translation, fieldsMap.get(key), key);
                if(key === 'codiceNazioneStruttura') {
                    value = this.selectValues?.nazioniEstere?.find(n => n.codice === value)?.descrizione;
                } else if(key === 'codiceTipologiaMobilita') {
                    value = this.selectValues?.tipologieMobilita?.find(n => n.codice === value)?.descrizione;
                } else if(key === 'codiceTipologiaBonus') {
                    value = this.selectValues?.tipologieBonus?.find(n => n.numeroTipologiaBonus === value)?.descrizioneTipologiaBonus;
                } else if(key === 'codiceTipoInterventoSupporto') {
                    value = this.selectValues?.tipiInterventoSupporto?.find(n => n.codice === value)?.descrizione;
                } else if(key === 'dataFineNull') {
                    return translatedKey;
                } else if(key === 'dataUltimaModifica') {
                    value = moment(value, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
                } else if(key === 'stato') {
                    value = get(translation, getLanguagePathByMobilitaStatus(value), null);
                } else if (key === 'statoCarriera') {
                    value = value.map(e => get(translation, getLanguagePathByStudenteCicloStatus(e), e))?.join(', ');
                } else if (key === 'statoCarrieraMotivazione') {
                    value = value.map(e => get(translation, getLanguagePathByStudenteCicloMotivoStatus(e), e))?.join(', ');
                }
                return translatedKey + ': ' + value;
            })
        } else {
            return [];
        }
    }

    getDataRequest(tipoPeriodi: TipoMobilitaDashboardTab, isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10): void {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        let request: (filters, pageSize) => Observable<any>;
        if(tipoPeriodi === TipoMobilitaDashboardTab.SOGGIORNOESTERO){
            request = (filters, pageSize) => this.getMobilityPeriods$(TipoPeriodoEnum.SOGGIORNOESTERO, isFirstTime, filters, page, pageSize);
        } else if (tipoPeriodi === TipoMobilitaDashboardTab.RICERCA){
            request = (filters, pageSize) => this.getMobilityPeriods$(TipoPeriodoEnum.RICERCA, isFirstTime, filters, page, pageSize);
        } else if (tipoPeriodi === TipoMobilitaDashboardTab.DELIBERE){
            request = (filters, pageSize) => this.getDelibere$(false, filters as DelibereFilters, page, pageSize as number);
        } else {
            request = (filters, pageSize) => this.getAutorizzazioni$(false, filters as AutorizzazioniFilters, page, pageSize as number);
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap((value) => request(filters, size))
        ).subscribe({
            next: (value) => {
                if(tipoPeriodi === TipoMobilitaDashboardTab.SOGGIORNOESTERO) {
                    this.mobilitaFilterManagerService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                    this.mobilitaFilterManagerService.filterApplied$.next(this.getMainFormGroup(this.selectedTabTipo).getRawValue());
                } else if(tipoPeriodi === TipoMobilitaDashboardTab.RICERCA) {
                    this.ricercaFilterManagerService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                    this.ricercaFilterManagerService.filterApplied$.next(this.getMainFormGroup(this.selectedTabTipo).getRawValue());
                } else if(tipoPeriodi === TipoMobilitaDashboardTab.DELIBERE) {
                    this.delibereFilterManagerService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                    this.delibereFilterManagerService.filterApplied$.next(this.getMainFormGroup(this.selectedTabTipo).getRawValue());
                } else if(tipoPeriodi === TipoMobilitaDashboardTab.AUTORIZZAZIONI) {
                    this.autorizzazioniFilterManagerService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                    this.autorizzazioniFilterManagerService.filterApplied$.next(this.getMainFormGroup(this.selectedTabTipo).getRawValue());
                }
                this.currentFilterListChipLabels.set(tipoPeriodi, this.getListChipsLabel());
            },
            error: (err) => {
                console.log(err)
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.goToHome(),
                    () => this.getDataRequest(tipoPeriodi, isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getMobilityPeriods$(tipoPeriodi: TipoPeriodoEnum, isFirstTime?: boolean, filters?: MobilitaFilters, page: number = 0, size: number = DEFAULT_PAGE_SIZE,): Observable<PagePeriodoDiMobilitaStudenteInfoView> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.periodiDiMobilitaService.getPeriodiDiMobilitaStudenteForm(
            !!filters?.codiceMobilita ? filters.codiceMobilita : undefined,
            tipoPeriodi,
            !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
            undefined,
            undefined,
            !!filters?.stato ? filters.stato : undefined,
            !!filters?.statoSvolgimento ? filters?.statoSvolgimento : undefined,
            !!filters?.periodoDa ? filters.periodoDa : undefined,
            !!filters?.periodoA ? filters.periodoA : undefined,
            !!filters?.annoAccademico ? filters.annoAccademico : undefined,
            !!filters?.nominativoStrutturaEsteraOspitante ? filters.nominativoStrutturaEsteraOspitante : undefined,
            !!filters?.codiceNazioneStruttura ? filters.codiceNazioneStruttura : undefined,
            !!filters?.dataFineNull ? filters.dataFineNull : undefined,
            !!filters?.codiceTipologiaMobilita ? filters.codiceTipologiaMobilita : undefined,
            !!filters?.codiceTipologiaBonus ? filters.codiceTipologiaBonus : undefined,
            !!filters?.codiceTipoInterventoSupporto ? filters.codiceTipoInterventoSupporto : undefined,
            !!filters?.dataUltimaModifica ? moment(filters.dataUltimaModifica).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            undefined,
            undefined,
            page, size, undefined
        ).pipe(
            tap((pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView) => {
                const activeLang = this.translocoService.getActiveLang();
                const translation = this.translocoService.getTranslation().get(activeLang);
                const pageSize = this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO ? this.mobilitaTabData.pageSize : this.ricercaTabData.pageSize;
                const tableConfig = buildMobilityRequestsTableConfiguration(pagePeriodi, pageSize, this.getSottoruolo(), this.currentRuolo, translation, tipoPeriodi === TipoPeriodoEnum.RICERCA, );
                if(tipoPeriodi === TipoPeriodoEnum.SOGGIORNOESTERO) {
                    this.mobilitaTabData.tableConfiguration = tableConfig;
                } else {
                    this.ricercaTabData.tableConfiguration = tableConfig;
                }
                if(page == 0 && this.table?.getPaginator()) {
                    this.table.getPaginator().pageIndex = 0;
                }
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.loading = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        )
    }

    getDelibere$(isFirstTime?: boolean, filters?: DelibereFilters, page: number = 0, size: number = DEFAULT_PAGE_SIZE): Observable<PagePeriodoDiMobilitaStudenteInfoView> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.periodiDiMobilitaService.searchDelibereDiProgrammazioneMobilitaForm(
            !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
            !!filters?.codiceFiscaleStudente ? filters.codiceFiscaleStudente : undefined,
            !!filters?.matricolaStudente ? filters.matricolaStudente : undefined,
            !!filters?.numeroDelibera ? filters.numeroDelibera : undefined,
            !!filters?.dataDelibera ? filters.dataDelibera : undefined, // moment(filters.dataDelibera).format('DD/MM/YYYY HH:mm') as unknown as Date
            page, size
        ).pipe(
            tap((pageDelibere: PageDeliberaDiProgrammazioneMobilitaFiltered) => {
                const pageSize= this.delibereTabData.pageSize;
                const tableConfig = buildDelibereTableConfiguration(pageDelibere, pageSize, this.getSottoruolo(), this.currentRuolo);
                this.delibereTabData.tableConfiguration = tableConfig;
                if(page == 0 && this.table?.getPaginator()) {
                    this.table.getPaginator().pageIndex = 0;
                }
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.loading = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        )
    }

    getAutorizzazioni$(isFirstTime?: boolean, filters?: AutorizzazioniFilters, page: number = 0, size: number = DEFAULT_PAGE_SIZE, sort?: string ): Observable<PageStudenteCicloUtenteViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.studentiCicloService.getStudentiForm(
            !!filters?.nominativo ? filters?.nominativo : undefined,
            !!filters?.codiceFiscale ? filters?.codiceFiscale : undefined,
            !!filters?.statoCarriera ? filters?.statoCarriera : undefined,
            !!filters?.statoCarrieraMotivazione ? filters?.statoCarrieraMotivazione : undefined,
            !!filters?.ateneoProvenienza ? filters?.ateneoProvenienza : undefined,
            !!filters?.nazionalita ? filters?.nazionalita : undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            undefined,
            undefined,
            page, size, ).pipe(
            tap((pageStudenti) => {
                const pageSize= this.autorizzazioniTabData.pageSize;
                const tableConfig = buildAutorizzazioniTableConfiguration(
                    pageStudenti, this.translocoService, this.cicloConfigurationService, this.ctrlSelectFormControl?.value, pageSize);
                this.autorizzazioniTabData.tableConfiguration = tableConfig;
                if(page == 0 && this.table?.getPaginator()) {
                    this.table.getPaginator().pageIndex = 0;
                }
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.loading = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        )
    }

    catchErrorInMobilityRequest$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }


    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.SHOW:
                this.openPeriodInfoDialog($event?.value)
                break;
            case TipoClickEnum.CHIP_DETAIL:
                this.openInfoStudent($event.value);
                break;
            case TipoClickEnum.GO_TO_MOBILITA_STUDENTE:
                const periodoOrStudente = $event?.value;
                if(periodoOrStudente?.tipoPeriodoEnum) {
                    const periodType = periodoOrStudente?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca';
                    this.router.navigate([PathEnum.STUDENTS, periodoOrStudente?.studenteCiclo?.utente?.id, PathEnum.MOBILITA], {fragment: periodType});
                } else {
                    this.router.navigate([PathEnum.STUDENTS, periodoOrStudente?.idUtente, PathEnum.MOBILITA]);
                }
                break;
            case TipoClickEnum.SHOW_MOBILITY_REQUEST_DELIBERA:
                this.showDelibera($event?.value);
                break;
            case TipoClickEnum.DOWNLOAD_CONCLUSION_MOBILITY_REQUEST_ATTACHMENTS:
                this.donwloadConclusionRequestAttachments($event?.value);
                break;
            case TipoClickEnum.DOWNLOAD_MOBILITY_INVITATION_LETTER:
                this.donwloadInvitationLetter($event.value);
                break;
            case TipoClickEnum.DOWNLOAD:
                this.downloadDeliberaProgrammazione($event?.value);
                break;
            case TipoClickEnum.SHOW_DELIBERA_MOBILITA_DETAIL:
                this.showDeliberaProgrammazioneDetail($event?.value);
                break;
            case TipoClickEnum.EDIT:
                this.editDeliberaProgrammazione($event?.value);
                break;
            case TipoClickEnum.DELETE:
                this.openDeleteDeliberaProgrammazioneDialog($event?.value);
                break;
            case TipoClickEnum.SHOW_RETRIBUTION_MOBILITA_DETAILS:
                this.openRetributionInfoDialog($event?.value);
                break;
            case TipoClickEnum.SHOW_REFUSE_REASON:
                this.openShowRefuseReasonDialog($event?.value);
                break;
            case TipoClickEnum.DOWNLOAD_RETRIBUTION_MOBILITY_ATTACHMENTS:
                this.donwloadRetributionAttachments($event?.value, $event?.parentValue);
                break;
            case TipoClickEnum.REVOKE_MOBILITA_AUTORIZZAZIONE:
                this.openRevokeMobilitaAutorizzazioneDialog($event?.value);
                break;
        }
    }

    private donwloadInvitationLetter(periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getAllegatoPeriodoDiMobilitaStudente(
                periodo?.codiceMobilita, periodo?.allegatiPeriodoDiMobilita?.[0]?.id, periodo?.studenteCiclo?.utente?.id, 'response')
            ),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                fs.saveAs(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }


    pageAction($event: PageEvent) {
        if(this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO) {
            this.mobilitaTabData.page = $event.pageIndex;
            this.mobilitaTabData.pageSize = $event.pageSize;
        } else {
            this.ricercaTabData.page = $event.pageIndex;
            this.ricercaTabData.pageSize = $event.pageSize;
        }
        this.getDataRequest(this.selectedTabTipo, false, this.getMainFormGroup(this.selectedTabTipo)?.value, $event.pageIndex, $event.pageSize);
    }

    private openPeriodInfoDialog(periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const titleLabel = periodo.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobility.mobility_period' : 'mobility.research_period';
        const data: DialogMobilitaDashboardInfoI = {
            title: get(translation, titleLabel, null) + ' ' + periodo?.codiceMobilita,
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...infoAggiuntivePeriodoMobilitaDashboard(periodo, translation, this.currentLanguage, this, this.currentRuolo),
            dettaglioImporto: periodo?.dettaglioCalcoloImporto
        };
        this.dialog.open(DialogMobilitaDashboardInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '80vw'
        });
    }

    closeDrawer() {
        this.drawer.close();
    }

    getDataForFilters(): void {
        if(this.selectedTabTipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO || this.selectedTabTipo === TipoMobilitaDashboardTab.RICERCA) {
            this.showMiniLoader = true;
            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
                tap((selectValues: MobilitaRequestSelectFormValuesDTO) => {
                    if (this.selectedTabTipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO) {
                        this.mobilitaFilterManagerService.setSelectValues(selectValues);
                    } else if (this.selectedTabTipo === TipoMobilitaDashboardTab.RICERCA) {
                        this.mobilitaFilterManagerService.setSelectValues(selectValues);
                    }
                    this.selectValues = selectValues;
                }),
                takeUntil(this.destroy$),
                finalize(() => this.showMiniLoader = false)
            ).subscribe({
                next: () => {
                    this.toogleFilterContainer(true);
                },
                error: (err) => {
                    if (this.selectedTabTipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO) {
                        this.mobilitaFilterManagerService.setSelectValues(undefined);
                    } else if (this.selectedTabTipo === TipoMobilitaDashboardTab.RICERCA) {
                        this.ricercaFilterManagerService.setSelectValues(undefined);
                    }
                    this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                        () => {
                        },
                        () => this.getDataForFilters(),
                        'auth.cancel',
                        err?.error?.message);
                }
            });
        } else {
            this.toogleFilterContainer(true);
        }
    }

    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.getSidebarByTypo(this.selectedTabTipo)?.setFilterApplied();
        if (hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.mobilitaFilterManagerService.checkApplyFilterClick$.next(false);
        this.ricercaFilterManagerService.checkApplyFilterClick$.next(false);
        this.delibereFilterManagerService.checkApplyFilterClick$.next(false);
        this.autorizzazioniFilterManagerService.checkApplyFilterClick$.next(false);
    }

    reset(tipoPeriodo: TipoMobilitaDashboardTab): void {
        this.getMainFormGroup(tipoPeriodo)?.reset();
        this.getSidebarByTypo(tipoPeriodo)?.resetFilterApplied();
        if(tipoPeriodo === TipoMobilitaDashboardTab.SOGGIORNOESTERO) {
            this.mobilitaFilterManagerService?.checkApplyFilterClick$.next(true);
        } else if(tipoPeriodo === TipoMobilitaDashboardTab.RICERCA) {
            this.ricercaFilterManagerService?.checkApplyFilterClick$.next(true);
        } else if(tipoPeriodo === TipoMobilitaDashboardTab.DELIBERE) {
            this.delibereFilterManagerService?.checkApplyFilterClick$.next(true);
        } else if(tipoPeriodo === TipoMobilitaDashboardTab.AUTORIZZAZIONI) {
            this.autorizzazioniFilterManagerService?.checkApplyFilterClick$.next(true);
        }
    }

    getMainFormGroup(tipoPeriodo: TipoMobilitaDashboardTab): FormGroup<any> {
        return tipoPeriodo === TipoMobilitaDashboardTab.SOGGIORNOESTERO ? this.mobilitaFilterManagerService?.mainFormGroup :
            (tipoPeriodo === TipoMobilitaDashboardTab.RICERCA ? this.ricercaFilterManagerService?.mainFormGroup :
                (tipoPeriodo === TipoMobilitaDashboardTab.AUTORIZZAZIONI ? this.autorizzazioniFilterManagerService?.mainFormGroup : this.delibereFilterManagerService?.mainFormGroup));
    }


    private openInfoStudent(studente: StudenteCicloIdAndUtenteInfoView) {
        if (!!studente?.utente?.urlImmagineProfilo && !!studente?.utente?.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente.utente?.urlImmagineProfilo, studente.utente?.codiceFiscale)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.dialogInfoManagerService.openStudenteInfoDialogSCIUIV(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {},
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.dialogInfoManagerService.openStudenteInfoDialogSCIUIV(studente);
        }
    }


    private subscribeToFilterChanges(tipoPeriodi: TipoMobilitaDashboardTab, isFirstTime: boolean = false): Subscription {
        console.log('subscribing to filter change', tipoPeriodi, 'first time?', isFirstTime)
        //const conditionalSkip = isFirstTime ? tap(() => {}) : skip(1);
        const pageSize = tipoPeriodi === TipoMobilitaDashboardTab.SOGGIORNOESTERO ? this.mobilitaTabData.pageSize :
            (tipoPeriodi === TipoMobilitaDashboardTab.RICERCA ? this.ricercaTabData.pageSize :
                (tipoPeriodi === TipoMobilitaDashboardTab.AUTORIZZAZIONI ? this.autorizzazioniTabData.pageSize : this.delibereTabData.pageSize));
        this.getDataRequest(tipoPeriodi, isFirstTime, this.getMainFormGroup(tipoPeriodi)?.getRawValue(), 0, pageSize);
        const service = tipoPeriodi === TipoMobilitaDashboardTab.SOGGIORNOESTERO ? this.mobilitaFilterManagerService :
            (tipoPeriodi === TipoMobilitaDashboardTab.RICERCA ? this.ricercaFilterManagerService :
                (tipoPeriodi === TipoMobilitaDashboardTab.AUTORIZZAZIONI ? this.autorizzazioniFilterManagerService : this.delibereFilterManagerService));
        let request: (filters, pageSize) => Observable<any>;
        if(tipoPeriodi === TipoMobilitaDashboardTab.SOGGIORNOESTERO){
            request = (filters, pageSize) => this.getMobilityPeriods$(TipoPeriodoEnum.SOGGIORNOESTERO,false, filters as MobilitaFilters, 0, pageSize as number);
        } else if (tipoPeriodi === TipoMobilitaDashboardTab.RICERCA){
            request = (filters, pageSize) => this.getMobilityPeriods$(TipoPeriodoEnum.RICERCA,false, filters as MobilitaFilters, 0, pageSize as number);
        } else if (tipoPeriodi === TipoMobilitaDashboardTab.DELIBERE){
            request = (filters, pageSize) => this.getDelibere$(false, filters as DelibereFilters, 0, pageSize as number);
        } else {
            request = (filters, pageSize) => this.getAutorizzazioni$(false, filters as AutorizzazioniFilters, 0, pageSize as number);
        }
        return service.appliedFiltersObsevable$().pipe(
            filter(Boolean),
            //conditionalSkip,
            tap((filters) => console.log(filters, tipoPeriodi)),
            map(filters => {
                const pageSize = tipoPeriodi === TipoMobilitaDashboardTab.SOGGIORNOESTERO ? this.mobilitaTabData.pageSize :
                    (tipoPeriodi === TipoMobilitaDashboardTab.RICERCA ? this.ricercaTabData.pageSize :
                        (tipoPeriodi === TipoMobilitaDashboardTab.AUTORIZZAZIONI ? this.autorizzazioniTabData.pageSize : this.delibereTabData.pageSize));
                return [filters, pageSize];
            }),
            switchMap(([filters, pageSize]) => request(filters, pageSize).pipe(
                this.catchErrorInMobilityRequest$(request(filters, pageSize))
            )),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: () => {
                    service.filterApplied$.next(this.getMainFormGroup(tipoPeriodi).getRawValue());
                    this.currentFilterListChipLabels.set(tipoPeriodi, this.getListChipsLabel());
                },
                error: (err) => console.log(err)
            }
        );
    }


    openExportExcelDialog() {
        const appliedFiltersFormValues = this.getMainFormGroup(this.selectedTabTipo).getRawValue();
        const appliedFiltersNumber = Object.values(appliedFiltersFormValues).filter(f => !!f).length;
        if(appliedFiltersNumber > 0) {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                title: get(translation, 'mobility.export_excel_dialog', null),
                message: get(translation, 'mobility.export_excel_dialog_message', null),
                icon: {
                    name: 'mat_outline:info',
                    color: 'info'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'dialog.cancel', null),
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'mobility.export_without_filters', null),
                        function: () => this.exportExcelRequest()
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'mobility.export_with_filters', null),
                        function: () => this.exportExcelRequest(appliedFiltersFormValues)
                    }]
                }
            );
        } else {
            this.exportExcelRequest();
        }
    }

    private exportExcelRequest(filters?: MobilitaFilters) {
        this.fuseConfirmationService.showLoader();
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getPeriodiDiMobilitaStudenteForm(
                !!filters?.codiceMobilita ? filters.codiceMobilita : undefined,
                this.selectedTabTipo as TipoPeriodoEnum,
                !!filters?.nominativoStudente ? filters.nominativoStudente : undefined,
                undefined,
                undefined,
                !!filters?.stato ? filters.stato : undefined,
                !!filters?.statoSvolgimento ? filters?.statoSvolgimento : undefined,
                !!filters?.periodoDa ? filters.periodoDa : undefined,
                !!filters?.periodoA ? filters.periodoA : undefined,
                !!filters?.annoAccademico ? filters.annoAccademico : undefined,
                !!filters?.nominativoStrutturaEsteraOspitante ? filters.nominativoStrutturaEsteraOspitante : undefined,
                !!filters?.codiceNazioneStruttura ? filters.codiceNazioneStruttura : undefined,
                !!filters?.dataFineNull ? filters.dataFineNull : undefined,
                !!filters?.codiceTipologiaMobilita ? filters.codiceTipologiaMobilita : undefined,
                !!filters?.codiceTipologiaBonus ? filters.codiceTipologiaBonus : undefined,
                !!filters?.codiceTipoInterventoSupporto ? filters.codiceTipoInterventoSupporto : undefined,
                !!filters?.dataUltimaModifica ? moment(filters.dataUltimaModifica).format('DD/MM/YYYY HH:mm') as unknown as Date : undefined,
            )),
            switchMap((periodi: PagePeriodoDiMobilitaStudenteInfoView) => {
                const currentBaseURL = location.origin;
                const excelConfig = [{
                    nameSheet: 'Periodi di ' + (this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO ? 'Mobilità' : 'Ricerca'),
                    data: mapPeriodiForExcel(periodi?.content || [], currentBaseURL, translation),
                    config: PERIODI_EXCEL_SHEET_CONFIG(this.cicloConfigurationService),
                }];
                return this.excelService.generateExcel(excelConfig);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (excelFile: Blob) => {
                const appliedFiltersNumber = Object.values(filters || []).filter(f => !!f).length;
                const tipoPeriodo = this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO ? 'MOBILITA' : 'RICERCA';
                const fileTypeForName = 'PERIODI_DI_' + tipoPeriodo + '_EXPORT_EXCEL' + (appliedFiltersNumber >= 1 ? '_FILTRATI' : '');
                const outputFileName = makeFilenameFromFE(this.localStorageService.getCicloCorsoRuolo(), '.xlsx', fileTypeForName);
                fs.saveAs(excelFile, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }

    protected readonly console = console;

    onTabChanged($event: number) {
        console.log('tab changed')
        this.selectedTabTipo = $event == 0 ? TipoMobilitaDashboardTab.SOGGIORNOESTERO :
            ($event == 1 ? TipoMobilitaDashboardTab.RICERCA :
                ($event == 2 ? TipoMobilitaDashboardTab.DELIBERE :
                    TipoMobilitaDashboardTab.AUTORIZZAZIONI));
        if(this.selectedTabTipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO) {
            this.mobilitaTabData.tableConfiguration = undefined;
        } else if(this.selectedTabTipo === TipoMobilitaDashboardTab.RICERCA) {
            this.ricercaTabData.tableConfiguration = undefined;
        } else if(this.selectedTabTipo === TipoMobilitaDashboardTab.DELIBERE) {
            this.delibereTabData.tableConfiguration = undefined;
        } else if(this.selectedTabTipo === TipoMobilitaDashboardTab.AUTORIZZAZIONI) {
            this.autorizzazioniTabData.tableConfiguration = undefined;
        }
        //this.reset();
        //const pageSize = this.selectedTabTipo === TipoPeriodoEnum.SOGGIORNOESTERO ? this.mobilitaTabData.pageSize : this.ricercaTabData.pageSize;
        //this.getMobilityPeriodsRequest(this.selectedTabTipo, false, this.getMainFormGroup(this.selectedTabTipo)?.value, 0, pageSize);
        if(this.filterChangesSubscription.get(this.selectedTabTipo)) {
            this.filterChangesSubscription.get(this.selectedTabTipo).unsubscribe();
        }
        this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, false));
    }

    initTabsData() {
        this.mobilitaTabData = {
            tabLabel: 'mobility.mobility_periods',
            tabAriaLabel: 'mobilita',
            tableConfiguration: undefined,
            tipologiaPeriodi: TipoMobilitaDashboardTab.SOGGIORNOESTERO,
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE
        };
        this.ricercaTabData = {
            tabLabel: 'mobility.research_periods',
            tabAriaLabel:  'ricerca',
            tableConfiguration: undefined,
            tipologiaPeriodi: TipoMobilitaDashboardTab.RICERCA,
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE
        };
        this.delibereTabData = {
            tabLabel: 'mobility.delibere',
            tabAriaLabel: 'delibere',
            tableConfiguration: undefined,
            tipologiaPeriodi: TipoMobilitaDashboardTab.DELIBERE,
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE
        };
        this.autorizzazioniTabData = {
            tabLabel: 'mobility.authorizations',
            tabAriaLabel: 'autorizzazioni',
            tableConfiguration: undefined,
            tipologiaPeriodi: TipoMobilitaDashboardTab.AUTORIZZAZIONI,
            page: 0,
            pageSize: DEFAULT_PAGE_SIZE
        };
    }

    private showDelibera(periodo: PeriodoDiMobilitaStudenteInfoView) {
        this.fuseConfirmationService.openDialogDelibera({
            delibera: periodo.delibera,
            subtitle: periodo.codiceMobilita,
            codiceCorsoDiStudiEsse3: periodo.delibera?.codiceCorsoDiStudiEsse3 ,
        });
    }

    getSottoruolo() {
        return this.localStorageService.getSottoruoloCiclo();
    }


    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;

    getSidebarByTypo(tipo: TipoMobilitaDashboardTab){
        return tipo === TipoMobilitaDashboardTab.SOGGIORNOESTERO ? this.mobilitaSidebarContainerComponent : this.ricercaSidebarContainerComponent;
    }

    protected readonly TipoMobilitaDashboardTab = TipoMobilitaDashboardTab;

    private downloadDeliberaProgrammazione(deliberaProgrammazione: DeliberaDiProgrammazioneMobilitaFiltered) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let nomeFile = deliberaProgrammazione?.delibera?.url;
        const requestBody: GetDeliberaDTO = {
            nomeFile: nomeFile,
            numeroDelibera: deliberaProgrammazione?.delibera?.numero
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.delibereService.downloadDelibera(requestBody, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                console.error(err);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }

    private showDeliberaProgrammazioneDetail(deliberaProgrammazione: DeliberaDiProgrammazioneMobilitaFiltered) {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.periodiDiMobilitaService.getDeliberaDiProgrammazioneMobilita(deliberaProgrammazione?.id)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView) => {
                this.openDettaglioDeliberaProgrammazioneDialog(deliberaDettaglio);
            },
            error: (err) => {
                console.error(err);
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    undefined,
                    () => this.showDeliberaProgrammazioneDetail(deliberaProgrammazione),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    private openDettaglioDeliberaProgrammazioneDialog(deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView) {
        const data: DialogDettaglioDeliberaProgrammazioneI = {
            title: 'mobility.mobility_delibera_detail',
            icon: {
                name: 'mat_solid:info',
                color: 'info'
            },
            deliberaDettaglio: deliberaDettaglio
        };
        this.dialog.open(DialogDettaglioDeliberaProgrammazioneComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
        });
    }

    private openEditDeliberaProgrammazioneDialog(deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView) {
        const data: DialogEditDeliberaProgrammazioneI = {
            title: 'mobility.delibera_edit',
            icon: {
                name: 'mat_solid:info',
                color: 'info'
            },
            deliberaDettaglio: deliberaDettaglio
        };
        const dialogRef = this.dialog.open(DialogEditDeliberaProgrammazioneComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            maxWidth: '95vw',
            minWidth: '90vw'
        });
        dialogRef.afterClosed().subscribe((delibereProgrammazione) => {
            if(delibereProgrammazione !== 'cancelled'){
                if(this.filterChangesSubscription.get(this.selectedTabTipo)) {
                    this.filterChangesSubscription.get(this.selectedTabTipo).unsubscribe();
                }
                this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, false));
            }
        });
    }

    private editDeliberaProgrammazione(deliberaProgrammazione: DeliberaDiProgrammazioneMobilitaFiltered) {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.periodiDiMobilitaService.getDeliberaDiProgrammazioneMobilita(deliberaProgrammazione?.id)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (deliberaDettaglio: DeliberaDiProgrammazioneMobilitaInfoView) => {
                this.openEditDeliberaProgrammazioneDialog(deliberaDettaglio);
            },
            error: (err) => {
                console.error(err);
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    undefined,
                    () => this.editDeliberaProgrammazione(deliberaProgrammazione),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    private openDeleteDeliberaProgrammazioneDialog(deliberaProgrammazione: DeliberaDiProgrammazioneMobilitaFiltered) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'mobility.delibera_delete', null),
            message: get(translation, 'mobility.delibera_delete_message', null) + ' ' +
                deliberaProgrammazione?.delibera?.numero + ' - ' + moment(deliberaProgrammazione?.delibera?.data).format('DD/MM/YYYY') + '?',
            icon: {
                show: true,
                name: 'delete',
                color: 'primary'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
                {
                    color: 'primary',
                    label: get(translation, 'dialog.confirm', null), icon: 'check',
                    function: () => this.deleteDeliberaProgrammazioneRequest(deliberaProgrammazione.id)
                }]
            }
        );
    }

    private openRevokeMobilitaAutorizzazioneDialog(studente: StudenteCicloUtenteViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'mobility.revoke_autorization', null),
                message: get(translation, 'mobility.revoke_autorization_message', null),
                icon: {
                    show: true,
                    name: 'delete',
                    color: 'primary'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        function: () => this.revokeAutorizationMobilitaRequest(studente.idUtente)
                    }]
            }
        );
    }

    private deleteDeliberaProgrammazioneRequest(deliberaId: string) {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.deleteDeliberaDiProgrammazioneMobilita(deliberaId)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
                const activeLang = this.translocoService.getActiveLang();
                const translation = this.translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.op_saved', null),
                    type: SnackbarTypes.Success,
                });
                if(this.filterChangesSubscription.get(this.selectedTabTipo)) {
                    this.filterChangesSubscription.get(this.selectedTabTipo).unsubscribe();
                }
                this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, false));
            },
            error: (err) => {
                console.error(err);
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    () => {},
                    () => this.deleteDeliberaProgrammazioneRequest(deliberaId),
                    'dialog.close',
                    err?.error?.message);
            }
        });
    }

    private revokeAutorizationMobilitaRequest(idUtente: string) {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.revokaAutorizzazioneMobilitaDottorando(idUtente)),
            takeUntil(this.destroy$),
        ).subscribe({
            next: () => {
                const activeLang = this.translocoService.getActiveLang();
                const translation = this.translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'mobility.op_saved', null),
                    type: SnackbarTypes.Success,
                });
                if(this.filterChangesSubscription.get(this.selectedTabTipo)) {
                    this.filterChangesSubscription.get(this.selectedTabTipo).unsubscribe();
                }
                this.fuseConfirmationService.hideLoader();
                this.filterChangesSubscription.set(this.selectedTabTipo, this.subscribeToFilterChanges(this.selectedTabTipo, false));
            },
            error: (err) => {
                this.fuseConfirmationService.hideLoader();
                console.error(err);
                this.fuseConfirmationService.openErrorDialog({error: err},
                    this.translocoService,
                    () => {},
                    () => this.revokeAutorizationMobilitaRequest(idUtente),
                    'dialog.close',
                    err?.error?.message);
            }
        });
    }

    private donwloadConclusionRequestAttachments(periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const idUtente = periodo?.studenteCiclo?.utente?.id;
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.getZipAttestatiRichiesteDiConclusioneMobilita(idUtente, periodo?.codiceMobilita, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_CONCLUSIONE_' + periodo?.codiceMobilita;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: periodo?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo: this.localStorageService?.getCicloCorsoRuolo()?.ciclo || (this.localStorageService?.cicloAmm + '') ,
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                console.log(err)
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }

    private donwloadRetributionAttachments(richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView, periodo: PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const idUtente = periodo?.studenteCiclo?.utente?.id;
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.getZipAttestatiRichiestaDiRetribuzioneMobilita(idUtente, periodo?.codiceMobilita, richiestaRetribuzione?.id, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_VALIDAZIONE_' + periodo?.codiceMobilita;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: periodo?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo: this.localStorageService?.getCicloCorsoRuolo()?.ciclo || (this.localStorageService?.cicloAmm + '') ,
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    type: SnackbarTypes.Warning,
                });
            }
        });
    }

    private openShowRefuseReasonDialog(richiestaRetribuzioneOrPeriodo: RichiestaDiRetribuzioneMobilitaInfoView | PeriodoDiMobilitaStudenteInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let closeLabel = get(translation, 'dialog.close', null);
        const data: GenericComponentDialogConfig = {
            title: 'budget.refuse_reason',
            message: richiestaRetribuzioneOrPeriodo.motivazioneRifiuto,
            icon: {
                show: true,
                name: 'description',
                color: 'accent',
            },
            actions: {
                cancel: {
                    show: true,
                    label: closeLabel,
                },
                confirm: {
                    show: false,
                    function: form => {
                    }
                }
            },
            dismissible: true,
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private openRetributionInfoDialog(richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) {
        const data: DialogInfoI = {
            showImage: false,
            title: 'mobility.retribution_details',
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            readonlyData: [],
            bottomComponent: MobilitaValidazioneDettaglioComponent,
            bottomComponentData: richiestaRetribuzione,
        };
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '80vw'
        });
    }

}
