import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {filter, tap} from 'rxjs/operators';
import {distinctUntilChanged, Observable, take, takeUntil} from 'rxjs';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {
    MobilitaRequestSelectFormValuesDTO,
    NazioneEsteraMobilitaInfoView,
    PeriodoDiMobilitaStudenteStatus, SpesaStudenteStatus, SpesaStudenteType,
    StatoTemporaleEnum,
    TipoInterventoSupportoMobilitaInfoView,
    TipologiaBonusMobilitaStudenteInfoView,
    TipologiaMobilitaInfoView,
    TipoPeriodoEnum
} from '../../../../../api-clients/generated/services';

import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {isEmpty} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {BudgetFilterService} from "./budget-filter.service";
import {DEFAULT_PAGE_SIZE} from "../requests-to-be-resolved.component";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";

@Component({
    selector: 'app-budget-sidebar-filter-container',
    templateUrl: './budget-sidebar-filter-container.component.html',
    styleUrls: ['./budget-sidebar-filter-container.component.scss']
})
export class BudgetSidebarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    outputFormatData = 'DD/MM/YYYY';
    selectValues: MobilitaRequestSelectFormValuesDTO;
    currentLanguage: Observable<string>;
    // filters names
    isInputField = ['codiceSpesa', 'descrizioneNoteSpesa', 'nominativoStudente', 'emailStudente', 'cfStudente', 'matricolaStudente' ];

    protected readonly Object = Object;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly StatoTemporaleEnum = StatoTemporaleEnum;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;

    constructor(public budgetFilterService: BudgetFilterService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,
                private translocoService: TranslocoService,
                protected cicloConfigurationService: CicloConfigurationService,) {
        super();
        this.currentFields = this.budgetFilterService.defaultFormPathList;
        this.fieldsLabelMap = this.budgetFilterService.fieldsLabelMap;
        this.currentLanguage = this.translocoService.langChanges$;
    }

    ngOnInit(): void {
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(() => console.log('Aggiorna filtri')),
            tap(value =>
                this.currentFields = this.budgetFilterService.getFieldsByRole()),
            takeUntil(this.destroy$)
        ).subscribe();

        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            filter(Boolean),
            tap(() => this.budgetFilterService.init()),
            tap(value =>
                this.currentFields = this.budgetFilterService.getFieldsByRole()
            ),
            takeUntil(this.destroy$)
        ).subscribe();

        this.budgetFilterService.checkAggiornamentoSelectValues$.asObservable().pipe(
            tap(() => this.selectValues = this.budgetFilterService.selectValues),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.budgetFilterService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.budgetFilterService.mainFormGroup;
    }

    applyFilters(): void {
        this.resetPaginator();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.budgetFilterService.filterApplied = this.getMainFormGroup().getRawValue();
        this.budgetFilterService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeDrawer.next(true);
    }


    reset(): void {
        this.resetPaginator();
        this.budgetFilterService?.reset();
        this.close();
    }

    resetPaginator(){
        this.budgetFilterService.page = 0;
        this.budgetFilterService.pageSize = DEFAULT_PAGE_SIZE;

    }


    protected readonly SpesaStudenteType = SpesaStudenteType;
    protected readonly SpesaStudenteStatus = SpesaStudenteStatus;
}
