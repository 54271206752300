import {Component, Inject, OnInit} from '@angular/core';
import {
    ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest,
    ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse,
    AuthorityType,
    DeliberaApprovaPeriodiDiRicercaResponse,
    DeliberaApprovaPeriodiDiSoggiornoEsteroRequest,
    EsitoOperazione,
    EsitoSingolaApprovazioneRichiestaDiConclusioneMobilita,
    EsitoSingolaOperazioneInOperazioneMassiva,
    MediaService,
    OperazioniMassiveService,
    PeriodoDiMobilitaStudenteInfoView,
    StudenteCicloIdAndUtenteInfoView
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, catchError, finalize, map, of, switchMap, take, takeUntil, tap} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneConclusioneMobilitaMassivaDataI} from "../approvazione-conclusione-mobilita-steps-interface";
import {CAN_GO_AHEAD$, CURRENT_PROFILE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {filter} from "rxjs/operators";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogInfoManagerService} from "../../../../../shared/service/dialog-info-manager.service";


@Component({
    selector: 'app-confirm-approvazione-conclusione-mobilita-stepper',
    templateUrl: './confirm-approvazione-conclusione-mobilita.component.html',
    styleUrls: ['./confirm-approvazione-conclusione-mobilita.component.scss']
})
export class ConfirmApprovazioneConclusioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneMobilitaData: ApprovazioneConclusioneMobilitaMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array< EsitoSingolaApprovazioneRichiestaDiConclusioneMobilita> | undefined;
    resultsPeriodiSelected: PeriodoDiMobilitaStudenteInfoView[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private logoutService: LogoutService,
                private dialog: MatDialog,
                private dialogInfoManagerService: DialogInfoManagerService,
                @Inject(CURRENT_PROFILE) protected currentProfile: AuthorityType,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneConclusioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
            this.approvazioneMobilitaData = inputOutputData;
            // when there are studenti selected reset succeed and error flags
            if(this.approvazioneMobilitaData?.periodiSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: ApprovazioneMassivaRichiestaDiConclusioneMobilitaRequest = {
            specifiche: this.approvazioneMobilitaData?.periodiSelected?.map(period => ({
                id_utente: period.studenteCiclo?.utente?.id,
                codice_mobilita: period.codiceMobilita,
            }))
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaRichiestaDiConclusioneMobilitaForm(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaConclusioneResponse: ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse) => {
                this.resultsPeriodiSelected  = cloneDeep(this.approvazioneMobilitaData?.periodiSelected);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    periodiSelected: undefined,
                    refreshPeriodi: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaConclusioneResponse: ApprovazioneMassivaRichiestaDiConclusioneMobilitaResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaConclusioneResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsPeriodiSelected?.find(s => s.studenteCiclo?.utente?.id === id_utente);
        return studente?.studenteCiclo?.utente?.cognome + ' ' + studente?.studenteCiclo?.utente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    private openInfoStudent(studente: StudenteCicloIdAndUtenteInfoView) {
        if (!!studente?.utente?.urlImmagineProfilo && !!studente?.utente?.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.mediaService.getImmagineProfiloForm(studente?.utente?.urlImmagineProfilo, studente?.utente?.codiceFiscale)
                .pipe(
                    catchError(err => of(undefined)),
                    map((image) => {
                        if (image) {
                            const objectURL = URL.createObjectURL(image);
                            return this.sanitizer.bypassSecurityTrustUrl(objectURL);
                        } else {
                            return undefined;
                        }
                    }),
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                next: (image: SafeUrl) => {
                    this.dialogInfoManagerService.openStudenteInfoDialogSCIUIV(studente, image);
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this.translocoService,
                        () => {
                            this.logoutService.goToHome();
                        },
                        () => this.openInfoStudent(studente),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        } else {
            this.dialogInfoManagerService.openStudenteInfoDialogSCIUIV(studente);
        }
    }





}
