<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>

        <div class="mt-4 flex flex-col gap-3">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t(documentsFormConfig.length > 0 ?
                'massive_operations.approvazione_piani.hint_compile_info' : 'massive_operations.approvazione_piani.hint_compile_no_info')}}</fuse-alert>

        </div>

        <div class="w-full flex flex-col items-center">

            <app-form class="w-full"
                      [formGroupConfig]="documentsFormConfig"
                      [valueForm]="documentsFormValue"
            ></app-form>

            <!-- TABELLA STUDENTI -->
            <div class="w-full mb-6 flex flex-col gap-3" *ngIf="updatesWithDocumentAlreadyAdded">
                <fuse-alert class="w-full"
                            [type]="'warning'"
                            [showIcon]="true"
                            [appearance]="'outline'">
                    {{t('massive_operations.approvazione_piani.documents_will_be_added_on_non_filled_request')}}
                </fuse-alert>

                <app-generic-table [configuration]="updatesWithDocumentAlreadyAdded"
                                   (clickAction)="tableClickAction($event)">
                </app-generic-table>
            </div>


        </div>



    </ng-template>



</ng-container>
