import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';
import {ChiaveFunzionalitaEnum, FunzionalitaStatus} from "../../../api-clients/generated/services";
import {PathEnum} from "../../app.routing";
import {checkFunctionalityState} from "./guards-utils";
import {CicloConfigurationService} from "../../shared/service/ciclo-configuration.service";
import {Observable, of} from "rxjs";
import {CicloConfigDependantGuard} from "./ciclo-config-dependant.guard";


@Injectable({
    providedIn: 'root'
})
export class TrainingOfferAdministrationGuard extends CicloConfigDependantGuard {
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        protected cicloConfigurationService: CicloConfigurationService,
    ) {
        super(cicloConfigurationService)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */


    _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const canActivate = TrainingOfferAdministrationGuard.accessCondition(this.cicloConfigurationService);
        if(!canActivate) {
            this._router.navigate([PathEnum.ADMINISTRATOR, PathEnum.HOME]);
        }
        return of(canActivate);
    }

    static accessCondition(cicloConfigurationService: CicloConfigurationService,
                           passingStates: FunzionalitaStatus[] = [FunzionalitaStatus.ABILITATA],) {
        return checkFunctionalityState(cicloConfigurationService, ChiaveFunzionalitaEnum.AreaDidattica, passingStates);
    }



}
