import {Injectable} from '@angular/core';
import {AuthorityType, AuthService} from '../../../api-clients/generated/services';
import {catchError, finalize, Observable, of} from 'rxjs';
import {PathEnum} from '../../app.routing';
import {LocalStorageService} from './local-storage.service';
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";

@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    constructor(private authService: AuthService,
                private localStorageService: LocalStorageService,
                private fuseConfirmationService: FuseConfirmationService,
                private _router: Router,
                private matDialog: MatDialog) {
    }

    logout(keepLocation = false): void {
        this.fuseConfirmationService.showLoader();

        this.authService.logoutUser().pipe(
            tap(() => this.localStorageService.cleanAllClassVariablesAndStorage()),
            catchError(() => {
                    this.localStorageService.cleanAllClassVariablesAndStorage();
                    return of(null);
                }
            ),
            finalize(() => {
                if(!environment.servicesKeycloak?.useInternalIDP) {
                    const newPage = window.open(environment.servicesKeycloak.idpLogout + '/idp/profile/Logout?redirectUrl=' + environment.servicesKeycloak.redirectUri, '_self');
                    newPage.onload = () => {
                        this.fuseConfirmationService.hideLoader();
                    };
                } else{
                    this.fuseConfirmationService.hideLoader();
                    if(!keepLocation){
                        history.replaceState({}, 'page', '/' + PathEnum.SIGN_IN);
                    }
                    window.location.reload();
                }
            })
        ).subscribe({
            next: () => {
                this.matDialog.closeAll();
            },
            error: () => {
                this.fuseConfirmationService.hideLoader();
            }
        });
    }

    // it is forced in the sense that even if logout from the BE fails, it will still logout from the IDP and clean the storage
    logoutForcingIDP$(redirectUrl: string): Observable<any> {
        this.fuseConfirmationService.showLoader();
        // use the access token if you have it in the storage
        this.localStorageService.accessToken = this.localStorageService.getAccessToken(true);
        // trying to logout from the BE (keycloak)
        return this.authService.logoutUser().pipe(
            finalize(() => {
                this.matDialog.closeAll();
                this.fuseConfirmationService.hideLoader();
                // clean all the storage
                this.localStorageService.cleanAllClassVariablesAndStorage();
                // logout from the IDP
                if(!environment.servicesKeycloak?.useInternalIDP) {
                    const newPage = window.open(redirectUrl, '_self');
                    newPage.onload = () => {
                        this.fuseConfirmationService.hideLoader();
                    };
                } else{
                    this.fuseConfirmationService.hideLoader();
                    window.location.reload();
                }
            })
        );
    }

    goToHome(): void {
        const cicloCorsoRuoloFromStorage= this.localStorageService.getCicloCorsoRuolo();
        if(!!cicloCorsoRuoloFromStorage){
            const currentRuolo= cicloCorsoRuoloFromStorage?.ruolo;
            if(currentRuolo === AuthorityType.STUDENTE){
                const idStudente = this.localStorageService.idStudenteByProfile;
                if (idStudente) {
                    this._router.navigate([PathEnum.STUDENTS, idStudente, PathEnum.PROFILE]);
                }
            }
            else{
                this._router.navigate( this.isAreaAdministrator ? [PathEnum.ADMINISTRATOR, PathEnum.HOME]: [PathEnum.HOME]);
            }
        } else  if(this.isAreaAdministrator) {
            this._router.navigate( [PathEnum.ADMINISTRATOR, PathEnum.HOME]);
        }

    }

    get isAreaAdministrator() {
        return window.location.pathname.startsWith('/' + PathEnum.ADMINISTRATOR);
    }


}
