import {Component, Inject, OnInit} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AddDeliberaDTO, AggiornamentoBudgetStudentePerOpMassivaInfoView,
    AttivitaOffertaFormativaInfoView,
    AuthorityType,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService,
    PageDeliberaInfoViewImpl,
    SpesaStudentePerApprovMassivaInfoView, SpeseStudentiCicloService,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, switchMap, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ApprovazioneModificheBudgetMassivaDataI} from '../approvazione-modifiche-budget-steps-interface';
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get, orderBy} from "lodash";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {annoRiferimentoToRomanNumeral, makeFilenameFromFE} from "../../../../../shared/utils/utils";
import {HttpResponse} from "@angular/common/http";
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import * as fs from "file-saver";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {buildFormConfigForAddNewDelibera} from "../../../gestione-documenti/gestione-documenti-utils";


@Component({
    selector: 'app-info-approvazione-modifiche-budget-stepper',
    templateUrl: './compila-info-approvazione-modifiche-budget.component.html',
    styleUrls: ['./compila-info-approvazione-modifiche-budget.component.scss']
})
export class CompilaInfoApprovazioneModificheBudgetComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    speseListConfiguration: GenericTableConfigurationModel;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    compileInfoFormGroup: FormGroup = new FormGroup<any>({});
    budgetSelected: AggiornamentoBudgetStudentePerOpMassivaInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    form: FormGroup;

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private studentiCicloService: StudentiCicloService,
                private speseStudentiCicloService: SpeseStudentiCicloService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneModificheBudgetMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneModificheBudgetMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
            }
            // if spese selected changes reset delibera form
            if(inputOutputData.budgetSelected !== this.budgetSelected){
                this.budgetSelected = inputOutputData?.budgetSelected;
                this.speseListConfiguration = this.buildModificheBudgetConfiguration(this.budgetSelected || []);
                this.form.get('deliberaCollegio').setValue(inputOutputData.deliberaCollegio?.id)
            }
        });
        this.compileInfoFormGroup?.valueChanges?.subscribe((value) => {
            this.canGoNext$.next(this.compileInfoFormGroup.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$.getValue(),
                compileInfo: this.compileInfoFormGroup.get(['child', 'input_fields'])?.value
            });
        })
        this.getDelibereRequest();
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildModificheBudgetConfiguration(budgets: AggiornamentoBudgetStudentePerOpMassivaInfoView[]): GenericTableConfigurationModel {
        const activeLang = this.translocoService.getActiveLang()
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            configuration: {
                data: budgets?.map(budget => ({
                    ...budget,
                    studente: budget.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + budget.budgetStudente?.studenteCiclo?.utente?.nome,
                    studenteForChip: [{nome: budget.budgetStudente?.studenteCiclo?.utente?.nome, cognome: budget.budgetStudente?.studenteCiclo?.utente?.cognome}],
                    importoRichiesto: budget.importo,
                    id: budget.id,
                    annoRichiesta: annoRiferimentoToRomanNumeral(budget?.annoRiferimento),
                    importoApprovato: budget.importo,
                })),
                sticky: true,
                totalElements: budgets?.length,
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data',
                hidePaginator: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        show: () => false,
                        nomeColonna: 'id',
                        colonnaKey: 'id',
                        hideColonna: true,
                        flex: 0,
                        formControl: {
                            type: TypeDialogFormEnum.TEXT,
                            show: false,
                            name: 'id',
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'common.dottorando',
                        colonnaKey: 'studenteForChip',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.edit_year',
                        colonnaKey: 'annoRichiesta',
                        flex: 15,
                    },
                    {
                        tipo: TipoColonnaEnum.AMOUNT,
                        nomeColonna: 'budget.req_amount',
                        colonnaKey: 'importoRichiesto',
                        flex: 20,
                    },
                    // {
                    //     tipo: TipoColonnaEnum.INPUT_AMOUNT,
                    //     nomeColonna: 'budget.appr_amount',
                    //     colonnaKey: 'importoApprovato',
                    //     flex: 15,
                    //     formControl: {
                    //         type: TypeDialogFormEnum.AMOUNT,
                    //         show: true,
                    //         name: 'importoApprovato',
                    //         required: true,
                    //         min: { number: 0.1, text: get(translation, 'budget.min_budget_error', null)}
                    //     }
                    // },
                    {
                        tipo: TipoColonnaEnum.INPUT_TEXT,
                        nomeColonna: 'common.nota',
                        colonnaKey: 'nota',
                        flex: 40,
                        formControl: {
                            type: TypeDialogFormEnum.TEXT,
                            show: true,
                            name: 'nota',
                            required: false,
                        }
                    },
                ],
            }
        };
    }

    private initForm() {
        this.form = this.fb.group({
            deliberaCollegio: undefined
        });
        this.form.get('deliberaCollegio').valueChanges.subscribe(deliberaId => {
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                deliberaCollegio: this.delibere?.find(d => d.id === deliberaId)
            });
        })
    }

    tableClickAction($event: ClickEvent) {
        if($event.tipoClick === TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST) {
            this.downloadAllegatiRequest($event.value);
        }
    }

    private downloadAllegatiRequest(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.speseStudentiCicloService.getZipAllegatiRichiestaDiSpesaStudente(spesa?.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                //const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_SPESA_' + spesa?.codiceSpesa;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: spesa?.budgetStudente?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo:  spesa?.budgetStudente?.studenteCiclo?.numeroCicloDottorato + '',
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse?.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }

    openAddNewDeliberaDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined,
                nota: undefined,
                fileNotaTrasmissione: undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera,
            note: rawValue.nota
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    private getDelibereRequest() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getDelibereRequest(), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }


}
