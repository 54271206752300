import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogApprovalComponent } from './dialog-approval.component';
import {TranslocoModule} from '@ngneat/transloco';
import {FuseAlertModule} from '../../../../@fuse/components/alert';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {DialogWrapModule} from '../../../layout/common/dialog-wrap/dialog-wrap.module';
import {FlexModule} from '@angular/flex-layout';
import {GenericTableModule} from '../../components/table/generic-table/generic-table.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {GenericTableEditableModule} from "../../components/table/generic-table-editable/generic-table-editable.module";
import {SharedModule} from "../../shared.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {FormModule} from "../../../layout/common/generic-components/form/form.module";



@NgModule({
  declarations: [
    DialogApprovalComponent
  ],
    imports: [
        CommonModule,
        TranslocoModule,
        FuseAlertModule,
        MatButtonModule,
        MatIconModule,
        DialogWrapModule,
        FlexModule,
        GenericTableModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        GenericTableEditableModule,
        SharedModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        FormModule
    ],
    exports: [
        DialogApprovalComponent
    ]
})
export class DialogApprovalModule { }
