/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum delle chiavi funzionalità
 */
export type ChiaveFunzionalitaEnum = 'area_didattica' | 'registro_attivita' | 'missioni' | 'spese' | 'modifica_budget_dottorando' | 'mobilita' | 'superamento_anno' | 'gestione_esterni';

export const ChiaveFunzionalitaEnum = {
    AreaDidattica: 'area_didattica' as ChiaveFunzionalitaEnum,
    RegistroAttivita: 'registro_attivita' as ChiaveFunzionalitaEnum,
    Missioni: 'missioni' as ChiaveFunzionalitaEnum,
    Spese: 'spese' as ChiaveFunzionalitaEnum,
    ModificaBudgetDottorando: 'modifica_budget_dottorando' as ChiaveFunzionalitaEnum,
    Mobilita: 'mobilita' as ChiaveFunzionalitaEnum,
    SuperamentoAnno: 'superamento_anno' as ChiaveFunzionalitaEnum,
    GestioneEsterni: 'gestione_esterni' as ChiaveFunzionalitaEnum
};