import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FilterManagerService} from '../../../../shared/service/filter-manager.service';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {filter, tap} from 'rxjs/operators';
import {distinctUntilChanged, Observable, take, takeUntil} from 'rxjs';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {
    CicliCorsiDiStudiService,
    MobilitaRequestSelectFormValuesDTO, NazioneEsteraMobilitaInfoView,
     PeriodoDiMobilitaStudenteStatus,
    StatoTemporaleEnum,
    TipoInterventoSupportoMobilitaInfoView, TipologiaBonusMobilitaStudenteInfoView,
    TipologiaMobilitaInfoView,
    TipoPeriodoEnum,
    UserSottoruoloView,
    UsersViewInfoView
} from '../../../../../api-clients/generated/services';

import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {capitalizeString} from '../../../../shared/utils/utils';
import {isEmpty} from "lodash";
import {MobilitaFilterManagerService} from "../../../../shared/service/mobilita-filter-manager.service";
import {TranslocoService} from "@ngneat/transloco";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import * as moment from "moment";
import {MobilitaFilterService} from "./mobilita-filter.service";
import {DEFAULT_PAGE_SIZE} from "../requests-to-be-resolved.component";
import {CicloConfigurationService} from "../../../../shared/service/ciclo-configuration.service";

@Component({
    selector: 'app-mobilita-sidebar-filter-container',
    templateUrl: './mobilita-sidebar-filter-container.component.html',
    styleUrls: ['./mobilita-sidebar-filter-container.component.scss']
})
export class MobilitaSidebarFilterContainerComponent extends AbstractDefaultComponent implements OnInit {
    @Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();
    currentFields: Array<string> = [];
    fieldsLabelMap: Map<string, string>;
    outputFormatData = 'DD/MM/YYYY';
    selectValues: MobilitaRequestSelectFormValuesDTO;
    currentLanguage: Observable<string>;
    // filters names
    _nominativoStudente = 'nominativoStudente';
    _statoSvolgimento = 'statoSvolgimento';
    _periodoDa = 'periodoDa';
    _periodoA = 'periodoA';
    _nominativoStrutturaEsteraOspitante = 'nominativoStrutturaEsteraOspitante';
    _annoAccademico = 'annoAccademico';
    _codiceNazioneStruttura = 'codiceNazioneStruttura';
    _dataFineNull = 'dataFineNull';
    _codiceTipologiaMobilita = 'codiceTipologiaMobilita';
    _codiceTipologiaBonus = 'codiceTipologiaBonus';
    _codiceMobilita = 'codiceMobilita';
    _stato = 'stato';
    _dataUltimaModifica = 'dataUltimaModifica'

    protected readonly Object = Object;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;
    protected readonly StatoTemporaleEnum = StatoTemporaleEnum;
    protected readonly PeriodoDiMobilitaStudenteStatus = PeriodoDiMobilitaStudenteStatus;

    constructor(public mobilitaFilterService: MobilitaFilterService,
                private appInitService: AppInitService,
                private localStorageService: LocalStorageService,
                private translocoService: TranslocoService,
                protected cicloConfigurationService: CicloConfigurationService,) {
        super();
        this.currentFields = this.mobilitaFilterService.defaultFormPathList;
        this.fieldsLabelMap = this.mobilitaFilterService.fieldsLabelMap;
        this.currentLanguage = this.translocoService.langChanges$;
    }

    ngOnInit(): void {
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            take(1),
            distinctUntilChanged(),
            tap(value =>
                this.currentFields = this.mobilitaFilterService.getFieldsByRole(value?.ruolo, value?.sottoruolo)),
            takeUntil(this.destroy$)
        ).subscribe();

        this.localStorageService.checkCurrentSottoRuoloSelected$.asObservable().pipe(
            filter(Boolean),
            tap(() => this.mobilitaFilterService.init()),
            tap(value =>
                this.currentFields = this.mobilitaFilterService.getFieldsByRole(
                    this.localStorageService?.dipartimentoRuoloCiclo?.ruolo,
                    value)
            ),
            takeUntil(this.destroy$)
        ).subscribe();

        this.mobilitaFilterService.checkAggiornamentoSelectValues$.asObservable().pipe(
            tap(() => this.selectValues = this.mobilitaFilterService.selectValues),
            takeUntil(this.destroy$)
        ).subscribe();

    }

    getFormControlByPath(path: string): FormControl<any> {
        return this.mobilitaFilterService.getCtrlByPath(path);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.mobilitaFilterService.mainFormGroup;
    }

    applyFilters(): void {
        this.resetPaginator();
        // take mainformgroup raw value, and for multiple select fields if the array is empty patch the value of the form with undefined
        const formValue = this.getMainFormGroup().getRawValue();
        for (const key of Object.keys(formValue)) {
            if (Array.isArray(formValue[key]) && formValue[key].length === 0) {
                this.getMainFormGroup().patchValue({[key]: null});
            }
        }
        this.mobilitaFilterService.filterApplied = this.getMainFormGroup().getRawValue();
        this.mobilitaFilterService.checkApplyFilterClick$.next(true);
        this.close();
    }

    close(): void {
        this.closeDrawer.next(true);
    }


    reset(): void {
        this.resetPaginator();
        this.mobilitaFilterService?.reset();
        this.close();
    }


    resetPaginator(){
        this.mobilitaFilterService.page = 0;
        this.mobilitaFilterService.pageSize = DEFAULT_PAGE_SIZE;
    }


    dateEvent(event: MatDatepickerInputEvent<Date>, fieldCtrl: AbstractControl, required: boolean): void {
        fieldCtrl.setValue(moment(event.value).format(this.outputFormatData));
        fieldCtrl.setErrors(null);
        fieldCtrl.setValidators([]);
        if (required) {
            fieldCtrl.addValidators(Validators.required);
        }
        fieldCtrl.updateValueAndValidity();
        this.prefillDateFields();
    }

    clearDateField(formControl: FormControl<any>) {
        formControl.setValue(undefined);
        formControl.setErrors(null);
        formControl.setValidators([]);
        this.prefillDateFields(true);
    }

    dateValue(fieldCtrl: AbstractControl) {
        return fieldCtrl.value ? moment(fieldCtrl.value, this.outputFormatData).toDate() : null;
    }

    private prefillDateFields(isRemovingData: boolean = false) {
        // prefill the other date field (to prevent sending only one of the dates)
        const periodoDaCtrl = this.getFormControlByPath('periodoDa');
        const periodoACtrl = this.getFormControlByPath('periodoA');
        if (isRemovingData) {
            periodoACtrl.patchValue(null);
            periodoDaCtrl.patchValue(null);
        } else {
            if (!!periodoDaCtrl.value && !periodoACtrl.value) {
                periodoACtrl.patchValue(periodoDaCtrl.value);
            } else if (!periodoDaCtrl.value && !!periodoACtrl.value) {
                periodoDaCtrl.patchValue(periodoACtrl.value);
            }
        }
    }

}
