/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddDeliberaDTO } from '../model/addDeliberaDTO';
import { DeliberaInfoView } from '../model/deliberaInfoView';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { GetDeliberaDTO } from '../model/getDeliberaDTO';
import { PageDeliberaInfoViewImpl } from '../model/pageDeliberaInfoViewImpl';
import { UpdateDeliberaDTO } from '../model/updateDeliberaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DelibereService {

    protected basePath = 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette di aggiungere una nuova delibera.
     * 
     * @param addDeliberaDTO 
     * @param file 
     * @param notaDiTrasmissione 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDeliberaForm(addDeliberaDTO?: AddDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe?: 'body', reportProgress?: boolean): Observable<DeliberaInfoView>;
    public addDeliberaForm(addDeliberaDTO?: AddDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaInfoView>>;
    public addDeliberaForm(addDeliberaDTO?: AddDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaInfoView>>;
    public addDeliberaForm(addDeliberaDTO?: AddDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addDeliberaDTO !== undefined) {
            formParams = formParams.append('addDeliberaDTO', new Blob([JSON.stringify(addDeliberaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (notaDiTrasmissione !== undefined) {
            formParams = formParams.append('notaDiTrasmissione', <any>notaDiTrasmissione) as any || formParams;
        }

        return this.httpClient.request<DeliberaInfoView>('post',`${this.basePath}/api/v1/delibera/add`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di eliminare una delibera non utilizzata.
     * 
     * @param idDelibera id della delibera da eliminare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDelibera(idDelibera: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteDelibera(idDelibera: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteDelibera(idDelibera: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteDelibera(idDelibera: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idDelibera === null || idDelibera === undefined) {
            throw new Error('Required parameter idDelibera was null or undefined when calling deleteDelibera.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/delibera/${encodeURIComponent(String(idDelibera))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array della delibera di una richiesta di periodo di mobilità studente.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadDelibera(body: GetDeliberaDTO, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadDelibera(body: GetDeliberaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadDelibera(body: GetDeliberaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadDelibera(body: GetDeliberaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling downloadDelibera.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/delibera/download`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista paginata delle delibere con i filtri indicati.
     * 
     * @param numero 
     * @param data 
     * @param note 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDelibereForm(numero?: string, data?: string, note?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageDeliberaInfoViewImpl>;
    public searchDelibereForm(numero?: string, data?: string, note?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDeliberaInfoViewImpl>>;
    public searchDelibereForm(numero?: string, data?: string, note?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDeliberaInfoViewImpl>>;
    public searchDelibereForm(numero?: string, data?: string, note?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (numero !== undefined) {
            formParams = formParams.append('numero', <any>numero) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }
        if (note !== undefined) {
            formParams = formParams.append('note', <any>note) as any || formParams;
        }

        return this.httpClient.request<PageDeliberaInfoViewImpl>('post',`${this.basePath}/api/v1/delibera/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare una delibera.
     * 
     * @param idDelibera id della delibera da modificare
     * @param updateDeliberaDTO 
     * @param file 
     * @param notaDiTrasmissione 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeliberaForm(idDelibera: string, updateDeliberaDTO?: UpdateDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe?: 'body', reportProgress?: boolean): Observable<DeliberaInfoView>;
    public updateDeliberaForm(idDelibera: string, updateDeliberaDTO?: UpdateDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaInfoView>>;
    public updateDeliberaForm(idDelibera: string, updateDeliberaDTO?: UpdateDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaInfoView>>;
    public updateDeliberaForm(idDelibera: string, updateDeliberaDTO?: UpdateDeliberaDTO, file?: Blob, notaDiTrasmissione?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idDelibera === null || idDelibera === undefined) {
            throw new Error('Required parameter idDelibera was null or undefined when calling updateDelibera.');
        }




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = canConsumeForm;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (updateDeliberaDTO !== undefined) {
            formParams = formParams.append('updateDeliberaDTO', new Blob([JSON.stringify(updateDeliberaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (notaDiTrasmissione !== undefined) {
            formParams = formParams.append('notaDiTrasmissione', <any>notaDiTrasmissione) as any || formParams;
        }

        return this.httpClient.request<DeliberaInfoView>('put',`${this.basePath}/api/v1/delibera/${encodeURIComponent(String(idDelibera))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
