/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati di una funzionalità. Valori previsti: *NASCOSTA*, *DISABILITATA*, *ABILITATA*
 */
export type FunzionalitaStatus = 'NASCOSTA' | 'DISABILITATA' | 'ABILITATA';

export const FunzionalitaStatus = {
    NASCOSTA: 'NASCOSTA' as FunzionalitaStatus,
    DISABILITATA: 'DISABILITATA' as FunzionalitaStatus,
    ABILITATA: 'ABILITATA' as FunzionalitaStatus
};